import axios from 'axios';
import { useSelector } from 'react-redux';

const apiAxios = axios.create({
  //baseURL: "http://localhost:5000",
  baseURL: 'https://portal-alternativo-backend.vercel.app/',
});

const ApiAxiosInterceptor = () => {
  const token = useSelector((state) => state.user?.userInfo?.data?.token);
  apiAxios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return null; // Não retorna nada, pois não é um componente visual
};

export default apiAxios;
export { ApiAxiosInterceptor };
