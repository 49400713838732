import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Header from '../../components/header';
import apiAxios from '../../api/apiAxios';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import imgPerfil from '../../assets/注册代理-400x400.png';
import { IoCameraSharp } from 'react-icons/io5';
import { app } from '../../firebase';
import { getDownloadURL, getRef, getStorage, ref, uploadBytes } from 'firebase/storage';
import './profile.css';
import { useDropzone } from 'react-dropzone';
import fullimg from "../../assets/fundo-branco-detalhes-amarelos.jpg"

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user);
  const { loading, userInfo: user, error } = userInfo;

  const [userData, setUserData] = useState(null);
  const [editableInfo, setEditableInfo] = useState({
    firstName: '',
    lastName: '',
    cpf: '',
    password: '',
  });

  const [confirmPassword, setConfirmPassword] = useState('');
  const [newProfileImage, setNewProfileImage] = useState(null);

  const [infoMessage, setInfoMessage] = useState(null);
  const [infoPassword, setPasswordMessage] = useState(null);
  const [infoImage, setImageMessage] = useState(null);

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!user || !user.data || !user.data._id) {
          // Usuário não autenticado ou dados do usuário não disponíveis
          return;
        }

        const userId = user.data._id;
        const response = await apiAxios.get(`/api/users/user/${userId}`);
        if (response.data.status === 'success' && response.data.data) {
          setUserData(response.data.data);
          setEditableInfo({
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
            cpf: response.data.data.cpf,
          });
        }
      } catch (error) {
        console.error('Erro ao obter informações do usuário:', error);
      }
    };
    fetchUserData();
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Editing ${name}: ${value}`);

    if (name === 'image') {
      setNewProfileImage(e.target.files[0]);
    } else {
      setEditableInfo((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const userId = user?.data?._id;
      const updateInfoResponse = await apiAxios.put(`/api/users/editUser/${userId}`, {
        firstName: editableInfo.firstName,
        lastName: editableInfo.lastName,
        cpf: editableInfo.cpf,
      });

      console.log('Update Response:', updateInfoResponse.data);

      if (updateInfoResponse.data.status !== 'success') {
        setInfoMessage('Erro ao atualizar informações do perfil');
        return;
      }

      setInfoMessage('Informações do perfil atualizadas com sucesso!');

      // Set the editableInfo state with the updated data
      setEditableInfo({
        firstName: updateInfoResponse.data.data.firstName,
        lastName: updateInfoResponse.data.data.lastName,
        cpf: updateInfoResponse.data.data.cpf,
        // Add other fields if necessary
      });

      // Fetch user data after a successful update
      const response = await apiAxios.get(`/api/users/user/${userId}`);
      setUserData(response.data.data);
    } catch (error) {
      console.error('Erro ao atualizar informações do perfil:', error);
      setInfoMessage('Erro ao atualizar informações do perfil');
    }
  };

  const handleUpdatePassword = async () => {
    try {
      if (editableInfo.password !== confirmPassword) {
        setPasswordMessage('A senha e a confirmação da senha não correspondem.');
        return;
      }

      const userId = user?.data?._id;
      const response = await apiAxios.put(`/api/users/updatePassword/${userId}`, {
        password: editableInfo.password,
      });

      if (response.data.status === 'success') {
        setPasswordMessage('Senha atualizada com sucesso!');
      } else {
        setPasswordMessage('Erro ao atualizar a senha');
      }
    } catch (error) {
      console.error('Erro ao atualizar a senha:', error);
      setPasswordMessage('Erro ao atualizar a senha');
    }
  };


  const uploadImageToFirebase = async (file) => {
    try {
      const storage = getStorage(app);
      const storageRef = ref(storage, `profile_images/${file.name}`);
      await uploadBytes(storageRef, file);
      const imageURL = await getDownloadURL(storageRef);
      return imageURL;
    } catch (error) {
      console.error('Erro ao enviar a imagem para o Firebase:', error);
      throw error;
    }
  };

  const handleUpdateImage = async (e) => {
    e.preventDefault();
    try {
      if (!newProfileImage) {
        return;
      }

      const imageURL = await uploadImageToFirebase(newProfileImage);

      const userId = userData._id;
      const updateImageResponse = await apiAxios.put(`/api/users/editProfileImage/${userId}`, {
        profileImageURL: imageURL,
      });

      if (updateImageResponse.data.status === 'success') {
        setImageMessage('Imagem do perfil atualizada com sucesso!');
        const userResponse = await apiAxios.get(`/api/users/user/${userId}`);
        setUserData(userResponse.data.data);
        setNewProfileImage(null);
      } else {
        setImageMessage('Erro ao atualizar a imagem do perfil');
      }
    } catch (error) {
      console.error('Erro ao atualizar a imagem do perfil:', error);
      setImageMessage('Erro ao atualizar a imagem do perfil');
    }
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setNewProfileImage(imageFile);
    setImageMessage(null);

    // Atualize também o estado userData com a nova imagem para refletir imediatamente no componente
    setUserData((prevUserData) => ({
      ...prevUserData,
      profileImageURL: URL.createObjectURL(imageFile),
    }));
  };

  const handleDrop = (acceptedFiles) => {
    setNewProfileImage(acceptedFiles[0]);
    setImageMessage(null);

    // Atualize também o estado userData com a nova imagem para refletir imediatamente no componente
    setUserData((prevUserData) => ({
      ...prevUserData,
      profileImageURL: URL.createObjectURL(acceptedFiles[0]),
    }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*',
    multiple: false,
  });

  return (
    <div style={{ height: '300vh' }}>
      <Header />
      <div className='return-icon-portal'><Link to="/"><FaArrowLeft /><p>Voltar</p></Link></div>
      <div className='profile-full-screen'>
        <div className='profile-screen'>
          <div className='edit-info-profile-area-left'>
            <h4>Edite suas informações</h4>
            <label htmlFor='firstName'>First Name:</label>
            <input type='text' id='firstName' name='firstName' value={editableInfo.firstName} onChange={handleChange} />
            <label htmlFor='lastName'>Last Name:</label>
            <input type='text' id='lastName' name='lastName' value={editableInfo.lastName} onChange={handleChange} />
            <label htmlFor='cpf'>CPF:</label>
            <input type='text' id='cpf' name='cpf' value={editableInfo.cpf} readOnly style={{ cursor: 'not-allowed', background: '#d1d1d1', font: 'bold 12pt arial', color: 'gray' }} />
            <button onClick={handleUpdateProfile}>Atualizar Informações</button>
            {infoMessage && <p className={infoMessage.includes('Erro') ? 'error-message' : 'success-message'}>{infoMessage}</p>}
            <h4 style={{ marginTop: "20px" }}>Edite sua senha</h4>
            <label htmlFor='password'>Senha:</label>
            <input type='password' id='password' name='password' value={editableInfo.password} onChange={handleChange} />
            <label htmlFor='confirmPassword'>Confirmar Senha:</label>
            <input type='password' id='confirmPassword' name='confirmPassword' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            <button onClick={handleUpdatePassword}>Atualizar Senha</button>
            {infoPassword && <p className={infoPassword.includes('Erro') ? 'error-message' : 'success-message'}>{infoPassword}</p>}
          </div>
          <div className="edit-info-profile-area-right">
            <form onSubmit={handleUpdateImage} method='post'>
              <div {...getRootProps()} className="img-profile-right">
                <input {...getInputProps()} />
                <div className='img-profile-right-icon'>
                  {isDragActive ? (
                    <p>Solte a imagem aqui...</p>
                  ) : newProfileImage ? (
                    <div style={{background:"none"}}>
                      <img  src={URL.createObjectURL(newProfileImage)} alt='Imagem selecionada' />
                    </div>
                  ) : (
                    <img
                      alt={userData?.name}
                      src={userData?.profileImageURL || imgPerfil}
                      style={{background:"none"}}
                    />
                  )}
                </div>
                <IoCameraSharp className="default-user-icon" />
              </div>

              {/* Mova o botão de envio para dentro do formulário */}
              <button type="submit" className={`update-image-button${newProfileImage ? '' : ' disabled'}`}>Atualizar Imagem</button>
            </form>
            <div style={{ color: 'red' }}>{infoMessage}</div>
            <div style={{ color: 'red' }}>{infoPassword}</div>
            <div style={{ color: 'red' }}>{infoImage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
{/* <div className="edit-info-profile-area-right">
            {userData && (
              <div className='img-profile-right'>
                <input
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleImageChange}
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                <label htmlFor="image" className='img-profile-right-icon'>
                  {newProfileImage ? (
                    <div>
                      <img src={URL.createObjectURL(newProfileImage)} alt='Imagem selecionada' />
                    </div>
                  ) : (
                    <img
                      alt={userData?.name}
                      src={userData?.profileImageURL || imgPerfil}
                    />
                  )}
                </label>
              </div>
            )}
            <button className={`update-image-button${newProfileImage ? '' : ' disabled'}`} onClick={handleUpdateImage} disabled={!newProfileImage}>Atualizar Imagem</button>
          </div> */}