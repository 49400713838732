import React, { useState } from 'react';
import Header from '../../../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteMatricula, listMatriculas } from '../../../actions/matriculaActions';
import { Link } from 'react-router-dom';
import './MatriculasAdmin.css';
import { MdFamilyRestroom } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri'
import { TailSpin } from 'react-loader-spinner';

const MatriculasAdmin = () => {
    const dispatch = useDispatch();
    const matriculaList = useSelector((state) => state.matricula);
    const { loading, error, matriculaData } = matriculaList;
    const [visibleItems, setVisibleItems] = useState(4); // Define o número inicial de itens visíveis
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de pesquisa
    const [showLess, setShowLess] = useState(false); // Estado para controlar se deve mostrar menos itens
    useEffect(() => {
        dispatch(listMatriculas());
    }, [dispatch]);

    const loadMoreItems = () => {
        // Aumenta o número de itens visíveis quando o botão "ler mais" é clicado
        setVisibleItems(visibleItems + 2); // Aumenta em 2, você pode ajustar conforme necessário
    };
    const toggleShowLess = () => {
        // Define o número de itens visíveis para 4 ao clicar em "Ler menos"
        setVisibleItems(4);
        setShowLess(!showLess); // Inverte o estado para alternar entre "Ler mais" e "Ler menos"
    };

    const filteredMatriculas = matriculaData && Array.isArray(matriculaData.data)
        ? matriculaData.data.filter((matricula) =>
            matricula.nomeAluno.toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    const allItemsVisible = visibleItems >= filteredMatriculas.length;

    const handleDeleteMatricula = (matriculaId, nomeAluno) => {
        const userInput = prompt(`Para confirmar a exclusão, digite o nome do aluno "${nomeAluno}":`);

        if (userInput === nomeAluno) {
            console.log(`Deletando matrícula com o ID: ${matriculaId}`);
            // Chame a action de exclusão aqui
            dispatch(deleteMatricula(matriculaId));
        } else {
            alert('Nome do aluno não corresponde. A exclusão foi cancelada.');
        }
    };


    return (
        <div style={{ height: "330vh" }}>
            <Header />
            <h1>Matrículas</h1>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <TailSpin // Use o componente TailSpin para o indicador de carregamento
                        color="red"
                        height={80}
                        width={80}
                    />
                </div>
            ) : error ? (
                <p>Ocorreu um erro: {error}</p>
            ) : (
                <div className='full-matriculasadmin'>
                    <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>FICHAS DE MATRÍCULAS 2024</h4>
                    <div className='matriculasadmin-elements'>
                        <p style={{ color: "black", font: "bold 13pt arial" }}>
                            Total de matrículas : {filteredMatriculas.filter(matricula => !matricula.arqMorto).length}
                        </p>
                        <input
                            type="text"
                            placeholder="pesquisar aluno"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o estado do termo de pesquisa
                        />
                        {filteredMatriculas.length > 0 ? (
                            filteredMatriculas
                                .reverse()
                                .slice(0, allItemsVisible ? filteredMatriculas.length : visibleItems)
                                .map((matricula) => (
                                    // Adicione uma verificação para excluir matrículas arquivadas
                                    !matricula.arqMorto && (
                                        <div className='matriculasadmin-element' key={matricula._id}>
                                            <Link to={`/menuadmin/matriculadmin/aluno/${matricula._id}`}>
                                                <div className='matriculasadmin-sec-1'>
                                                    <MdFamilyRestroom />
                                                </div>
                                                <div className='matriculasadmin-sec-2'>
                                                    <p>{matricula.nomeAluno}</p>
                                                    <p>{matricula.dataNascimento}</p>
                                                    <p>{matricula.serie}</p>
                                                </div>
                                            </Link>
                                            <div onClick={() => handleDeleteMatricula(matricula._id, matricula.nomeAluno)} className='matriculasadmin-sec-3'>
                                                <RiDeleteBin5Line />
                                            </div>
                                        </div>
                                    )
                                ))
                        ) : (
                            <p>Nenhum resultado encontrado.</p>
                        )}

                        {allItemsVisible ? (
                            <div className='show-items-matricula'>
                                <button onClick={toggleShowLess}>
                                    Ler menos
                                </button>
                            </div>
                        ) : (
                            <div className='show-items-matricula'>
                                <button onClick={loadMoreItems}>
                                    Ler mais
                                </button>
                            </div>
                        )}
                    </div>
                    <h4 style={{ color: "grey", font: "bold 17pt arial", margin: "20px" }}>Matriculas Arquivadas</h4>
                    <div className='matriculasadmin-elements'>
                        <p style={{ color: "black", font: "bold 13pt arial" }}>
                            Total de matrículas arquivadas: {filteredMatriculas.filter(matricula => matricula.arqMorto).length}
                        </p>
                        <input
                            type="text"
                            placeholder="pesquisar aluno"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o estado do termo de pesquisa
                        />
                        {filteredMatriculas.length > 0 ? (
                            filteredMatriculas
                                .filter((matricula) => matricula.arqMorto)
                                .reverse()
                                .slice(0, allItemsVisible ? filteredMatriculas.length : visibleItems)
                                .map((matricula) => (
                                    <div className='matriculasadmin-element'>
                                        <Link to={`/menuadmin/matriculadmin/aluno/${matricula._id}`} key={matricula._id}>
                                            <div className='matriculasadmin-sec-1'>
                                                <MdFamilyRestroom />
                                            </div>
                                            <div className='matriculasadmin-sec-2'>
                                                <p>{matricula.nomeAluno}</p>
                                                <p>{matricula.dataNascimento}</p>
                                                <p>{matricula.serie}</p>
                                            </div>
                                        </Link>
                                        <div onClick={() => handleDeleteMatricula(matricula._id, matricula.nomeAluno)} className='matriculasadmin-sec-3'>
                                            <RiDeleteBin5Line />
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <p>Nenhum resultado encontrado.</p>
                        )}
                        {allItemsVisible ? (
                            <div className='show-items-matricula'>
                                <button onClick={toggleShowLess}>
                                    Ler menos
                                </button>
                            </div>
                        ) : (
                            <div className='show-items-matricula'>
                                <button onClick={loadMoreItems}>
                                    Ler mais
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MatriculasAdmin;
