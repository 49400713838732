import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Header from '../../../../../components/header';
import apiAxios from '../../../../../api/apiAxios';
import '../PrimeiroAno/primeiroano.css';
import { GrAdd } from 'react-icons/gr';
import { AiOutlineClose } from 'react-icons/ai';
import GraficoDesempenho from '../../../../../components/GraficoDesenpenho';

// Configurar o elemento root do modal
Modal.setAppElement('#root');

const SegundoAno = () => {
    const [aluno, setAluno] = useState({
        aluno: '',
        idade: '',
        serie: '',
        turno: '',
        B1: {
            portugues: '',
            arte_e_educacao: '',
            ensinoReligioso: '',
            matematica: '',
            historia: '',
            geografia: '',
            ciencias: '',
            ingles: '',
        },
        B2: {
            portugues: '',
            arte_e_educacao: '',
            ensinoReligioso: '',
            matematica: '',
            historia: '',
            geografia: '',
            ciencias: '',
            ingles: '',
        },
        B3: {
            portugues: '',
            arte_e_educacao: '',
            ensinoReligioso: '',
            matematica: '',
            historia: '',
            geografia: '',
            ciencias: '',
            ingles: '',
        },
        B4: {
            portugues: '',
            arte_e_educacao: '',
            ensinoReligioso: '',
            matematica: '',
            historia: '',
            geografia: '',
            ciencias: '',
            ingles: '',
        },
        notaFinal: {
            portugues: '',
            arte_e_educacao: '',
            ensinoReligioso: '',
            matematica: '',
            historia: '',
            geografia: '',
            ciencias: '',
            ingles: '',
        }
    });

    const [alunos, setAlunos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [alunoSelecionado, setAlunoSelecionado] = useState(null);
    const [bimestreSelecionado, setBimestreSelecionado] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const [editNota, setEditNota] = useState({});

    const handleSelecionarBimestre = (bimestre) => {
        setBimestreSelecionado(bimestre);
    };

    const handleChange = (e) => {
        setAluno({ ...aluno, [e.target.name]: e.target.value });
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleSelecionarAluno = (aluno) => {
        setAlunoSelecionado(aluno);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Faça a solicitação para criar um novo aluno
            const response = await apiAxios.post('/api/pedagogico/alunos', aluno);
            // O novo aluno foi criado com sucesso, você pode fazer algo com a resposta se desejar
            console.log('Novo aluno criado:', response.data);

            // Limpe o estado do formulário após a criação bem-sucedida
            setAluno({
                aluno: '',
                idade: '',
                serie: '',
                turno: '',
                // ... outros campos
            });

            // Feche o modal após o envio do formulário
            closeModal();
        } catch (error) {
            console.error('Erro ao criar aluno:', error.message);
        }
    };
    const handleChangeAluno = (novoAluno) => {
        setAlunoSelecionado(novoAluno);
        console.log('Novo aluno selecionado:', novoAluno);
    };
    const handleEditClick = () => {
        setIsEditing(true); // Ativa o modo de edição
        setEditNota({ ...alunoSelecionado }); // Copia as notas do aluno selecionado
    };
    const handleSaveClick = async () => {
        try {
            setIsEditing(false);

            // As notas já estão no estado 'editNota', então você pode usá-las diretamente
            const { B1, B2, B3, B4 } = editNota;


            const mediasFinais = calcularMediasFinais(B1, B2, B3, B4);

            // Faça a solicitação para salvar as notas do aluno
            const response = await apiAxios.put(`/api/pedagogico/alunos/${alunoSelecionado._id}`, {
                B1,
                B2,
                B3,
                B4,
                notaFinal: { ...mediasFinais },
            });

            console.log('Resposta do servidor:', response.data);

            // Busque novamente a lista de alunos após salvar as notas
            const alunosResponse = await apiAxios.get('/api/pedagogico/alunos');
            const alunosFiltrados = alunosResponse.data.filter((a) => {
                const anoDaSerie = extrairAnoDaSerie(a.serie);
                // Lógica para filtrar alunos com 1 ano em série
                return anoDaSerie === 2;
            });

            setAlunos(alunosFiltrados);

            // Atualize o estado do aluno selecionado com as notas editadas
            const alunoAtualizado = alunosFiltrados.find((a) => a._id === alunoSelecionado._id);
            setAlunoSelecionado(alunoAtualizado);

            // As notas do aluno foram atualizadas com sucesso
            console.log('Notas do aluno atualizadas:', response.data);
        } catch (error) {
            console.error('Erro ao atualizar notas do aluno:', error.message);
        }
    };

    const calcularMediasFinais = (B1, B2, B3, B4) => {
        const mediasFinais = {};
        const disciplinas = Object.keys(B1); // Obtém a lista de disciplinas (portugues, arte_e_educacao, etc.)

        disciplinas.forEach((disciplina) => {
            const notasBimestre = [B1[disciplina], B2[disciplina], B3[disciplina], B4[disciplina]];
            const notasNumericas = notasBimestre.map((nota) => parseFloat(nota) || 0);
            const somaNotas = notasNumericas.reduce((soma, nota) => soma + nota, 0);
            const mediaFinal = somaNotas / notasBimestre.length;
            mediasFinais[disciplina] = mediaFinal.toFixed(2); // Ajuste a precisão conforme necessário
        });

        return mediasFinais;
    };


    useEffect(() => {
        // Função para buscar a lista de alunos da API
        const fetchAlunos = async () => {
            try {
                const response = await apiAxios.get('/api/pedagogico/alunos');
                const alunosFiltrados = response.data.filter((a) => {
                    const anoDaSerie = extrairAnoDaSerie(a.serie);
                    // Lógica para filtrar alunos com 2 anos em série (segundo ano)
                    return anoDaSerie === 2;
                });

                setAlunos(alunosFiltrados);

                // Seleciona o primeiro aluno se houver alunos
                if (alunosFiltrados.length > 0) {
                    setAlunoSelecionado(alunosFiltrados[0]);
                }
            } catch (error) {
                console.error('Erro ao buscar alunos:', error.message);
            }
        };


        const preencherNotas = async () => {
            try {
                // Faça a solicitação para preencher as notas
                await apiAxios.post('/api/pedagogico/preencher-notas');
                console.log('Notas preenchidas com sucesso!');

                // Depois de preencher as notas, busque novamente os alunos (se necessário)
                await fetchAlunos();
            } catch (error) {
                console.error('Erro ao preencher as notas:', error.message);
            }
        };

        fetchAlunos();
        // Chama a função para preencher as notas quando o componente é montado
        preencherNotas();
        // Chama a função de busca quando o componente é montado
    }, []);
    useEffect(() => {
        const fetchAlunoData = async () => {
            try {
                const response = await apiAxios.get('/api/pedagogico/alunos');
                const notaedit = response.data;
                setEditNota(notaedit.aluno || {}); // Atualize o estado aluno quando receber os dados
            } catch (error) {
                console.error('Erro ao buscar dados do aluno:', error.message);
            }
        };

        fetchAlunoData();
    }, []);


    const extrairAnoDaSerie = (serie) => {
        const matches = serie.match(/^(\d+)/); // Extrai os dígitos no início da string
        return matches ? parseInt(matches[0], 10) : null;

    };

    return (
        <div>
            <Header />
            <h1>Segundo Ano</h1>
            <div className='pedagogico-full-sceen'>
                <div className='pedagogico-notas'>
                    <div className='toolbarpedagogico'>
                        <button onClick={openModal}><GrAdd /></button>
                    </div>
                    {/* adicionar aluno */}
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel="Adicionar Aluno"
                        className="Modal-pedagogico-fundamental"
                    >
                        <form onSubmit={handleSubmit}>
                            <h4> Adicionar aluno</h4>
                            <label>
                                <p>Nome:</p>
                                <input type="text" name="aluno" value={aluno.aluno} onChange={handleChange} />
                            </label>
                            <label>
                                <p>Idade:</p>
                                <input type="text" name="idade" value={aluno.idade} onChange={handleChange} />
                            </label>
                            <label>
                                <p>Série:</p>
                                <input type="text" name="serie" value={aluno.serie} onChange={handleChange} />
                            </label>
                            <label>
                                <p>Turno:</p>
                                <input type="text" name="turno" value={aluno.turno} onChange={handleChange} />
                            </label>
                            <button className='adicionar-aluno-fundamental' type="submit">Adicionar Aluno</button>
                            <button className='sair-modal' onClick={closeModal}><AiOutlineClose /></button>
                        </form>
                    </Modal>
                    <div className='pedagogico-screen'>
                        {/* editar nota */}
                        <div className='pedagogico-screen-edit'>
                            <div className='edit-data'>
                                <div className='data-right'><img src='/' alt='Imagem do Aluno' /></div>
                                <div className='data-left'>
                                    {alunoSelecionado && (
                                        <>
                                            <p>Aluno: {alunoSelecionado.aluno}</p>
                                            <p>Série: {alunoSelecionado.serie}</p>
                                            <p>Turno: {alunoSelecionado.turno}</p>
                                            <p>Idade: {alunoSelecionado.idade}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='edit-notas'>
                                <div className='toolbarpedagogico-editnotas'>
                                    {isEditing ? (
                                        <button style={{ marginLeft: "10px", background: 'orange' }} className='button-matriculado-detail' onClick={handleSaveClick}>Salvar notas</button>
                                    ) : (
                                        <button style={{ marginLeft: "10px", background: 'orange' }} className='button-matriculado-detail' onClick={handleEditClick}>Editar notas</button>
                                    )}
                                </div>
                                {/* Botões para selecionar o bimestre */}
                                <div className='bimestre-buttons'>
                                    {[1, 2, 3, 4].map((bimestre) => (
                                        <button
                                            key={bimestre}
                                            onClick={() => handleSelecionarBimestre(bimestre)}
                                            className={bimestre === bimestreSelecionado ? 'selected' : ''}
                                        >
                                            Bimestre {bimestre}
                                        </button>
                                    ))}
                                </div>
                                <div className='bimestre-list-notas'>
                                    {alunoSelecionado && (
                                        <>
                                            {bimestreSelecionado === 1 && (
                                                <>
                                                    <div className='bimestre-list-notas-right'>
                                                        <p>Português:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={(editNota.B1 && editNota.B1.portugues) || ''}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, portugues: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}>{(alunoSelecionado.B1 && alunoSelecionado.B1.portugues) || 'N/A'}</p>
                                                        )}

                                                        <p>Arte e educação:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.arte_e_educacao}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, arte_e_educacao: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.arte_e_educacao}</p>
                                                        )}

                                                        <p>ensinoReligioso :</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.ensinoReligioso}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, ensinoReligioso: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.ensinoReligioso}</p>
                                                        )}

                                                        <p>Matemática:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.matematica}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, matematica: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.matematica}</p>
                                                        )}
                                                    </div>
                                                    <div className='bimestre-list-notas-left'>
                                                        <p>História</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.historia}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, historia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.historia}</p>
                                                        )}

                                                        <p>Geografia:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.geografia}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, geografia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.geografia}</p>
                                                        )}

                                                        <p>Ciências:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.ciencias}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, ciencias: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.ciencias}</p>
                                                        )}

                                                        <p>Inglês:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B1 && editNota.B1.ingles}
                                                                onChange={(e) => setEditNota({ ...editNota, B1: { ...editNota.B1, ingles: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B1 && alunoSelecionado.B1.ingles}</p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {bimestreSelecionado === 2 && (
                                                <>
                                                    <div className='bimestre-list-notas-right'>
                                                        <p>Português:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={(editNota.B2 && editNota.B2.portugues) || ''}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, portugues: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {(alunoSelecionado.B2 && alunoSelecionado.B2.portugues) || 'N/A'}</p>
                                                        )}

                                                        <p>arte_e_educacao:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.arte_e_educacao}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, arte_e_educacao: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.arte_e_educacao}</p>
                                                        )}
                                                        <p>ensinoReligioso :</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.ensinoReligioso}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, ensinoReligioso: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.ensinoReligioso}</p>
                                                        )}

                                                        <p>Matemática:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.matematica}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, matematica: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.matematica}</p>
                                                        )}
                                                    </div>
                                                    <div className='bimestre-list-notas-left'>
                                                        <p>História</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.historia}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, historia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.historia}</p>
                                                        )}

                                                        <p>Geografia:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.geografia}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, geografia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.geografia}</p>
                                                        )}

                                                        <p>Ciências:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.ciencias}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, ciencias: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.ciencias}</p>
                                                        )}

                                                        <p>Inglês:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B2 && editNota.B2.ingles}
                                                                onChange={(e) => setEditNota({ ...editNota, B2: { ...editNota.B2, ingles: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B2 && alunoSelecionado.B2.ingles}</p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {bimestreSelecionado === 3 && (
                                                <>
                                                    <div className='bimestre-list-notas-right'>
                                                        <p>Português:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={(editNota.B3 && editNota.B3.portugues) || ''}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, portugues: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {(alunoSelecionado.B3 && alunoSelecionado.B3.portugues) || 'N/A'}</p>
                                                        )}

                                                        <p>arte_e_educacao:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.arte_e_educacao}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, arte_e_educacao: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.arte_e_educacao}</p>
                                                        )}
                                                        <p>ensinoReligioso :</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.ensinoReligioso}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, ensinoReligioso: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.ensinoReligioso}</p>
                                                        )}

                                                        <p>Matemática:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.matematica}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, matematica: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.matematica}</p>
                                                        )}
                                                    </div>
                                                    <div className='bimestre-list-notas-left'>
                                                        <p>História</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.historia}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, historia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.historia}</p>
                                                        )}

                                                        <p>Geografia:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.geografia}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, geografia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.geografia}</p>
                                                        )}

                                                        <p>Ciências:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.ciencias}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, ciencias: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.ciencias}</p>
                                                        )}

                                                        <p>Inglês:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B3 && editNota.B3.ingles}
                                                                onChange={(e) => setEditNota({ ...editNota, B3: { ...editNota.B3, ingles: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B3 && alunoSelecionado.B3.ingles}</p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            {bimestreSelecionado === 4 && (
                                                <>
                                                    <div className='bimestre-list-notas-right'>
                                                        <p>Português:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={(editNota.B4 && editNota.B4.portugues) || ''}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, portugues: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {(alunoSelecionado.B4 && alunoSelecionado.B4.portugues) || 'N/A'}</p>
                                                        )}

                                                        <p>arte_e_educacao:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.arte_e_educacao}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, arte_e_educacao: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.arte_e_educacao}</p>
                                                        )}
                                                        <p>ensinoReligioso :</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.ensinoReligioso}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, ensinoReligioso: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.ensinoReligioso}</p>
                                                        )}

                                                        <p>Matemática:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.matematica}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, matematica: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.matematica}</p>
                                                        )}
                                                    </div>
                                                    <div className='bimestre-list-notas-left'>
                                                        <p>História</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.historia}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, historia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.historia}</p>
                                                        )}

                                                        <p>Geografia:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.geografia}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, geografia: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.geografia}</p>
                                                        )}

                                                        <p>Ciências:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.ciencias}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, ciencias: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.ciencias}</p>
                                                        )}

                                                        <p>Inglês:</p>
                                                        {isEditing ? (
                                                            <input
                                                                style={{ textAlign: 'center' }}
                                                                type='text'
                                                                value={editNota.B4 && editNota.B4.ingles}
                                                                onChange={(e) => setEditNota({ ...editNota, B4: { ...editNota.B4, ingles: e.target.value } })}
                                                            />
                                                        ) : (
                                                            <p style={{ background: "#f5f2f2", color: "gray" }}> {alunoSelecionado.B4 && alunoSelecionado.B4.ingles}</p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='list-notas-finais'>
                                    <h5>Notas finais :</h5>
                                    {alunoSelecionado && (
                                        <>
                                            <div className='notasfinais-container' style={{ marginBottom: "40px" }}>
                                                <div className='notasfinais-item'><p>Port</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.portugues}</div>
                                                <div className='notasfinais-item'><p>Arte e educação</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.arte_e_educacao}</div>
                                                <div className='notasfinais-item'><p>Ens Rel</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.ensinoReligioso}</div>
                                                <div className='notasfinais-item'><p>Mat</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.matematica}</div>
                                                <div className='notasfinais-item'><p>Hist</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.historia}</div>
                                                <div className='notasfinais-item'><p>Geo</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.geografia}</div>
                                                <div className='notasfinais-item'><p>Cien</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.ciencias}</div>
                                                <div className='notasfinais-item'><p>Ing</p> {alunoSelecionado.notaFinal && alunoSelecionado.notaFinal.ingles}</div>
                                            </div>
                                        </>
                                    )}
                                    {alunoSelecionado && (
                                        <>
                                            {/* ... restante do código */}
                                            <GraficoDesempenho notas={alunoSelecionado} onAlunoChange={handleChangeAluno} alunoSelecionado={alunoSelecionado} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='pedagogico-screen-alunos'>
                            <table>
                                <thead>
                                    <tr>
                                        <th >Selecione o seu aluno</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {alunos
                                        .filter((a) => {
                                            const anoDaSerie = extrairAnoDaSerie(a.serie);
                                            // Lógica para filtrar alunos com 1 ano em série
                                            return anoDaSerie === 2;
                                        })
                                        .map((a) => (
                                            <tr key={a._id} onClick={() => handleSelecionarAluno(a)}>
                                                <td className={a === alunoSelecionado ? 'selectedAluno' : ''}>{a.aluno}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SegundoAno;
