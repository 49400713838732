import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { register } from '../../actions/userActions';
import './matriculaEscolaScreen.css';
import escola from "../../assets/OIP.png"
import { FaUserGraduate } from 'react-icons/fa';
import { LiaPaperclipSolid } from "react-icons/lia";
import defaultImage from '../../assets/注册代理-400x400.png';
import { TailSpin } from 'react-loader-spinner';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../../firebase"; // Importe sua configuração do Firebase

const MatriculaEscolaScreen = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [cpf, setCpf] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const userRegister = useSelector((state) => state.user);
    const { loading, userInfo, error } = userRegister;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const redirecth = searchParams.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirecth);
        }
    }, [userInfo, navigate, redirecth]);

    const submitHandler = async (e) => {
        e.preventDefault();

        if (password !== repassword) {
            console.log('As senhas não coincidem');
            alert('Campos de senha inválidos!');
            return;
        }

        try {
            // Iniciar o armazenamento do Firebase
            const storage = getStorage(app);

            // Criar uma referência para o local no Firebase Storage onde a imagem será armazenada
            const storageRef = ref(storage, `profile_images/${email}-${Date.now()}`);

            // Fazer o upload da imagem para o Firebase Storage
            await uploadBytes(storageRef, profileImage);

            // Obter o link da imagem no Firebase Storage
            const imageUrl = await getDownloadURL(storageRef);

            // Salvar o link da imagem no estado
            setProfileImageUrl(imageUrl);

            // Criar um objeto de usuário com todos os dados, incluindo o link da imagem
            const newUser = {
                firstName,
                lastName,
                email,
                password,
                cpf,
                profileImageUrl,
            };

            // Dispatch da ação de registro, passando o objeto do usuário
            dispatch(register(newUser));
        } catch (error) {
            console.error('Error registering user:', error);
            alert('Erro ao registrar usuário');
        }
    };

    const handleImageChange = (e) => {
        setProfileImage(e.target.files[0]);
    };

    const ImagePreview = ({ image }) => {
        return (
            <div className="image-preview">
                {image && <img src={URL.createObjectURL(image)} alt="Imagem selecionada" />}
            </div>
        );
    };

    return (
        <div className="full-register">
            {loading ? <TailSpin color="red" size={30} /> :
                <>
                    <img src={escola} alt="Escola Logo" />
                    <form className="register" onSubmit={submitHandler} encType="multipart/form-data">
                        <div className="menu-register">
                            <FaUserGraduate style={{ margin: '5px' }} />
                            <p>Cadastre-se no portal</p>
                        </div>
                        {error?.general && <div style={{ color: 'red', font: "normal 9pt arial", marginTop: "10px" }}>{error.general}</div>}
                        <div className="profile-image-upload">
                            <input
                                type="file"
                                id="profileImage"
                                name="profileImage"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="input-file" // Adicionando uma classe ao input para estilização
                            />
                            <label htmlFor="profileImage" className="custom-file-upload">
                                <LiaPaperclipSolid className='custom-file-upload-clips' /> Escolher Foto
                            </label>
                            <div className="image-preview-container">
                                <ImagePreview image={profileImage} />
                            </div>
                        </div>
                        <div className='menu-register-container'>
                            <div className='menu-register-item'>
                                <label htmlFor="firstName">Nome</label>
                                <input type="text" name="firstName" id="firstName" onChange={(e) => setFirstName(e.target.value)} />

                                <label htmlFor="lastName">Sobrenome</label>
                                <input type="text" name="lastName" id="lastName" onChange={(e) => setLastName(e.target.value)} />

                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className='menu-register-item'>
                                <label htmlFor="cpf">CPF</label>
                                <input type="text" name="cpf" id="cpf" onChange={(e) => setCpf(e.target.value)} />

                                <label htmlFor="password">Senha</label>
                                <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} />

                                <label htmlFor="repassword">Confirmar senha</label>
                                <input
                                    type="password"
                                    id="repassword"
                                    name="repassword"
                                    onChange={(e) => setRePassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <button type="submit" style={{ background: 'red' }}>
                            Register
                        </button>
                        <h6>
                            já tem conta? <Link to="/login">Entre</Link>
                        </h6>
                    </form>
                </>
            }
        </div >
    );
};

export default MatriculaEscolaScreen;


// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useNavigate, useSearchParams } from 'react-router-dom';
// import { register } from '../../actions/userActions';
// import './matriculaEscolaScreen.css';
// import escola from "../../assets/OIP.png"
// import { FaUserGraduate } from 'react-icons/fa';
// import { LiaPaperclipSolid } from "react-icons/lia";
// import defaultImage from '../../assets/注册代理-400x400.png';
// import { TailSpin } from 'react-loader-spinner';
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { app } from "../../firebase"; // Importe sua configuração do Firebase

// const MatriculaEscolaScreen = () => {
//     const [firstName, setFirstName] = useState('');
//     const [lastName, setLastName] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [repassword, setRePassword] = useState('');
//     const [cpf, setCpf] = useState('');
//     const [profileImage, setProfileImage] = useState(null);
//     const userRegister = useSelector((state) => state.user);
//     const { loading, userInfo, error } = userRegister;
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [searchParams] = useSearchParams();
//     const redirecth = searchParams.get('redirect') || '/';

//     useEffect(() => {
//         if (userInfo) {
//             navigate(redirecth);
//         }
//     }, [userInfo, navigate, redirecth]);

//     const submitHandler = async (e) => {
//         e.preventDefault();

//         if (password !== repassword) {
//             console.log('As senhas não coincidem');
//             alert('Campos de senha inválidos!');
//             return;
//         }

//         const formData = new FormData();
//         formData.append('firstName', firstName);
//         formData.append('lastName', lastName);
//         formData.append('email', email);
//         formData.append('password', password);
//         formData.append('cpf', cpf);
//         if (profileImage) {
//             formData.append('profileImage', profileImage);
//         } else {
//             const defaultImageFile = await fetch(defaultImage)
//                 .then(response => response.blob())
//                 .then(blob => new File([blob], 'defaultImage.png', { type: 'image/png' }));

//             formData.append('profileImage', defaultImageFile);
//         }

//         try {
//             dispatch(register(formData));
//         } catch (error) {
//             console.error('Error submitting formData:', error);
//         }
//     };

//     const handleImageChange = (e) => {
//         setProfileImage(e.target.files[0]);
//     };

//     const ImagePreview = ({ image }) => {
//         return (
//             <div className="image-preview">
//                 {image && <img src={URL.createObjectURL(image)} alt="Imagem selecionada" />}
//             </div>
//         );
//     };

//     return (
//         <div className="full-register">
//             {loading ? <TailSpin color="red" size={30} /> :
//                 <>
//                     <img src={escola} alt="Escola Logo" />
//                     <form className="register" onSubmit={submitHandler} encType="multipart/form-data">
//                         <div className="menu-register">
//                             <FaUserGraduate style={{ margin: '5px' }} />
//                             <p>Cadastre-se no portal</p>
//                         </div>
//                         {error?.general && <div style={{ color: 'red', font: "normal 9pt arial",marginTop:"10px" }}>{error.general}</div>}
//                         <div className="profile-image-upload">
//                             <input
//                                 type="file"
//                                 id="profileImage"
//                                 name="profileImage"
//                                 accept="image/*"
//                                 onChange={handleImageChange}
//                                 className="input-file" // Adicionando uma classe ao input para estilização
//                             />
//                             <label htmlFor="profileImage" className="custom-file-upload">
//                                 <LiaPaperclipSolid className='custom-file-upload-clips' /> Escolher Foto
//                             </label>
//                             <div className="image-preview-container">
//                                 <ImagePreview image={profileImage} />
//                             </div>
//                         </div>
//                         <div className='menu-register-container'>
//                             <div className='menu-register-item'>
//                                 <label htmlFor="firstName">Nome</label>
//                                 <input type="text" name="firstName" id="firstName" onChange={(e) => setFirstName(e.target.value)} />

//                                 <label htmlFor="lastName">Sobrenome</label>
//                                 <input type="text" name="lastName" id="lastName" onChange={(e) => setLastName(e.target.value)} />

//                                 <label htmlFor="email">Email</label>
//                                 <input type="email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
//                             </div>
//                             <div className='menu-register-item'>
//                                 <label htmlFor="cpf">CPF</label>
//                                 <input type="text" name="cpf" id="cpf" onChange={(e) => setCpf(e.target.value)} />

//                                 <label htmlFor="password">Senha</label>
//                                 <input type="password" id="password" name="password" onChange={(e) => setPassword(e.target.value)} />

//                                 <label htmlFor="repassword">Confirmar senha</label>
//                                 <input
//                                     type="password"
//                                     id="repassword"
//                                     name="repassword"
//                                     onChange={(e) => setRePassword(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                         <button type="submit" style={{ background: 'red' }}>
//                             Register
//                         </button>
//                         <h6>
//                             já tem conta? <Link to="/login">Entre</Link>
//                         </h6>
//                     </form>
//                 </>
//             }
//         </div >
//     );
// };

// export default MatriculaEscolaScreen;
