import React from 'react'
import Header from '../../../../components/header'
import { Link } from 'react-router-dom'
import './AgendaMain.css'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'; // Importe o componente TailSpin
import { FcContacts } from "react-icons/fc";
import { FcAdvertising } from "react-icons/fc";
import { FcViewDetails } from "react-icons/fc";
import { GrPlan } from "react-icons/gr";

const AgendaMain = () => {
    const userInfo = useSelector(state => state.user);
    const { loading, userInfo: user, error } = userInfo;

    const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
    const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);
    const isprof = useSelector(state => state.user?.userInfo?.data?.isProfessor?.isProfessor);

    const items = [
        {
            key: 1,
            name: 'Agenda online',
            icon: <FcContacts />,
            link: '/agenda/atividades',
        },
        {
            key: 2,
            name: 'Cardapio',
            icon: '🥗',
            link: '/cardapio',
        },
        {
            key: 3,
            name: 'Informes',
            icon: <FcAdvertising />,
            link: '/agenda/informes',
        },
        {
            key: 4,
            name: 'Plano Anual',
            icon: <FcViewDetails />,
            link: '/agenda/planos',
        },
    ]

    const canAccessPlanoAnual = isCoAdmin || isAdmin || isprof;

    return (
        <div style={{ height: "300vh" }}>
            <Header />
            <h1>Agenda</h1>
            <div className='agendamain-fullscreen'>
                <div className='agendamain-acess-full'>
                    <div className='agendamain-acess' >
                        {loading ? ( // Verifique se o carregamento está em andamento
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "100%" }}>
                                <TailSpin // Use o componente TailSpin para o indicador de carregamento
                                    color="red"
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : (
                            items.map(
                                (item) =>
                                    (item.name !== 'Plano Anual' || canAccessPlanoAnual) && (
                                        <Link key={item.key} style={{ textDecoration: 'none' }} to={item.link}>
                                            <div className="agendamain-item">
                                                <div className="icon-main-agendamain-colonia">{item.icon}</div>
                                                <div className="name-main-agendamain-colonia">{item.name}</div>
                                            </div>
                                        </Link>
                                    )
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgendaMain
