import React, { useEffect, useState } from 'react';
import apiAxios from '../../../../api/apiAxios';
import jsPDF from 'jspdf';
import { useParams } from 'react-router';
import Header from '../../../../components/header';
import fundopdf from '../../../../assets/catequese 2024 1111 (1).jpg';
import { format } from 'date-fns';

const FichaCatequeseDetail = () => {
    const { id } = useParams();
    const [alunoDetail, setAlunoDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        async function fetchAlunoDetail() {
            try {
                const response = await apiAxios.get(`/api/matriculasCatequese/inscricao/${id}`);

                // Filtra __v e __id do objeto de resposta
                const { __v, _id, ...filteredData } = response.data;

                setAlunoDetail(filteredData);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }

        fetchAlunoDetail();
    }, [id]);

    const generatePDF = () => {
        if (!alunoDetail) return;

        // Crie um novo documento jsPDF
        const doc = new jsPDF();

        // Adicione o fundo do PDF
        doc.addImage(fundopdf, 'JPEG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());

        // Coordenadas para cada campo
        const coordinates = {
            nome: { x: 40, y: 72 },
            sexo: { x: 180, y: 72 },
            nomePai: { x: 60, y: 81 },
            nomeMae: { x: 60, y: 90 },
            dataNascimento: { x: 58, y: 99 },
            Naturalidade: { x: 103, y: 99 },
            Nacionalidade: { x: 163, y: 99 },
            Endereço: { x: 45, y: 108 },
            Numero: { x: 175, y: 108 },
            Completo: { x: 50, y: 117 },
            Bairro: { x: 125, y: 117 },
            CEP: { x: 160, y: 117 },
            email: { x: 40, y: 125 },
            whatsapp: { x: 125, y: 125 },
            fone: { x: 160, y: 125 },
            religiaoPai: { x: 95, y: 138 },
            religiaoMae: { x: 145, y: 138 },
            batizado: { x: 145, y: 148 },
            justificativaDeParticipacao: { x: 40, y: 205 },
            paroquiaBatismo: { x: 123, y: 156 },
            paroquiaQuePertence: { x: 68, y: 172 },
        };
        doc.setFont('arial');
        doc.setFontSize(12);
        doc.setTextColor('#494646');

        Object.keys(alunoDetail)
            .filter(campo => campo !== '__v' && campo !== '_id' && coordinates[campo])
            .forEach(campo => {
                const valor = alunoDetail[campo];

                if (campo === 'batizado') {
                    const xCoordinate = 58; // coordenada x para o 'X'
                    const yCoordinate = 152; // coordenada y para o 'X'

                    if (valor === true) {
                        // Defina a cor de preenchimento como transparente apenas para o texto 'true'
                        doc.setFillColor(255, 255, 255, 0); // RGBA: (255, 255, 255, 0) - branco completamente transparente
                        doc.text(xCoordinate, yCoordinate, 'X', null, null, 'center'); // Ajuste conforme necessário
                    } else if (valor === false) {
                        // Defina a cor de preenchimento como transparente apenas para o texto 'false'
                        const xCoordinateFalse = 81; // coordenada x para o 'X' quando o valor é 'false'
                        const yCoordinateFalse = 153; // coordenada y para o 'X' quando o valor é 'false'
                        doc.setFillColor(255, 255, 255, 0); // RGBA: (255, 255, 255, 0) - branco completamente transparente
                        doc.text(xCoordinateFalse, yCoordinateFalse, 'X', null, null, 'center'); // Ajuste conforme necessário
                    } else {
                        // Use a cor normal para outros valores
                        doc.setFillColor(0, 0, 0); // Defina a cor de preenchimento para preto (ajuste conforme necessário)
                        doc.text(coordinates[campo].x, coordinates[campo].y, 'X');
                    }
                } else if (typeof valor !== 'boolean') {
                    // Adicione outros campos normalmente (evite adicionar valores booleanos)
                    const displayText = Array.isArray(valor) ? valor.join(', ') : valor.toString();
                    doc.text(coordinates[campo].x, coordinates[campo].y, displayText);
                }

                if (campo === 'participaDasMissas') {
                    const xCoordinate = 58; // coordenada x para o 'X'
                    const yCoordinate = 170; // coordenada y para o 'X'

                    if (valor === true) {
                        // Defina a cor de preenchimento como transparente apenas para o texto 'true'
                        doc.setFillColor(255, 255, 255, 0); // RGBA: (255, 255, 255, 0) - branco completamente transparente
                        doc.text(xCoordinate, yCoordinate, 'X', null, null, 'center'); // Ajuste conforme necessário
                    } else if (valor === false) {
                        // Defina a cor de preenchimento como transparente apenas para o texto 'false'
                        const xCoordinateFalse = 81; // coordenada x para o 'X' quando o valor é 'false'
                        const yCoordinateFalse = 153; // coordenada y para o 'X' quando o valor é 'false'
                        doc.setFillColor(255, 255, 255, 0); // RGBA: (255, 255, 255, 0) - branco completamente transparente
                        doc.text(xCoordinateFalse, yCoordinateFalse, 'X', null, null, 'center'); // Ajuste conforme necessário
                    } else {
                        // Use a cor normal para outros valores
                        doc.setFillColor(0, 0, 0); // Defina a cor de preenchimento para preto (ajuste conforme necessário)
                        doc.text(coordinates[campo].x, coordinates[campo].y, 'X');
                    }
                } else if (typeof valor !== 'boolean') {
                    // Adicione outros campos normalmente (evite adicionar valores booleanos)
                    const displayText = Array.isArray(valor) ? valor.join(', ') : valor.toString();
                    doc.text(coordinates[campo].x, coordinates[campo].y, displayText);
                }

                if (campo === 'participaMovimentosPastorais') {
                    const xCoordinate = 58; // coordenada x para o 'X'
                    const yCoordinate = 175; // coordenada y para o 'X'

                    if (valor === true) {
                        // Defina a cor de preenchimento como transparente apenas para o texto 'true'
                        doc.setFillColor(255, 255, 255, 0); // RGBA: (255, 255, 255, 0) - branco completamente transparente
                        doc.text(xCoordinate, yCoordinate, 'X', null, null, 'center'); // Ajuste conforme necessário
                    } else if (valor === false) {
                        // Defina a cor de preenchimento como transparente apenas para o texto 'false'
                        const xCoordinateFalse = 81; // coordenada x para o 'X' quando o valor é 'false'
                        const yCoordinateFalse = 153; // coordenada y para o 'X' quando o valor é 'false'
                        doc.setFillColor(255, 255, 255, 0); // RGBA: (255, 255, 255, 0) - branco completamente transparente
                        doc.text(xCoordinateFalse, yCoordinateFalse, 'X', null, null, 'center'); // Ajuste conforme necessário
                    } else {
                        // Use a cor normal para outros valores
                        doc.setFillColor(0, 0, 0); // Defina a cor de preenchimento para preto (ajuste conforme necessário)
                        doc.text(coordinates[campo].x, coordinates[campo].y, 'X');
                    }
                } else if (typeof valor !== 'boolean') {
                    // Adicione outros campos normalmente (evite adicionar valores booleanos)
                    const displayText = Array.isArray(valor) ? valor.join(', ') : valor.toString();
                    doc.text(coordinates[campo].x, coordinates[campo].y, displayText);
                }

                if (campo === 'dataNascimento' && valor) {
                    const dataNascimento = new Date(valor);

                    // Extraia o dia da data de nascimento
                    const dia = dataNascimento.getDate();
                    const mes = dataNascimento.getMonth();
                    const ano = dataNascimento.getFullYear();


                    // Adicione o valor extraído ao documento
                    doc.text(134, 144, `${dia}`);
                    doc.text(144, 144, `${mes}`);
                    doc.text(151, 144, `${ano}`);

                }

                if (campo === 'dataInscricao' && valor) {
                    const dataInscricao = new Date(valor);

                    // Extraia o dia da data de nascimento
                    const dia = dataInscricao.getDate();
                    const mes = dataInscricao.getMonth();
                    const ano = dataInscricao.getFullYear();


                    // Adicione o valor extraído ao documento
                    doc.text(134, 180, `${dia}`);
                    doc.text(144, 180, `${mes}`);
                    doc.text(151, 180, `${ano}`);

                }
            });

        // Crie um Blob do documento
        const pdfBlob = doc.output('blob', { mimeType: 'application/pdf' });

        // Crie um link temporário
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Crie um link de download
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Catequese.pdf';

        // Adicione o link ao corpo do documento
        document.body.appendChild(link);

        // Simule um clique para iniciar o download
        link.click();

        // Remova o link do corpo do documento
        document.body.removeChild(link);
        window.open(pdfUrl, '_blank');
    };

    return (
        <div>
            <Header />
            <div className='full-matriculado-detail'>
                <div style={{ width: '95%', display: 'flex', margin: '10px' }}>
                    <button className='button-matriculado-detail' onClick={generatePDF}>
                        Baixar PDF
                    </button>
                </div>
                {loading ? (
                    <p>Carregando...</p>
                ) : error ? (
                    <p>Ocorreu um erro: {error}</p>
                ) : alunoDetail ? (
                    <div className='grid-container'>
                        <h4>Detalhes do Aluno</h4>
                        <div className='grid-container-2'>
                            <div className='grid-item'>
                                {Object.keys(alunoDetail)
                                    .filter(campo => !['__id', '__v'].includes(campo))
                                    .map(campo => (
                                        <p key={campo}>
                                            {campo.charAt(0).toUpperCase() + campo.slice(1)}:
                                            {campo === 'dataInscricao' || campo === 'dataBatismo' ?
                                                // Formate as datas
                                                ` ${format(new Date(alunoDetail[campo]), 'dd/MM/yyyy')}` :
                                                // Mantenha outros campos da maneira como eles são
                                                ` ${Array.isArray(alunoDetail[campo]) ? alunoDetail[campo].join(', ') : alunoDetail[campo].toString()}`
                                            }
                                        </p>
                                    ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Aluno não encontrado.</p>
                )}
            </div>
        </div>
    );
};

export default FichaCatequeseDetail;
