import React, { useState } from 'react'
import Header from '../../../../components/header'
import './BoletinsMain.css'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'
const BoletinsMain = () => {

    const [infantilExpandido, setInfantilExpandido] = useState(false);
    const [fundamentalExpandido, setFundamentalExpandido] = useState(false);
    const isProf = useSelector((state) => state.user.userInfo?.data?.isProfessor);

    const toggleInfantil = () => {
        setInfantilExpandido(!infantilExpandido);
    };

    const toggleFundamental = () => {
        setFundamentalExpandido(!fundamentalExpandido);
    };

    return (
        <div style={{ height: "100%" }}>
            <Header />
            <div className='return-icon-portal'><Link to="/"><FaArrowLeft /><p>Voltar</p></Link></div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "start", height: "100%", marginTop: "40px" }}>
                <div className="pedagogico-containers">
                    <h4 style={{ color: "red", font: "bold 16pt arial", marginBottom: "25px" }}>Lançamento das notas</h4>
                    <div className={`container ${infantilExpandido ? 'expandido' : ''}`} onClick={toggleInfantil}>
                        <p>Ensino Infantil</p>
                        <div className={infantilExpandido ? 'conteudo-expandido' : 'conteudo-recolhido'}>
                            {/* Conteúdo do Ensino Infantil */}
                            <Link to="/pedagogico/boletins/infantilI" className='serie-item'>Infantil I <AiFillLock /></Link>
                            <Link to="/pedagogico/boletins/infantilII" className='serie-item'>Infantil II <AiFillLock /></Link>
                            <Link to="/pedagogico/boletins/infantilIII" className='serie-item'>Infantil III <AiFillLock /></Link>
                            <Link to="/pedagogico/boletins/infantilIV" className='serie-item'>Infantil IV <AiFillLock /></Link>
                            <Link to="/pedagogico/boletins/infantilV" className='serie-item'>Infantil V <AiFillLock /></Link>
                        </div>
                    </div>
                    <div className={`container ${fundamentalExpandido ? 'expandido' : ''}`} onClick={toggleFundamental}>
                        <p>Ensino Fundamental I</p>
                        <div className={fundamentalExpandido ? 'conteudo-expandido' : 'conteudo-recolhido'}>
                            <Link to={`${isProf?.primeiroAno ? "/pedagogico/boletins/1ano" : ""}`} className='serie-item'>1º ano {isProf?.primeiroAno ? <AiFillUnlock style={{ font: "bold 12pt arial" ,color:"green"}} /> : <AiFillLock />}</Link>
                            <Link to={`${isProf?.segundoAno ? "/pedagogico/boletins/2ano" : ""}`} className='serie-item'>2º ano {isProf?.segundoAno ? <AiFillUnlock style={{ font: "bold 12pt arial" ,color:"green"}} /> : <AiFillLock />}</Link>
                            <Link to={`${isProf?.terceiroAno ? "/pedagogico/boletins/3ano" : ""}`} className='serie-item'>3º ano {isProf?.terceiroAno ? <AiFillUnlock style={{ font: "bold 12pt arial" ,color:"green"}} /> : <AiFillLock />}</Link>
                            <Link to={`${isProf?.quartoAno ? "/pedagogico/boletins/4ano" : ""}`} className='serie-item'>4º ano {isProf?.quartoAno ? <AiFillUnlock style={{ font: "bold 12pt arial" ,color:"green"}} /> : <AiFillLock />}</Link>
                            <Link to={`${isProf?.quintoAno ? "/pedagogico/boletins/5ano" : ""}`} className='serie-item'>5º ano {isProf?.quintoAno ? <AiFillUnlock style={{ font: "bold 12pt arial" ,color:"green"}} /> : <AiFillLock />}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoletinsMain