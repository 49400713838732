import React, { useState } from 'react'
import Header from '../../../components/header'
import "./MatriculadoAdmin.css"
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchAlunoById, updateMatricula } from '../../../actions/matriculaActions'
import { useParams } from 'react-router'
import jsPDF from 'jspdf';
import ImageData from '../../../assets/OIP.png'
import { RiFolderReduceLine } from "react-icons/ri";


const MatriculadoAdmin = () => {
    const dispatch = useDispatch();
    const { id } = useParams(); // Obtém o parâmetro :id da URL
    const alunoState = useSelector(state => state.alunos); // Assume que você tem um reducer para alunos
    const [aluno, setAluno] = useState({}); // Adicione o estado aluno
    const [isEditing, setIsEditing] = useState(false); // Novo estado local

    useEffect(() => {
        // Quando o componente monta, dispara a ação para buscar os detalhes do aluno
        dispatch(fetchAlunoById(id));
    }, [dispatch, id]);

    // const aluno = alunoState.aluno || {};
    useEffect(() => {
        setAluno(alunoState.aluno || {}); // Atualize o estado aluno quando receber os dados
    }, [alunoState]);

    const generatePDF = () => {
        const docWidth = 300; // Largura do documento em milímetros (padrão A4)
        const docHeight = 297; // Altura do documento em milímetros (padrão A4)
        const doc = new jsPDF({
            unit: 'mm', // Unidade de medida
            format: [docWidth, docHeight] // Define o tamanho do documento
        });
        const rectWidth = 90;
        const rectHeight = 12;
        // Define a posição da imagem
        const i = 105;
        const g = 215;
        const width = 80; // Largura da imagem em unidades de PDF
        const height = 80; // Altura da imagem em unidades de PDF

        // Adiciona a imagem ao PDF
        doc.addImage(ImageData, 'PNG', i, g, width, height);

        doc.setTextColor(255, 0, 0);
        doc.setFontSize(30);
        doc.setFont("helvetica", "bold");
        doc.text(`FICHA DE MATRÍCULA 2024`, 75, 15);
        doc.setTextColor(0, 0, 0); // Restaure para preto
        doc.setFontSize(16); // Restaure para 12 pontos

        const x = 40;
        const y = 30;
        // Dados do Aluno
        const bgColor = "#FFFF00"; // Amarelo
        doc.setFillColor(bgColor);
        doc.rect(x - 2, y - 2, rectWidth + 4, rectHeight + 4, "F");
        doc.setFont("helvetica", "bold");
        doc.text(`Dados do aluno :`, x, y + 10);
        doc.setFont("helvetica", "normal");
        doc.text(`Nome: ${aluno.nomeAluno}`, x, y + 20);
        doc.text(`CPF: ${aluno.CPF}`, x, y + 30);
        doc.text(`Data de Nascimento: ${aluno.dataNascimento}`, x, y + 40);
        doc.text(`Série: ${aluno.serie}`, x, y + 50);
        doc.text(`Tipo Sanguíneo: ${aluno.tiposanguinio}`, x, y + 60);
        doc.text(`Emergência: ${aluno.emergencia}`, x, y + 70);
        doc.text(`Vencimento: ${aluno.vencimento}`, x, y + 80);
        doc.text(`Endereço: ${aluno.endereco}`, x, y + 90);
        doc.text(`Overview: ${aluno.overView}`, x, y + 100);

        // Dados do Responsável
        const z = 160;
        const h = 30;

        doc.setFillColor(bgColor);
        doc.rect(z - 2, h - 2, rectWidth + 4, rectHeight + 4, "F");
        doc.setFont("helvetica", "bold");
        doc.text(`Dados da mãe:`, z, h + 10);
        doc.setFont("helvetica", "normal");
        doc.text(`Nome: ${aluno.nomeResponsavel}`, z, h + 20);
        doc.text(`CPF: ${aluno.cpfResponsavel}`, z, h + 30);
        doc.text(`Email: ${aluno.emailResponsavel}`, z, h + 40);
        doc.text(`Parentesco: ${aluno.parentescoResponsavel}`, z, h + 50);
        doc.text(`Profissão: ${aluno.profisaoResponsavel}`, z, h + 60);
        doc.text(`Telefone: ${aluno.telefoneResponsavel}`, z, h + 70);

        // Responsável Opcional 1
        if (aluno.responsavel1) {
            const p = 40;
            const l = 145;
            const bgColor = "#FFFF00"; // Amarelo
            doc.setFillColor(bgColor);
            doc.rect(p - 2, l - 2, rectWidth + 4, rectHeight + 4, "F");
            doc.setFont("helvetica", "bold");
            doc.text(`Dados do pai:`, p, l + 10); // Aumentei o valor do "l" para evitar sobreposição
            doc.setFont("helvetica", "normal");
            doc.text(`Nome: ${aluno.responsavel1.nomeResponsavel}`, p, l + 20);
            doc.text(`CPF: ${aluno.responsavel1.cpfResponsavel}`, p, l + 30);
            doc.text(`Email: ${aluno.responsavel1.emailResponsavel}`, p, l + 40);
            doc.text(`Parentesco: ${aluno.responsavel1.parentescoResponsavel}`, p, l + 50);
            doc.text(`Telefone: ${aluno.responsavel1.telefoneResponsavel}`, p, l + 60);
        }

        // Responsável Opcional 2
        if (aluno.responsavel2) {
            const q = 160;
            const o = 145;
            const bgColor = "#adadad"; // Amarelo
            doc.setFillColor(bgColor);
            doc.rect(q - 2, o - 2, rectWidth + 4, rectHeight + 4, "F");
            doc.setFont("helvetica", "bold");
            doc.text(`Responsável Opcional :`, q, o + 10); // Aumentei o valor do "o" para evitar sobreposição
            doc.setFont("helvetica", "normal");
            doc.text(`Nome: ${aluno.responsavel2.nomeResponsavel}`, q, o + 20);
            doc.text(`CPF: ${aluno.responsavel2.cpfResponsavel}`, q, o + 30);
            doc.text(`Email: ${aluno.responsavel2.emailResponsavel}`, q, o + 40);
            doc.text(`Parentesco: ${aluno.responsavel2.parentescoResponsavel}`, q, o + 50);
            doc.text(`Telefone: ${aluno.responsavel2.telefoneResponsavel}`, q, o + 60);
        }

        doc.save(`informacoes_${aluno.nomeAluno}.pdf`);
    };

    const handleEditClick = () => {
        setIsEditing(true); // Ativa o modo de edição
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        dispatch(updateMatricula(aluno._id, aluno));
    };
    console.log(aluno)
    console.log(aluno.data)

    const handleArquivoMortoClick = () => {
        const newArqMorto = !aluno.arqMorto; // Inverte o valor atual de arqMorto
        const updatedData = { arqMorto: newArqMorto };
        dispatch(updateMatricula(aluno._id, updatedData));
        setAluno({ ...aluno, arqMorto: newArqMorto }); // Atualiza o estado local para refletir a mudança
    };

    return (
        <div>
            <Header />
            <h1>Matrículas</h1>
            <div className='full-matriculado-detail' style={{}}>
                <div style={{ width: "95%", display: "flex", }}>
                    <button className='button-matriculado-detail' onClick={generatePDF}>Baixar PDF</button>
                    {isEditing ? (
                        <button style={{ marginLeft: "10px", background: 'orange' }} className='button-matriculado-detail' onClick={handleSaveClick}>Salvar dados</button>
                    ) : (
                        <button style={{ marginLeft: "10px", background: 'orange' }} className='button-matriculado-detail' onClick={handleEditClick}>Editar dados</button>
                    )}
                    <button style={{
                        marginLeft: "10px",
                        background: (aluno.arqMorto === false) ? 'green' : 'gray', // Se arqMorto for true, a cor será cinza, senão verde
                    }} className='button-matriculado-detail' onClick={handleArquivoMortoClick}><RiFolderReduceLine style={{ marginRight: "3px" }} />{aluno.arqMorto == false ? "Arquivar" : "Desarquivar"}</button>
                </div>
                <div className='grid-container-1'>
                    {/* aluno */}
                    <div className='grid-container'>
                        <h4>Aluno</h4>
                        <div className='grid-container-2'>
                            <div className='grid-item'>
                                <p>{aluno && aluno.nomeAluno ? (isEditing ? (<input type="text" value={aluno.nomeAluno} onChange={(e) => { setAluno({ ...aluno, nomeAluno: e.target.value }); }} />) : (<p>Nome: {aluno.nomeAluno}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.CPF ? (isEditing ? (<input type="text" value={aluno.CPF} onChange={(e) => { setAluno({ ...aluno, CPF: e.target.value }); }} />) : (<p>CPF: {aluno.CPF}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.dataNascimento ? (isEditing ? (<input type="text" value={aluno.dataNascimento} onChange={(e) => { setAluno({ ...aluno, dataNascimento: e.target.value }); }} />) : (<p>Data de Nascimento: {aluno.dataNascimento}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.serie ? (isEditing ? (<input type="text" value={aluno.serie} onChange={(e) => { setAluno({ ...aluno, serie: e.target.value }); }} />) : (<p>Série: {aluno.serie}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.tiposanguinio ? (isEditing ? (<input type="text" value={aluno.tiposanguinio} onChange={(e) => { setAluno({ ...aluno, tiposanguinio: e.target.value }); }} />) : (<p>Tipo sanguíneo: {aluno.tiposanguinio}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.emergencia ? (isEditing ? (<input type="text" value={aluno.emergencia} onChange={(e) => { setAluno({ ...aluno, emergencia: e.target.value }); }} />) : (<p>Medicamento : {aluno.emergencia}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.vencimento ? (isEditing ? (<input type="text" value={aluno.vencimento} onChange={(e) => { setAluno({ ...aluno, vencimento: e.target.value }); }} />) : (<p>vencimento : {aluno.vencimento} </p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.endereco ? (isEditing ? (<input type="text" value={aluno.endereco} onChange={(e) => { setAluno({ ...aluno, endereco: e.target.value }); }} />) : (<p>Endereço completo: {aluno.endereco} </p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.overView ? (isEditing ? (<input type="text" value={aluno.overView} onChange={(e) => { setAluno({ ...aluno, overView: e.target.value }); }} />) : (<p>Informações gerais : {aluno.overView}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                            </div>
                        </div>
                    </div>
                    {/* Responsáveel */}
                    <div className='grid-container'>
                        <h4>Dados da mãe</h4>
                        <div className='grid-container-2'>
                            <div className='grid-item'>
                                <p>{aluno && aluno.nomeResponsavel ? (isEditing ? (<input type="text" value={aluno.nomeResponsavel} onChange={(e) => { setAluno({ ...aluno, nomeResponsavel: e.target.value }); }} />) : (<p>Nome: {aluno.nomeResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.cpfResponsavel ? (isEditing ? (<input type="text" value={aluno.cpfResponsavel} onChange={(e) => { setAluno({ ...aluno, cpfResponsavel: e.target.value }); }} />) : (<p>CPF: {aluno.cpfResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.emailResponsavel ? (isEditing ? (<input type="text" value={aluno.emailResponsavel} onChange={(e) => { setAluno({ ...aluno, emailResponsavel: e.target.value }); }} />) : (<p>Email: {aluno.emailResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.parentescoResponsavel ? (isEditing ? (<input type="text" value={aluno.parentescoResponsavel} onChange={(e) => { setAluno({ ...aluno, parentescoResponsavel: e.target.value }); }} />) : (<p>Parentesco: {aluno.parentescoResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.profisaoResponsavel ? (isEditing ? (<input type="text" value={aluno.profisaoResponsavel} onChange={(e) => { setAluno({ ...aluno, profisaoResponsavel: e.target.value }); }} />) : (<p>Profissão: {aluno.profisaoResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                <p>{aluno && aluno.telefoneResponsavel ? (isEditing ? (<input type="text" value={aluno.telefoneResponsavel} onChange={(e) => { setAluno({ ...aluno, telefoneResponsavel: e.target.value }); }} />) : (<p>Telefone: {aluno.telefoneResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                            </div>
                        </div>
                    </div>
                    {/* responsavel opcional */}
                    <div className='grid-container'>
                        <h4>Dados do pai</h4>
                        {
                            aluno.responsavel1 ?
                                <div className='grid-container-2'>
                                    <div className='grid-item'>
                                        <p>{aluno.responsavel1 && aluno.responsavel1.nomeResponsavel ? (isEditing ? (<input type="text" value={aluno.responsavel1.nomeResponsavel} onChange={(e) => { setAluno({ ...aluno, responsavel1: { ...aluno.responsavel1, nomeResponsavel: e.target.value, }, }); }} />) : (<p>Nome: {aluno.responsavel1.nomeResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                        <p>{aluno.responsavel1 && aluno.responsavel1.cpfResponsavel ? (isEditing ? (<input type="text" value={aluno.responsavel1.cpfResponsavel} onChange={(e) => { setAluno({ ...aluno, responsavel1: { ...aluno.responsavel1, cpfResponsavel: e.target.value, }, }); }} />) : (<p>Nome: {aluno.responsavel1.cpfResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                        <p>{aluno.responsavel1 && aluno.responsavel1.emailResponsavel ? (isEditing ? (<input type="text" value={aluno.responsavel1.emailResponsavel} onChange={(e) => { setAluno({ ...aluno, responsavel1: { ...aluno.responsavel1, emailResponsavel: e.target.value, }, }); }} />) : (<p>Nome: {aluno.responsavel1.emailResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                        <p>{aluno.responsavel1 && aluno.responsavel1.parentescoResponsavel ? (isEditing ? (<input type="text" value={aluno.responsavel1.parentescoResponsavel} onChange={(e) => { setAluno({ ...aluno, responsavel1: { ...aluno.responsavel1, parentescoResponsavel: e.target.value, }, }); }} />) : (<p>Nome: {aluno.responsavel1.parentescoResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                        <p>{aluno.responsavel1 && aluno.responsavel1.profisaoResponsavel ? (isEditing ? (<input type="text" value={aluno.responsavel1.profisaoResponsavel} onChange={(e) => { setAluno({ ...aluno, responsavel1: { ...aluno.responsavel1, profisaoResponsavel: e.target.value, }, }); }} />) : (<p>Nome: {aluno.responsavel1.profisaoResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>
                                        <p>{aluno.responsavel1 && aluno.responsavel1.telefoneResponsavel ? (isEditing ? (<input type="text" value={aluno.responsavel1.telefoneResponsavel} onChange={(e) => { setAluno({ ...aluno, responsavel1: { ...aluno.responsavel1, telefoneResponsavel: e.target.value, }, }); }} />) : (<p>Nome: {aluno.responsavel1.telefoneResponsavel}</p>)) : (<p>Dados do aluno não disponíveis.</p>)}</p>

                                    </div>
                                </div>
                                :
                                <div>Nâo foi prenchido nenhum responsavel aqui</div>
                        }
                    </div>
                    <div className='grid-container'>
                        <h4>Responsável opcional</h4>
                        {
                            aluno.responsavel1 ?
                                <div className='grid-container-2'>
                                    <div className='grid-item'>
                                        <p>Nome: {aluno.responsavel2 && aluno.responsavel2.nomeResponsavel ? aluno.responsavel2.nomeResponsavel : 'Dados não disponíveis'}</p>
                                        <p>CPF: {aluno.responsavel2 && aluno.responsavel2.cpfResponsavel ? aluno.responsavel2.cpfResponsavel : 'Dados não disponíveis'}</p>
                                        <p>Email: {aluno.responsavel2 && aluno.responsavel2.emailResponsavel ? aluno.responsavel2.emailResponsavel : 'Dados não disponíveis'}</p>
                                        <p>Parentesco: {aluno.responsavel2 && aluno.responsavel2.parentescoResponsavel ? aluno.responsavel2.parentescoResponsavel : 'Dados não disponíveis'}</p>
                                        <p>Profissão: {aluno.responsavel2 && aluno.responsavel2.profisaoResponsavel ? aluno.responsavel2.profisaoResponsavel : 'Dados não disponíveis'}</p>
                                        <p>Telefone: {aluno.responsavel2 && aluno.responsavel2.telefoneResponsavel ? aluno.responsavel2.telefoneResponsavel : 'Dados não disponíveis'}</p>
                                    </div>
                                </div>
                                :
                                <div>Nâo foi prenchido nenhum responsavel aqui</div>
                        }
                    </div>
                    {/* como chegou ao colegio  */}
                    <div className='grid-container'>
                        <h4>Como chegou ? </h4>
                        {
                            aluno.comoChegou ?
                                <div style={{ width: "100%" }} className='grid-container-2'>
                                    <div className='grid-item' >
                                        <p>Google:{aluno.comoChegou.google === true ? "sim" : "não"}</p>
                                        <p>Instagram:{aluno.comoChegou.instagram === true ? "sim" : "não"}</p>
                                        <p>Facebook:{aluno.comoChegou.facebook === true ? "sim" : "não"}</p>
                                        <p>Tiktok:{aluno.comoChegou.tiktok === true ? "sim" : "não"}</p>
                                        <p>Foi indicação? {aluno.comoChegou.indicacao === "" ? "não" : "sim,"}{aluno.comoChegou.indicacao}</p>
                                    </div>
                                </div>
                                :
                                <div>Nâo foi prenchido nenhum responsavel aqui</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MatriculadoAdmin