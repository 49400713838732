const initialState = {
  loading: false,
  userInfo: null,
  stateMatriculaEscola: false,
  profileImageURL: null, // Adicionado profileImageURL ao estado inicial
  error: null,
  users: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_SIGNIN_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'USER_SIGNIN_SUCCESS':
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        profileImageURL: action.payload.profileImageURL,
        error: null
      };
    case 'USER_SIGNIN_FAILURE':
      return {
        ...state,
        loading: false,
        userInfo: null,
        error: action.payload
      };
    case 'USER_REGISTER_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'USER_REGISTER_SUCCESS':
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        profileImageURL: action.payload.profileImageURL,
        error: null
      };
    case 'USER_REGISTER_FAILURE':
      return {
        ...state,
        loading: false,
        userInfo: null,
        error: action.payload
      };
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LIST_REQUEST':
    case 'USER_DELETE_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'USER_LIST_SUCCESS':
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };
    case 'USER_DELETE_SUCCESS':
      return {
        ...state,
        loading: false,
        users: state.users.filter((user) => user._id !== action.payload),
        error: null,
      };
    case 'USER_LIST_FAILURE':
    case 'USER_DELETE_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
