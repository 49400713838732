import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';


const AtividadesAgenda2ano = () => {
    return (
        <>
            <AtividadeComponente materiaSelect={"2ano"} anoName="2°ano"/>
        </>
    );
};

export default AtividadesAgenda2ano;
