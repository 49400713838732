import React from 'react'
import '../components-style/banner.css'
import { Link } from 'react-router-dom'
import Modalidadesmatriculas from './modalidadesmatriculas'
import img from '../assets/bolha-de-bate-papo.png'
const banner = () => {
  return (
    <div className='banner'>
      <div className='shadow'>
        <section className='sec-banner-2'>
          <Modalidadesmatriculas />
        </section>
      </div>
    </div>
  )
}

export default banner