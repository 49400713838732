import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, listUsers, updateIsAluno } from '../../../actions/userActions';
import Header from '../../../components/header';
import "./AdminArea.css"
import { MdFamilyRestroom } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TailSpin } from 'react-loader-spinner';
import apiAxios from '../../../api/apiAxios';

const AdminArea = () => {
  const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
  const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);

  const dispatch = useDispatch();
  const userList = useSelector((state) => state.user);
  const { loading, users, error } = userList;
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateUserIsAluno = async (userId, newValue) => {
    try {
      const updatedUser = {
        ...selectedUser,
        isAluno: {
          ...selectedUser.isAluno,
          isAluno: newValue
        }
      };

      await apiAxios.put(`/api/users/edit/${userId}`, {
        isAluno: updatedUser.isAluno
      });

      closeModal();
    } catch (error) {
      console.error('Error updating isAluno.isAluno:', error);
    }
  };

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  const openModal = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      <h1>Matrículas</h1>
      <div>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <TailSpin // Use o componente TailSpin para o indicador de carregamento
              color="red"
              height={80}
              width={80}
            />
          </div>
        ) : error ? (
          <p>Ocorreu um erro: {error}</p>
        ) : (
          <div className='full-matriculasadmin'>
            <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>Usuários na Plataforma</h4>
            <div className='matriculasadmin-elements'>
              <p style={{ color: "black", font: "bold 13pt arial" }}>Total de matrículas : {users.length}</p>
              {users.map((inscricao) => (
                <div className='matriculasadmin-element' key={inscricao._id} onClick={() => openModal(inscricao)}>
                  <div className='matriculasadmin-sec-1'>
                    <MdFamilyRestroom />
                  </div>
                  <div matriculasadmin-sec-2>
                    <p>Nome: {inscricao.firstName}</p>
                    <p>Sobrenome: {inscricao.lastName}</p>
                    {isCoAdmin === true ? ("") : (
                      <>
                        <p>Direção: {inscricao.isAdmin === true ? "sim" : "não"}</p>
                        <p>Coordenação: {inscricao.isCoAdmin === true ? "sim" : "não"}</p>
                        <p>Professor: {inscricao.isProfessor.isProfessor === true ? "sim" : "não"}</p>
                      </>
                    )}
                    <p>Aluno: {inscricao.isAluno.isAluno === true ? "sim" : "não"}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Render Bootstrap modal conditionally */}
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Detalhes do Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add user details here */}
          {selectedUser ? (
            <div>
              <p>Nome: {selectedUser.firstName}</p>
              <p>Sobrenome: {selectedUser.lastName}</p>
              <p>Email: {selectedUser.email}</p>
              <p>CPF: {selectedUser.cpf}</p>
              {isCoAdmin === true ? ("") : (
                <>
                  <p>Direção: {selectedUser.isAdmin === true ? "sim" : "não"}</p>
                  <p>Coordenação: {selectedUser.isCoAdmin === true ? "sim" : "não"}</p>
                  <p>Professor: {selectedUser.isProfessor.isProfessor === true ? "sim" : "não"}</p>
                </>
              )}
              <p>Aluno: {selectedUser.isAluno?.isAluno === true ? "sim" : "não"}</p>
            </div>
          ) : (
            <p>Carregando detalhes do usuário...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant={selectedUser && selectedUser.isAluno && selectedUser.isAluno.isAluno ? "danger" : "success"}
            onClick={() => updateUserIsAluno(selectedUser?._id, !selectedUser?.isAluno?.isAluno)}
          >
            {selectedUser && selectedUser.isAluno && selectedUser.isAluno.isAluno ? "Remover Aluno" : "Tornar Aluno"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminArea;
