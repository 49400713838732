const initialState = {
  loading: false,
  error: null,
  matriculaData: [],
};

const MatriculaReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MATRICULA_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'MATRICULA_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        matriculaData: action.payload,
      };
    case 'MATRICULA_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'MATRICULA_UPDATE_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'MATRICULA_UPDATE_SUCCESS':
      // Atualize os dados da matrícula no estado com os novos dados recebidos
      return {
        ...state,
        loading: false,
        error: null,
        matriculaData: action.payload,
      };
    case 'MATRICULA_UPDATE_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'MATRICULA_DELETE_SUCCESS':
      // Remova a matrícula deletada do estado
      const updatedMatriculaData = state.matriculaData.filter(
        (matricula) => matricula.id !== action.payload
      );
      return {
        ...state,
        loading: false,
        error: null,
        matriculaData: updatedMatriculaData,
      };
    case 'MATRICULA_DELETE_REQUEST':
      return {
        ...state,
        loading: true,
      };
    case 'MATRICULA_DELETE_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default MatriculaReducer;
