import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';


const AtividadesAgendaInfantil2 = () => {

    return (
        <AtividadeComponente materiaSelect="Infantil II" anoName="Infantil II" />

    );
};

export default AtividadesAgendaInfantil2;
