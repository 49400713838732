import React, { useState, useEffect } from 'react';
import Header from '../../../components/header';
import apiAxios from '../../../api/apiAxios';
import { MdFamilyRestroom } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const FichaCatequese = () => {
    const [catequeseData, setCatequeseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredCampanha, setFilteredCampanha] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchCatequeseData = async () => {
            try {
                const response = await apiAxios.get('/api/matriculasCatequese/inscricoes'); // Substitua pela rota correta
                if (response.status === 200) {
                    setCatequeseData(response.data);
                    setLoading(false);
                } else {
                    setError('Erro ao buscar dados de catequese.');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Erro inesperado:', error);
                setError('Erro inesperado ao buscar dados de catequese.');
                setLoading(false);
            }
        };

        fetchCatequeseData();
    }, []);

    useEffect(() => {
        // Filtre as inscrições com base no searchTerm
        const filteredData = catequeseData.filter((inscricao) =>
            inscricao.nome.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCampanha(filteredData);
    }, [catequeseData, searchTerm]);

    return (
        <div>
            <Header />
                <div className='return-icon-portal' >
                    <Link to="/"><FaArrowLeft /><p>Voltar</p></Link>
                </div>
                {loading ? (
                    <p>Carregando...</p>
                ) : error ? (
                    <p>Ocorreu um erro: {error}</p>
                ) : (
                    <div className='full-matriculasadmin'>
                        <h4 style={{ color: 'red', font: 'bold 17pt arial', margin: '20px' }}>
                            Inscrições para a Catequese
                        </h4>

                        {filteredCampanha && filteredCampanha.length > 0 ? (
                            <div className='matriculasadmin-elements'>
                                <p style={{ color: 'black', font: 'bold 13pt arial' }}>
                                    Total de matrículas : {filteredCampanha.length}
                                </p>
                                <div>
                                    {/* Adicione um campo de pesquisa */}
                                    <input
                                        type='text'
                                        placeholder='Pesquisar por nome'
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                {filteredCampanha.map((inscricao) => (
                                    <div className='matriculasadmin-element' key={inscricao._id}>
                                        <Link to={`/menuadmin/fichascatequese/${inscricao._id}`}>
                                            <div className='matriculasadmin-sec-1'>
                                                <MdFamilyRestroom />
                                            </div>
                                            <div className='matriculasadmin-sec-2'>
                                                <p>Criança: {inscricao.nome}</p>
                                                <p>Nome do pai: {inscricao.nomePai}</p>
                                                <p>Nome da mãe: {inscricao.nomeMae}</p>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Nenhuma inscrição encontrada.</p>
                        )}
                    </div>
                )}
            </div>
    );
};

export default FichaCatequese;
