import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import apiAxios from '../../../api/apiAxios';
import { useParams } from 'react-router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ImageData from '../../../assets/OIP.png';

const FichaEsportesDetail = () => {
    const { id } = useParams(); // Obtém o parâmetro :id da URL
    const [alunoDetail, setAlunoDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // Novo estado local
    const [editedAlunoDetail, setEditedAlunoDetail] = useState({}); // Estado para dados editados

    useEffect(() => {
        async function fetchAlunoDetail() {
            try {
                const response = await apiAxios.get(`/api/matriculasEsportes/matriculas/${id}`);
                console.log("->", response.data.data)
                setAlunoDetail(response.data.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }

        fetchAlunoDetail();
    }, [id]);

    const handleEditClick = () => {
        setIsEditing(true);
        // Copie os dados atuais para o estado de dados editados
        setEditedAlunoDetail({ ...alunoDetail });
    };

    // Função para lidar com o clique no botão "Salvar"
    const handleSaveClick = async () => {
        try {
            // Envie uma solicitação para atualizar os dados
            const response = await apiAxios.put(`/api/matriculasEsportes/matriculas/${id}`, editedAlunoDetail);
            // Atualize o estado local com os novos dados
            setAlunoDetail(response.data.data);
            setIsEditing(false); // Desative o modo de edição
        } catch (error) {
            setError(error.message);
        }
    };


    const formatarData = (data) => {
        const dataInscricao = new Date(data);
        return dataInscricao.toLocaleDateString(); // Formata a data para um formato legível
    };

    const generatePDF = () => {
        if (alunoDetail) {
            const doc = new jsPDF({
                unit: 'mm', // Unidade em milímetros
                format: [210, 500] // Largura x Altura da página
            });

            // Define a fonte e o tamanho do texto principal
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(12);

            // Título principal
            doc.setTextColor(255, 0, 0);
            doc.setFont('bold');
            doc.setFontSize(20);
            doc.text(`Ficha de Inscrição Esportes`, 68, 15);

            // Restaure a cor e o estilo do texto
            doc.setTextColor(0, 0, 0);
            doc.setFont('normal');

            // Dados do Aluno
            const x = 10;
            let y = 30;

            const i = 80;
            const g = 215;
            const width = 50;
            const height = 0;

            // Defina as coordenadas corretas para posicionar a imagem
            const imgX = 80; // Posição X da imagem
            const imgY = 400; // Posição Y da imagem
            const imgWidth = 50; // Largura da imagem
            const imgHeight = 50; // Altura da imagem

            // Adicione a imagem ao PDF
            doc.addImage(ImageData, 'PNG', imgX, imgY, imgWidth, imgHeight);

            // Crie um retângulo para destacar os dados do aluno
            doc.setFillColor("#FFF");
            doc.rect(x, y, 100, 10, "F");

            // Crie uma matriz com os dados do aluno
            const data = [
                ["Nome do Aluno:", alunoDetail.dadosAluno.nomeAluno],
                ["CPF:", alunoDetail.dadosAluno.CPF],
                ["Com Quem Mora:", alunoDetail.dadosAluno.comQuemMora],
                ["Data de Nascimento:", alunoDetail.dadosAluno.dataNascimento],
                ["Endereço:", alunoDetail.dadosAluno.endereco],
                ["Nome da Mãe:", alunoDetail.dadosAluno.nomeMae],
                ["Nome do Pai:", alunoDetail.dadosAluno.nomePai],
                ["Plano de Saúde:", alunoDetail.dadosAluno.planoDeSaude],
                ["Telefone da Mãe:", alunoDetail.dadosAluno.telefoneMae],
                ["Telefone do Pai:", alunoDetail.dadosAluno.telefonePai],
                ["Tipo Sanguíneo:", alunoDetail.dadosAluno.tiposanguinio],
                ["CPF do Responsável:", alunoDetail.dadosResponsavelFinanceiro.cpfResponsavel],
                ["Email do Responsável:", alunoDetail.dadosResponsavelFinanceiro.emailResponsavel],
                ["Endereço Completo do Responsável:", alunoDetail.dadosResponsavelFinanceiro.enderecoCompleto],
                ["Nome do Responsável:", alunoDetail.dadosResponsavelFinanceiro.nomeResponsavel],
                ["Parentesco do Responsável:", alunoDetail.dadosResponsavelFinanceiro.parentescoResponsavel],
                ["Profissão do Responsável:", alunoDetail.dadosResponsavelFinanceiro.profisaoResponsavel],
                ["Telefone do Responsável:", alunoDetail.dadosResponsavelFinanceiro.telefoneResponsavel],
                ["Data de Inscrição:", formatarData(alunoDetail.dataInscricao)],
                ["Qual Esporte:", alunoDetail.qualEsporte[0]],
                ["Vencimento:", alunoDetail.vencimento]
            ];

            // Crie a tabela com os dados do aluno
            doc.autoTable({
                startY: y + 10,
                head: [['Descrição', 'Valor']],
                body: data,
                theme: 'grid',
                styles: {
                    fontSize: 12,
                    cellPadding: 5,
                    fontStyle: 'normal'
                },
                didDrawCell: (data) => {
                    if (data.section === 'body' && (data.row.index === 0 || data.row.index === 1)) {
                        data.cell.styles.fillColor = [255, 255, 0];
                    }
                },
            });

            // Use o nome do aluno para salvar o PDF
            const nomeAluno = alunoDetail.dadosAluno.nomeAluno.replace(/ /g, "_");
            doc.save(`informacoes_${nomeAluno}.pdf`);
        }
    };

    return (
        <div>
            <Header />
            <h1>Matrículas</h1>
            <div className='full-matriculado-detail'>
                <div style={{ width: "95%", display: "flex", margin: "10px" }}>
                    <button className='button-matriculado-detail' onClick={generatePDF}>Baixar PDF</button>
                    {isEditing ? (
                        <button style={{ marginLeft: "10px", background: 'orange' }} className='button-matriculado-detail' onClick={handleSaveClick}>Salvar</button>
                    ) : (
                        <button style={{ marginLeft: "10px", background: 'orange' }} className='button-matriculado-detail' onClick={handleEditClick}>Editar</button>
                    )}
                </div>
                {loading ? (
                    <p>Carregando...</p>
                ) : error ? (
                    <p>Ocorreu um erro: {error}</p>
                ) : alunoDetail ? (
                    <div className='grid-container'>
                        <h4>Detalhes do Aluno</h4>
                        <div className='grid-container-2'>
                            <div className='grid-item'>
                                <p>Nome do Aluno: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.nomeAluno} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, nomeAluno: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.nomeAluno}</span>}</p>
                                <p>CPF: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.CPF} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, CPF: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.CPF}</span>}</p>
                                <p>Com quem mora: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.comQuemMora} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, comQuemMora: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.comQuemMora}</span>}</p>
                                <p>Data de Nascimento: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.dataNascimento} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, dataNascimento: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.dataNascimento}</span>}</p>
                                <p>Endereço: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.endereco} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, endereco: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.endereco}</span>}</p>
                                <p>Nome da Mãe: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.nomeMae} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, nomeMae: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.nomeMae}</span>}</p>
                                <p>Nome do Pai: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.nomePai} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, nomePai: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.nomePai}</span>}</p>
                                <p>Plano de Saúde: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.planoDeSaude} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, planoDeSaude: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.planoDeSaude}</span>}</p>
                                <p>Telefone da Mãe: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.telefoneMae} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, telefoneMae: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.telefoneMae}</span>}</p>
                                <p>Telefone do Pai: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.telefonePai} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, telefonePai: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.telefonePai}</span>}</p>
                                <p>Tipo Sanguíneo: {isEditing ? <input type="text" value={editedAlunoDetail.dadosAluno.tiposanguinio} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosAluno: { ...editedAlunoDetail.dadosAluno, tiposanguinio: e.target.value } })} /> : <span>{alunoDetail.dadosAluno.tiposanguinio}</span>}</p>
                                <p>CPF do Responsável: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.cpfResponsavel} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, cpfResponsavel: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.cpfResponsavel}</span>}</p>
                                <p>Email do Responsável: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.emailResponsavel} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, emailResponsavel: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.emailResponsavel}</span>}</p>
                                <p>Endereço Completo: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.enderecoCompleto} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, enderecoCompleto: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.enderecoCompleto}</span>}</p>
                                <p>Nome do Responsável: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.nomeResponsavel} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, nomeResponsavel: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.nomeResponsavel}</span>}</p>
                                <p>Parentesco do Responsável: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.parentescoResponsavel} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, parentescoResponsavel: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.parentescoResponsavel}</span>}</p>
                                <p>Profissão do Responsável: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.profisaoResponsavel} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, profisaoResponsavel: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.profisaoResponsavel}</span>}</p>
                                <p>Telefone do Responsável: {isEditing ? <input type="text" value={editedAlunoDetail.dadosResponsavelFinanceiro.telefoneResponsavel} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, dadosResponsavelFinanceiro: { ...editedAlunoDetail.dadosResponsavelFinanceiro, telefoneResponsavel: e.target.value } })} /> : <span>{alunoDetail.dadosResponsavelFinanceiro.telefoneResponsavel}</span>}</p>
                                <p>Qual Esporte: {isEditing ? <input type="text" value={editedAlunoDetail.qualEsporte[0]} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, qualEsporte: [e.target.value] })} /> : <span>{alunoDetail.qualEsporte[0]}</span>}</p>
                                <p>Vencimento: {isEditing ? <input type="text" value={editedAlunoDetail.vencimento} onChange={e => setEditedAlunoDetail({ ...editedAlunoDetail, vencimento: e.target.value })} /> : <span>{alunoDetail.vencimento}</span>}</p>
                                <p style={{ color: "green" }}>Data de Inscrição: {formatarData(alunoDetail.dataInscricao)}</p>
                                {/* Adicione mais campos aqui conforme necessário */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Aluno não encontrado.</p>
                )}
            </div>
        </div>
    );
};

export default FichaEsportesDetail;
