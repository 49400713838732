import React, { useState } from 'react';
import "../components-style/manual.css";

const Manual = () => {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    endereco: '',
    comoEncontrou: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDownload = () => {
    // Aqui você pode adicionar lógica para gerar e baixar o PDF com base nos dados do formulário.
    // Por exemplo, você pode usar uma biblioteca como jsPDF para criar o PDF.
    // Depois de gerar o PDF, você pode redirecionar o usuário para o arquivo PDF ou oferecer um link de download.

    // Supondo que você tenha gerado o PDF e o armazenou em uma variável chamada "pdfData":
     const pdfData = ''; // Os dados do PDF gerado

    // Você pode criar um blob de dados do PDF e criar um URL para ele.
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    // Crie um link para o URL do blob.
    const a = document.createElement('a');
    a.href = url;
    a.download = 'manual_de_matriculas.pdf'; // Nome do arquivo para download

    // Simule um clique no link para iniciar o download.
    a.click();

    // Libere o objeto URL criado.
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className='manual-container'>
      <h1 className='manual-title'>Baixe o nosso manual de matrículas</h1>
      <div className='form-container'>
        <form>
          <label htmlFor="nome" className='form-label'>Nome:</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            className='form-input'
          />

          <label htmlFor="email" className='form-label'>Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className='form-input'
          />

          <label htmlFor="endereco" className='form-label'>Endereço:</label>
          <input
            type="text"
            id="endereco"
            name="endereco"
            value={formData.endereco}
            onChange={handleChange}
            className='form-input'
          />

          <label htmlFor="comoEncontrou" className='form-label'>Como encontrou o colégio:</label>
          <input
            type="text"
            id="comoEncontrou"
            name="comoEncontrou"
            value={formData.comoEncontrou}
            onChange={handleChange}
            className='form-input'
          />

          <button type="button" onClick={handleDownload} className='download-button'>
            Baixar Manual de Matrículas
          </button>
        </form>
      </div>
    </div>
  );
};

export default Manual;
