import React from 'react'
import "../components-style/ensino.css"
import img1 from '../assets/infantill.png'
import img2 from '../assets/fundamental.jpg'

const Ensino = () => {
  return (
    <div className='ensino-screen'>
      <p style={{ margin: "30px" }} >Conheça nosso ensino</p>
      <div className='grid-ensino'>
        <div className='grid-item-ensino'>
          <div className='item-ensino'>
            <div className='item-img'>
              <img src={img1} alt='Ensino infantil'></img>
            </div>
          </div>
          <div className='item-ensino'>
            <div className='item-ensino-element-part'>
              <h4 style={{ color: "red" }}>Ensino infantil</h4>
              <p>A proposta pedagógica da educação infantil no Colégio Alternativo Colaço é fundamentada na Base Nacional Comum Curricular (BNCC), que estabelece diretrizes essenciais para o desenvolvimento integral das crianças. Priorizando uma abordagem centrada no aluno, o colégio busca promover experiências educativas significativas, que estimulem o desenvolvimento cognitivo, socioemocional e motor das crianças. Através de práticas pedagógicas inovadoras, o Colégio Alternativo Colaço visa proporcionar um ambiente acolhedor e estimulante, onde as crianças sintam-se seguras para explorar, criar e aprender, desenvolvendo assim habilidades essenciais para sua formação plena como cidadãos críticos e participativos na sociedade.</p>
            </div>
          </div>
        </div>
        <div className='grid-item-ensino'>
          <div className='item-ensino'>
            <div className='item-img'>
              <img src={img2} alt='Ensino fundamental'></img>
            </div>
          </div>
          <div className='item-ensino'>
            <div className='item-ensino-element-part'>
              <h4 style={{ color: "red" }}>Ensino fundamental I</h4>
              <p>A proposta pedagógica do ensino fundamental no Colégio Alternativo Colaço é alinhada com os princípios e diretrizes estabelecidos pela Base Nacional Comum Curricular (BNCC), que norteiam a educação básica no país. Priorizando uma abordagem interdisciplinar e contextualizada, o colégio busca promover um ensino que valorize não apenas o desenvolvimento acadêmico, mas também as habilidades socioemocionais e o pensamento crítico dos alunos. Através de projetos pedagógicos integrados e práticas educativas inovadoras, o Colégio Alternativo Colaço visa formar estudantes autônomos, criativos e comprometidos com valores éticos e sociais, preparando-os para os desafios do mundo contemporâneo e para uma participação ativa na construção de uma sociedade mais justa e democrática.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ensino