import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';

const AtividadesAgendaInfantil3 = () => {

    return (
        <AtividadeComponente materiaSelect="Infantil III" anoName="Infantil III" />
    );
};

export default AtividadesAgendaInfantil3;
