import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import apiAxios from '../../../api/apiAxios';
import { Link } from 'react-router-dom';
import { MdSportsBasketball } from 'react-icons/md'; // Use um ícone esportivo apropriado

const FichasEsportes = () => {
    const [loading, setLoading] = useState(true);
    const [esportes, setEsportes] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEsportes, setFilteredEsportes] = useState([]);

    useEffect(() => {
        async function fetchEsportes() {
            try {
                const response = await apiAxios.get('/api/matriculasEsportes/matriculas');
                console.log("-->", response.data)
                setEsportes(response.data.data);
                setFilteredEsportes(response.data.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }

        fetchEsportes();
    }, []);

    useEffect(() => {
        // Filtrar as inscrições com base no nome ao digitar
        const filteredData = esportes.filter((inscricao) =>
            inscricao && inscricao.dadosAluno.nomeAluno && inscricao.dadosAluno.nomeAluno.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const teste = esportes.filter((inscricao) =>
            console.log("teste",inscricao)
        );
        console.log(teste)
        setFilteredEsportes(filteredData);
    }, [searchTerm, esportes]);

    return (
        <div>
            <Header />
            <h1>Matrículas</h1>
            {loading ? (
                <p>Carregando...</p>
            ) : error ? (
                <p>Ocorreu um erro: {error}</p>
            ) : (
                <div className='full-matriculasadmin'>
                    <h4 style={{ color: "red", fontWeight: "bold", fontSize: "17pt", margin: "20px" }}>
                        Inscrições Esportivas
                    </h4>

                    {filteredEsportes && filteredEsportes.length > 0 ? (
                        <div className='matriculasadmin-elements'>
                            <p style={{ color: "black", fontWeight: "bold", fontSize: "13pt" }}>
                                Total de matrículas: {filteredEsportes.length}
                            </p>
                            <div>
                                {/* Adicione um campo de pesquisa */}
                                <input
                                    type="text"
                                    placeholder="Pesquisar por nome"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            {filteredEsportes.slice().reverse().map((inscricao) => (
                                <div className='matriculasadmin-element' key={inscricao._id}>
                                    <Link to={`/menuadmin/fichasEsportes/${inscricao._id}`}>
                                        <div className='matriculasadmin-sec-1'>
                                            <MdSportsBasketball />
                                        </div>
                                        <div className='matriculasadmin-sec-2'>
                                            <p>Criança: {inscricao.dadosAluno && inscricao.dadosAluno.nomeAluno}</p>
                                            <p>Responsável: {inscricao.dadosResponsavelFinanceiro && inscricao.dadosResponsavelFinanceiro.nomeResponsavel}</p>
                                            <p>Esporte: {inscricao.qualEsporte && inscricao.qualEsporte.length > 0 ? inscricao.qualEsporte[0] : 'Nenhum esporte especificado'}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}

                        </div>
                    ) : (
                        <p>Nenhuma inscrição encontrada.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default FichasEsportes;
