import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import axios from 'axios'; // Importe o axios para fazer solicitações HTTP
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Modal } from 'react-bootstrap'; // Importe o Modal do react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Importe o CSS do Bootstrap
import { MdOutlineLibraryAdd } from 'react-icons/md';

const Cardapio = () => {
    const [formData, setFormData] = useState({
        LanchedaManha: {
            infatil1ao3: '',
            infatil4ao5ano: '',
        },
        Almoco: '',
        LanchedaTarde: '',
        prejanta: '',
        date: new Date(),
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [firstLevel, secondLevel] = name.split('.');
        if (secondLevel) {
            setFormData((prevData) => ({
                ...prevData,
                [firstLevel]: {
                    ...prevData[firstLevel],
                    [secondLevel]: value,
                },
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/cardapio', formData);
            console.log('Novo item de cardápio criado:', response.data);
            // Aqui você pode redirecionar o usuário para a página de visualização do cardápio ou fazer qualquer outra ação necessária
        } catch (error) {
            console.error('Erro ao criar novo item de cardápio:', error);
        }
    };

    const [cardapioList, setCardapioList] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false); // Estado para controlar a abertura e o fechamento do modal

    useEffect(() => {
        const fetchCardapio = async () => {
            try {
                const response = await axios.get('/api/cardapio');
                setCardapioList(response.data);
            } catch (error) {
                console.error('Erro ao buscar itens do cardápio:', error);
            }
        };
        fetchCardapio(); // Chamada da função de busca ao montar o componente
    }, []);

    const filteredCardapioList = cardapioList.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate.getFullYear() === formData.date.getFullYear() &&
            itemDate.getMonth() === formData.date.getMonth() &&
            itemDate.getDate() === formData.date.getDate();
    });
    
    const handleCalendarChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            date: date,
        }));
    };

    return (
        <div style={{ height: '300vh' }}>
            <Header />
            <div className='return-icon-portal'><Link to="/"><FaArrowLeft /><p>Voltar</p></Link></div>
            <div className="agendamain-fullscreen">

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    show={modalIsOpen}
                    onHide={() => setModalIsOpen(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                >

                    <Modal.Header closeButton>
                        <Modal.Title><h4 style={{ color: "red", font: "bold 18pt arial", marginBottom: "10px", margin: "5px", marginLeft: "5px" }}>Adicionar cardápio</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="agenda-form-series" onSubmit={handleSubmit}>
                            <label htmlFor="LanchedaManha1ao3">Lanche da Manhã (Infantil 1 ao 3 anos):</label>
                            <input
                                type="text"
                                id="LanchedaManha1ao3"
                                name="LanchedaManha.infatil1ao3"
                                value={formData.LanchedaManha.infatil1ao3}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="LanchedaManha4ao5">Lanche da Manhã (Infantil 4 ao 5 anos):</label>
                            <input
                                type="text"
                                id="LanchedaManha4ao5"
                                name="LanchedaManha.infatil4ao5ano"
                                value={formData.LanchedaManha.infatil4ao5ano}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="Almoco">Almoço:</label>
                            <input
                                type="text"
                                id="Almoco"
                                name="Almoco"
                                value={formData.Almoco}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="LanchedaTarde">Lanche da Tarde:</label>
                            <input
                                type="text"
                                id="LanchedaTarde"
                                name="LanchedaTarde"
                                value={formData.LanchedaTarde}
                                onChange={handleChange}
                                required
                            />
                            <label htmlFor="prejanta">Pré-janta:</label>
                            <input
                                type="text"
                                id="prejanta"
                                name="prejanta"
                                value={formData.prejanta}
                                onChange={handleChange}
                                required
                            />
                            <button type="submit">Criar Novo Item</button>
                        </form>
                    </Modal.Body>
                </Modal>

                <div className='atv-agenda-screen'>
                    <div className="custom-calendar-full">
                        <Calendar
                            onChange={handleCalendarChange}
                            value={formData.date}
                            calendarType="gregory"
                            className="custom-calendar"
                            locale="pt-BR"
                            view="month"
                        />
                    </div>
                    {filteredCardapioList.map((item, index) => (
                        <div className='atividade-agenda-modal-view-detail-container'>
                            <div key={index}>
                                <div className='orientation'>
                                    <div>
                                        <p><strong style={{ color: "#FF5050" }}>Infantil 1 ao 3:</strong> <br /> {item.LanchedaManha && item.LanchedaManha.infatil1ao3}</p>
                                        <p><strong style={{ color: "#FF5050" }}>Habilidades: <br /></strong>{item.LanchedaManha && item.LanchedaManha.infatil4ao5ano}</p>
                                    </div>
                                    <div>
                                        <p><strong style={{ color: "#FF5050" }}>Metodologia: <br /></strong>{item.Almoco}</p>
                                        <p><strong style={{ color: "#FF5050" }}>Metodologia: <br /></strong>{item.LanchedaTarde}</p>
                                    </div>
                                </div>

                                <div className='orientation'>
                                    <div>
                                        <p><strong style={{ color: "#FF5050" }}>Metodologia: <br /></strong>{item.prejanta}</p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="agenda-too-bar">
                    <button onClick={() => setModalIsOpen(true)}><MdOutlineLibraryAdd style={{ fontSize: "20px", marginRight: "5px" }} />Adicionar envio</button>
                </div>
            </div>
        </div>
    );
};

export default Cardapio;
