import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import './App.css';
import HomeScreen from './Screens/HomeScreen';
import MatriculaEscolaScreen from './Screens/authentication/matriculaEscolaScreen';
import { useSelector } from 'react-redux';
import AdminArea from './Screens/Matriculas/AdminArea/AdminArea';
import UserArea from './Screens/userarea/UserArea';
import LoginEscolaScreen from './Screens/authentication/LoginEscolaScreen';
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from './Screens/NotFound';
import Profile from './Screens/Profile/Profile';
import MenuAdmin from './Screens/Matriculas/main/MenuAdmin';
import MatriculasAdmin from './Screens/Matriculas/MatriculasAdmin/MatriculasAdmin';
import MatriculadoAdmin from './Screens/Matriculas/MatriculadoAdmin/MatriculadoAdmin';
import InscricoesCampanha from './Screens/Matriculas/InscriçõesCampanha/InscricoesCampanha';
import InscricoesCampanhaDetail from './Screens/Matriculas/InscriçõesCampanhaDetail/InscricoesCampanhaDetail';
import FichasEsportes from './Screens/Matriculas/fichasEsportes/FichasEsportes';
import FichaEsportesDetail from './Screens/Matriculas/fichaEsportesDetail/FichaEsportesDetail';
import FichaColonia from './Screens/Matriculas/fichaColonia/fichaColonia';
// Pedagogico
import BoletinsMain from './Screens/Pedagogico/boletins/Main/BoletinsMain';
import PrimeiroAno from './Screens/Pedagogico/boletins/EnsinoFundamental1/PrimeiroAno/PrimeiroAno';
import SegundoAno from './Screens/Pedagogico/boletins/EnsinoFundamental1/SegundoAno/SegundoAno';
import TerceiroAno from './Screens/Pedagogico/boletins/EnsinoFundamental1/Terceiroano/TerceiroAno';
import QuartoAno from './Screens/Pedagogico/boletins/EnsinoFundamental1/QuartoAno/QuartoAno';
import QuintoAno from './Screens/Pedagogico/boletins/EnsinoFundamental1/QuintoAno/QuintoAno';
// Financeiro
import FinanceiroMain from './Screens/financeiro/main/FinanceiroMain';
import FinanceiroColonia from './Screens/financeiro/financeirocolonia/FinanceiroColonia';
import FinanceiroColoniaDetail from './Screens/financeiro/financeirocoloniadetail/FinanceiroColoniaDetail';
import PedagogicoMain from './Screens/Pedagogico/Main/PedagogicoMain';
import FichaColoniaDetail from './Screens/Matriculas/fichaColoniaDetail/FichaColoniaDetail';
// agenda
import AgendaMain from './Screens/Agenda/InformesAgenda/Main/AgendaMain';
import AtividadesAgenda from './Screens/Agenda/atividadesAgenda/AtividadesAgenda';
import AtividadesAgendaInfantil1 from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil1';
import AtividadesAgendaInfantil2 from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil2';
import PlanosAgenda from './Screens/Agenda/planosAgenda/PlanosAgenda';
import PlanosAgenda1ano from './Screens/Agenda/planosAgenda/series/PlanoAgenda1ano';
import PlanosAgenda2ano from './Screens/Agenda/planosAgenda/series/PlanoAgenda2ano';
import PlanosAgenda3ano from './Screens/Agenda/planosAgenda/series/PlanoAgenda3ano';
import PlanosAgenda4ano from './Screens/Agenda/planosAgenda/series/PlanoAgenda4ano';
import PlanosAgenda5ano from './Screens/Agenda/planosAgenda/series/PlanoAgenda5ano';
import PlanosAgendaInfantil1 from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil1';
import PlanosAgendaInfantil2 from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil2';
import PlanosAgendaInfantil3 from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil3';
import PlanosAgendaInfantil4 from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil4';
import PlanosAgendaInfantil5 from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil5';
import AtividadesAgendaInfantil3 from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil3';
import AtividadesAgenda1ano from './Screens/Agenda/atividadesAgenda/series/AtividadesAgenda1ano';
import AtividadesAgenda2ano from './Screens/Agenda/atividadesAgenda/series/AtividadesAgenda2ano';
import AtividadesAgenda3ano from './Screens/Agenda/atividadesAgenda/series/AtividadesAgenda3ano';
import AtividadesAgenda4ano from './Screens/Agenda/atividadesAgenda/series/AtividadesAgenda4ano';
import AtividadesAgenda5ano from './Screens/Agenda/atividadesAgenda/series/AtividadesAgenda5ano';
import PlanosAgendaInfantil5Tarde from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil5Tarde';
import PlanosAgendaInfantil4Tarde from './Screens/Agenda/planosAgenda/series/PlanoAgendaInfantil4Tarde';
import AtividadesAgendaInfantil4Manha from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil4Manhã';
import AtividadesAgendaInfantil5Tarde from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil5Tarde';
import AtividadesAgendaInfantil5Manha from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil5Manhã';
import AtividadesAgendaInfantil4Tarde from './Screens/Agenda/atividadesAgenda/series/AtividadesAgendaInfantil4Tarde';
import InformesAgenda from './Screens/Agenda/InformesAgenda/InformesAgenda';
import FichaCatequese from './Screens/Matriculas/FIchaCatequese/FichaCatequese';
import Menu from './Screens/Menu/Menu';
import FichaCatequeseDetail from './Screens/Matriculas/FIchaCatequese/FichacatequeseDetail/FichaCatequeseDetail';
import Cardapio from './Screens/Cardapio/Cardapio';

function App() {
  const userInfo = useSelector(state => state.user);
  const { loading, userInfo: user, error } = userInfo; // Atualize para 'user'
  const isAdmin = user && user.data && user.data.isAdmin ? user.data.isAdmin : false;
  const isCoAdmin = user && user.data && user.data.isCoAdmin ? user.data.isCoAdmin : false;
  const { primeiroAno, segundoAno, terceiroAno, quartoAno, quintoAno } = user && user.data && user.data.isProfessor ? user.data.isProfessor : false;
  const isProfessor = user && user.data && user.data.isProfessor ? user.data.isProfessor : false;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={user && user.data ? <UserArea /> : <HomeScreen />}
          />
          <Route path="/matriculaescola" element={<MatriculaEscolaScreen />} />

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin" element={<MenuAdmin />} />
          ) : (
            <Route
              path="/menuadmin"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}
          {isAdmin === true ? (
            <Route path="/menuadmin/adminarea" element={<AdminArea />} />
          ) : (
            <Route
              path="/menuadmin/adminarea"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}
          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/matriculadmin" element={<MatriculasAdmin />} />
          ) : (
            <Route
              path="/menuadmin/matriculadmin"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}
          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/matriculadmin/aluno/:id" element={<MatriculadoAdmin />} />
          ) : (
            <Route
              path="/menuadmin/matriculadmin/aluno/:id"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}
          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/sabadoanimado" element={<InscricoesCampanha />} />
          ) : (
            <Route
              path="/menuadmin/sabadoanimado"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}
          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/sabadoanimado/:id" element={<InscricoesCampanhaDetail />} />
          ) : (
            <Route
              path="/menuadmin/sabadoanimado/:id"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/fichasEsportes" element={<FichasEsportes />} />
          ) : (
            <Route
              path="/menuadmin/fichasEsportes"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/fichasEsportes/:id" element={<FichaEsportesDetail />} />
          ) : (
            <Route
              path="/menuadmin/fichasEsportes/:id"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/fichascolonia" element={<FichaColonia />} />
          ) : (
            <Route
              path="/menuadmin/fichascolonia"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/fichascolonia/:id" element={<FichaColoniaDetail />} />
          ) : (
            <Route
              path="/menuadmin/fichascolonia/:id"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/fichascatequese" element={<FichaCatequese />} />
          ) : (
            <Route
              path="/menuadmin/fichascatequese"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {isAdmin || isCoAdmin === true ? (
            <Route path="/menuadmin/fichascatequese/:id" element={<FichaCatequeseDetail />} />
          ) : (
            <Route
              path="/menuadmin/fichascatequese/:id"
              element={user && user.data ? <UserArea /> : <HomeScreen />}
            />
          )}

          {/* Pedagogico */}
          {isProfessor ? <Route path="/pedagogico" element={<PedagogicoMain />} /> : <Route path="/pedagogico" element={user && user.data ? <UserArea /> : <HomeScreen />} />}

          {isProfessor ? <Route path="/pedagogico/boletins" element={<BoletinsMain />} /> : <Route path="/pedagogico" element={user && user.data ? <UserArea /> : <HomeScreen />} />}

          {primeiroAno === true ? <Route path='/pedagogico/boletins/1ano' element={<PrimeiroAno />} /> : <Route path="/pedagogico/1ano" element={user && user.data ? <UserArea /> : <HomeScreen />} />}
          {segundoAno === true ? <Route path='/pedagogico/boletins/2ano' element={<SegundoAno />} /> : <Route path="/pedagogico/2ano" element={user && user.data ? <UserArea /> : <HomeScreen />} />}
          {terceiroAno === true ? <Route path='/pedagogico/boletins/3ano' element={<TerceiroAno />} /> : <Route path="/pedagogico/3ano" element={user && user.data ? <UserArea /> : <HomeScreen />} />}
          {quartoAno === true ? <Route path='/pedagogico/boletins/4ano' element={<QuartoAno />} /> : <Route path="/pedagogico/4ano" element={user && user.data ? <UserArea /> : <HomeScreen />} />}
          {quintoAno === true ? <Route path='/pedagogico/boletins/5ano' element={<QuintoAno />} /> : <Route path="/pedagogico/5ano" element={user && user.data ? <UserArea /> : <HomeScreen />} />}

          {/* financeiro */}
          <Route path='/financeiro' element={<FinanceiroMain />}></Route>
          <Route path='/financeiro/colonia' element={<FinanceiroColonia />}></Route>
          <Route path='/financeiro/colonia/:id' element={<FinanceiroColoniaDetail />}></Route>

          {/* agenda */}
          <Route path='/agenda' element={<AgendaMain />}></Route>

          <Route path='/agenda/atividades' element={<AtividadesAgenda />}></Route>
          <Route path='/agenda/atividades/infantil/1' element={<AtividadesAgendaInfantil1 />}></Route>
          <Route path='/agenda/atividades/infantil/2' element={<AtividadesAgendaInfantil2 />}></Route>
          <Route path='/agenda/atividades/infantil/3' element={<AtividadesAgendaInfantil3 />}></Route>
          <Route path='/agenda/atividades/infantil/4/manha' element={<AtividadesAgendaInfantil4Manha />}></Route>
          <Route path='/agenda/atividades/infantil/5/manha' element={<AtividadesAgendaInfantil5Manha />}></Route>
          <Route path='/agenda/atividades/infantil/4/tarde' element={<AtividadesAgendaInfantil4Tarde />}></Route>
          <Route path='/agenda/atividades/infantil/5/tarde' element={<AtividadesAgendaInfantil5Tarde />}></Route>

          <Route path='/agenda/atividades/fundamental/1' element={<AtividadesAgenda1ano />}></Route>
          <Route path='/agenda/atividades/fundamental/2' element={<AtividadesAgenda2ano />}></Route>
          <Route path='/agenda/atividades/fundamental/3' element={<AtividadesAgenda3ano />}></Route>
          <Route path='/agenda/atividades/fundamental/4' element={<AtividadesAgenda4ano />}></Route>
          <Route path='/agenda/atividades/fundamental/5' element={<AtividadesAgenda5ano />}></Route>

          <Route path='/agenda/planos' element={<PlanosAgenda />}></Route>
          <Route path='/agenda/planoAula/fundamental/1' element={<PlanosAgenda1ano />}></Route>
          <Route path='/agenda/planoAula/fundamental/2' element={<PlanosAgenda2ano />}></Route>
          <Route path='/agenda/planoAula/fundamental/3' element={<PlanosAgenda3ano />}></Route>
          <Route path='/agenda/planoAula/fundamental/4' element={<PlanosAgenda4ano />}></Route>
          <Route path='/agenda/planoAula/fundamental/4' element={<PlanosAgenda4ano />}></Route>
          <Route path='/agenda/planoAula/fundamental/5' element={<PlanosAgenda5ano />}></Route>

          <Route path='/agenda/planoAula/infantil/1' element={<PlanosAgendaInfantil1 />}></Route>
          <Route path='/agenda/planoAula/infantil/2' element={<PlanosAgendaInfantil2 />}></Route>
          <Route path='/agenda/planoAula/infantil/3' element={<PlanosAgendaInfantil3 />}></Route>
          <Route path='/agenda/planoAula/infantil/4' element={<PlanosAgendaInfantil4 />}></Route>
          <Route path='/agenda/planoAula/infantil/4/tarde' element={<PlanosAgendaInfantil4Tarde />}></Route>
          <Route path='/agenda/planoAula/infantil/5' element={<PlanosAgendaInfantil5 />}></Route>
          <Route path='/agenda/planoAula/infantil/5/tarde' element={<PlanosAgendaInfantil5Tarde />}></Route>

          {/* Informes */}
          <Route path='/agenda/informes' element={<InformesAgenda />}></Route>

          {/* Cardapio */}
          <Route path='/agenda/cardapio' element={<Cardapio/>}></Route>

          {/* althenitication */}
          <Route path="/userarea" element={<UserArea />} />
          <Route path='/menu' element={<Menu />} />
          <Route path="/login" element={<LoginEscolaScreen />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
