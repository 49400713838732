import { useParams } from "react-router";
import apiAxios from "../../../api/apiAxios";
import { useEffect, useState } from "react";
import Header from "../../../components/header";
import Modal from 'react-modal';
import "./financeirocoloniadetail.css";
import { GrAdd } from 'react-icons/gr';

const FinanceiroColoniaDetail = () => {
    const { id } = useParams();

    const [showModal, setShowModal] = useState(true);
    const [pessoasList, setPessoasList] = useState([]);
    const [availableYears, setAvailableYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const handleYearChange = (e) => {
        setSelectedYear(parseInt(e.target.value));
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value));
    };
    const [formData, setFormData] = useState({
        StatusPayment: false,
        diaria: {
            periodo: '',
            Hdia: 0,
            alimentacao: {
                almoco: 8,
                lanche: 0,
                janta: 0,
            },
            vdia: 65,
        },
        paymentType: '',
        coloniaId: id,
    });

    // Edição de valores padrão
    const getVdiaDefaultValue = (periodo) => {
        // Defina os valores padrão para vdia com base no tipo de diária
        switch (periodo) {
            case 'Dsemi':
                return 65;
            case 'Dint':
                return 92;
            case 'Dturno':
                return 45;
            default:
                return 0; // Ou qualquer valor padrão desejado
        }
    };
    const getVdiaDefaultaalmoco = (periodo) => {
        // Defina os valores padrão para vdia com base no tipo de diária
        switch (periodo) {
            case 'Dsemi':
                return 8;
            case 'Dint':
                return 8;
            case 'Dturno':
                return 0;
            default:
                return 0; // Ou qualquer valor padrão desejado
        }
    };
    // 

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'periodo') {
            setFormData((prevData) => ({
                ...prevData,
                diaria: {
                    ...prevData.diaria,
                    [name]: value,
                },
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleDiariaInputChange = (e, campo) => {
        setFormData((prevData) => ({
            ...prevData,
            diaria: {
                ...prevData.diaria,
                [campo]: e.target.value,
            },
        }));
    };

    const handleAlimentacaoInputChange = (e, campo) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            diaria: {
                ...prevData.diaria,
                alimentacao: {
                    ...prevData.diaria.alimentacao,
                    [name]: value,
                },
            },
        }));
    };

    const handlePeriodoChange = (e) => {
        // Limpa os campos da diária ao trocar o período
        setFormData((prevData) => ({
            ...prevData,
            diaria: {
                ...prevData.diaria,
                periodo: e.target.value,
                Hdia: 0,
                alimentacao: {
                    ...prevData.diaria.alimentacao,
                    almoco: getVdiaDefaultaalmoco(e.target.value),
                    lanche: 0,
                    janta: 0,
                },
                vdia: getVdiaDefaultValue(e.target.value), // Obtemos o valor padrão com base no tipo de diária
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log('Form Data Before Request:', formData);  // Adicione este log

            const response = await apiAxios.post('/api/financeirocolonia/financeiroColonia', formData);

            if (response.status === 201) {
                console.log('Entrada de financeiroColonia criada com sucesso!');
                toggleModal();
            } else {
                console.error('Erro ao criar entrada de financeiroColonia:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao criar entrada de financeiroColonia:', error.message);
        }
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // Listagem 
    useEffect(() => {
        // Busca a lista de pessoas correspondentes ao coloniaId
        const fetchPessoasList = async () => {
            try {
                const response = await apiAxios.get(`/api/financeirocolonia/financeiroColonia`);
                const anos = [];
                // Extrair anos e meses disponíveis
                const filteredData = response.data.filter((pessoa) => {
                    const dataInscricao = new Date(pessoa.dataInscricao);
                    const ano = dataInscricao.getFullYear();
                    const mes = dataInscricao.getMonth() + 1;

                    if (!anos.includes(ano)) {
                        anos.push(ano);
                    }

                    return pessoa.coloniaId === id;
                });

                setAvailableYears(anos);
                setPessoasList(filteredData);
                toggleModal(); // Abre o modal após a obtenção das informações
            } catch (error) {
                console.error('Erro ao obter lista de pessoas:', error.message);
            }
        };

        fetchPessoasList();
    }, [id]); // O efeito ocorre sempre que o id muda

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%', // Ajuste a largura conforme necessário
            height: 'auto', // Ajuste a altura conforme necessário
        },
    };

    const handleStatusPaymentToggle = async (id, newStatus) => {
        try {
            const response = await apiAxios.patch(`/api/financeirocolonia/financeiroColonia/${id}`, {
                StatusPayment: newStatus,
            });

            if (response.status === 200) {
                console.log(`Status de pagamento alterado para ${newStatus ? 'Pago' : 'Não Pago'}`);
                // Atualize a lista após a alteração
                const updatedList = pessoasList.map((pessoa) =>
                    pessoa._id === id ? { ...pessoa, StatusPayment: newStatus } : pessoa
                );
                setPessoasList(updatedList);
            } else {
                console.error('Erro ao alterar status de pagamento:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao alterar status de pagamento:', error.message);
        }
    };

    const formatarData = (dataString) => {
        const data = new Date(dataString);

        // Verificar se a data é válida antes de continuar
        if (isNaN(data.getTime())) {
            return "Data inválida";
        }

        const diasDaSemana = [
            'Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'
        ];

        const diaDaSemana = diasDaSemana[data.getUTCDay()];
        const dia = data.getUTCDate();
        const mes = data.getUTCMonth() + 1; // Lembrando que os meses começam do zero
        const ano = data.getUTCFullYear();

        return <><div style={{ font: "bold 20pt arial" }}>{dia}</div><div style={{ font: "bold 10pt arial" }}>{diaDaSemana}</div></>;
    };

    return (
        <div>
            <Header />
            <h1 className="text-center">Financeiro</h1>
            <div className='financeiro-fullscreen'>
                <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>Financeiro colónia de férias</h4>
                <div className="financeiro-colonia-container">
                    <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center", gap: '10px' }}>
                        <div>
                            <button onClick={toggleModal}><GrAdd /></button>
                        </div>
                        <div>
                            <select value={selectedYear} onChange={handleYearChange}>
                                {availableYears.map((ano) => (
                                    <option key={ano} value={ano}>
                                        {ano}
                                    </option>
                                ))}
                            </select>
                            <select value={selectedMonth} onChange={handleMonthChange}>
                                {/* Opções de meses */}
                                {Array.from({ length: 12 }, (_, i) => i + 1).map((mes) => (
                                    <option key={mes} value={mes}>
                                        {mes}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <Modal
                        isOpen={showModal}
                        onRequestClose={toggleModal}
                        contentLabel="Formulário Financeiro"
                        style={customStyles}
                    >
                        <button className="close-button-form-colonia" onClick={toggleModal}>
                            &times;
                        </button>
                        <form className="form-financeiro-colonia" onSubmit={handleSubmit}>
                            {/* Escolha o tipo de Diária */}
                            <div>
                                <label>Escolha o tipo de Diária:</label>
                                <select
                                    name='periodo'
                                    value={formData.periodo}
                                    onChange={(e) => {
                                        handlePeriodoChange(e);
                                        handleInputChange(e);
                                    }}
                                >
                                    <option value='Dsemi'>Diária Semi-Integral</option>
                                    <option value='Dint'>Diária Integral</option>
                                    <option value='Dturno'>Diária de Turno</option>
                                </select>
                                {/* Renderiza campos específicos do período escolhido */}
                                <label>Horas excedidas:</label>
                                <input
                                    type='number'
                                    name='Hdia'
                                    value={formData.diaria.Hdia}
                                    onChange={(e) => handleDiariaInputChange(e, 'Hdia')}
                                />
                                {/* Diária - Almoço */}
                                <label>Almoço:</label>
                                <input
                                    type='number'
                                    name='almoco'
                                    value={formData.diaria.alimentacao.almoco}
                                    onChange={(e) => handleAlimentacaoInputChange(e, 'almoco')}
                                />
                                {/* Diária - Lanche */}
                                <label>Lanche:</label>
                                <input
                                    type='number'
                                    name='lanche'
                                    value={formData.diaria.alimentacao.lanche}
                                    onChange={(e) => handleAlimentacaoInputChange(e, 'lanche')}
                                />
                            </div>
                            <div>
                                {/* Diária - Janta */}
                                <label>Janta:</label>
                                <input
                                    type='number'
                                    name='janta'
                                    value={formData.diaria.alimentacao.janta}
                                    onChange={(e) => handleAlimentacaoInputChange(e, 'janta')}
                                />
                                {/* No seu formulário */}
                                <label>Valor Diário:</label>
                                <input
                                    type='number'
                                    name='vdia'
                                    value={formData.diaria.vdia}
                                    onChange={(e) => handleDiariaInputChange(e, 'vdia')}
                                />
                                {/* Tipo de Pagamento */}
                                <label>Tipo de Pagamento:</label>
                                <select
                                    name='paymentType'
                                    value={formData.paymentType}
                                    onChange={handleInputChange}
                                >
                                    <option value=''>Selecione o tipo de pagamento</option>
                                    <option value='cartao'>Cartão</option>
                                    <option value='pix'>PIX</option>
                                    <option value='dinheiro'>Dinheiro</option>
                                </select>
                                <button type='submit'>Criar Entrada</button>
                            </div>
                        </form>
                    </Modal>
                    <div className="financial-table-container">
                        <table className="financial-table">
                            <thead>
                                <tr>
                                    <th>Data de Inscrição</th>
                                    <th>Tipo de Pagamento</th>
                                    <th>Tipo de Diária</th>
                                    <th>Valor</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pessoasList.map((pessoa) => {
                                    const dataInscricao = new Date(pessoa.dataInscricao);
                                    const pessoaAno = dataInscricao.getFullYear();
                                    const pessoaMes = dataInscricao.getMonth() + 1;
                                    // Verifica se a pessoa está no ano e mês selecionados
                                    if (pessoaAno === selectedYear && pessoaMes === selectedMonth) {
                                        return (
                                            <tr key={pessoa._id}>
                                                <td>{formatarData(pessoa.dataInscricao)}</td>
                                                <td>{pessoa.paymentType}</td>
                                                <td>{pessoa.diaria.periodo}</td>
                                                <td>{pessoa.diaria.vdia} BRL</td>
                                                <td
                                                    className={`entry-status ${pessoa.StatusPayment ? 'paid' : 'unpaid'}`}
                                                    onClick={() => handleStatusPaymentToggle(pessoa._id, !pessoa.StatusPayment)}
                                                >
                                                    {pessoa.StatusPayment ? 'Pago' : 'Não Pago'}
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return null; // Se não estiver no ano e mês selecionados, retorna nulo
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinanceiroColoniaDetail;
