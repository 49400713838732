import React from 'react'
import "../components-style/aboutus.css"
import img from "../assets/aboutus.jpg"
const AboutUs = () => {
  return (
    <div className='sec-about'>
      <div className='sec-item'>
        <div className='title-about'>Nossa Escola</div>
        <p>
          Aqui na nossa instituição de ensino, buscamos proporcionar uma experiência educacional de excelência, onde cada aluno é valorizado e incentivado a desenvolver todo o seu potencial.
          Nossa equipe de professores altamente qualificados está comprometida em oferecer um ambiente de aprendizagem estimulante e acolhedor, onde os estudantes são encorajados a explorar, questionar e descobrir o mundo ao seu redor.
        </p>
      </div>
      <div className='sec-item'>
        <img src={img}></img>
      </div>
    </div>
  )
}

export default AboutUs