import React, { useEffect, useState } from 'react';
import '../components-style/header.css';
import logo from "../assets/NOVA LOGO.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { PiGraduationCap } from 'react-icons/pi'
import { TfiAgenda } from 'react-icons/tfi'
import { CiViewList } from 'react-icons/ci'
import { BsFillPersonFill, BsLayoutSidebarReverse, BsFillGrid3X2GapFill } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi"
import { logout } from '../actions/userActions';
import { IoIosArrowDown } from 'react-icons/io';
import { TbPigMoney } from "react-icons/tb";
import apiAxios from '../api/apiAxios';
import { IoMdHome } from "react-icons/io";
import { CgMenuGridR } from "react-icons/cg";
import { AiFillBell } from "react-icons/ai";


import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [nav, setNav] = useState(false);
  const [userData, setUserData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState('mural');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(state => state.user);
  const { loading, userInfo: user, error } = userInfo;

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
    if (isMenuOpen === true) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
    // Fechar o menu após o logout
  };

  const modalidade = "modalidade";

  const handleToggleLinks = () => {
    const linkNames = document.querySelectorAll('.link-name');
    const linkItems = document.querySelectorAll('.link-item');
    const headerAreaUser = document.querySelectorAll('.header-area-user');

    linkNames.forEach(linkName => linkName.classList.toggle('link-name-show'));
    linkItems.forEach(linkName => linkName.classList.toggle('link-item-show'));
    headerAreaUser.forEach(linkName => linkName.classList.toggle('header-area-user-show'));
  };

  useEffect(() => {
    // Função para obter as informações do usuário pelo ID
    const fetchUserData = async () => {
      try {
        // Substitua 'userId' pelo ID do usuário logado
        const userId = user?.data?._id; // Substitua pelo seu método de obter o ID do usuário logado
        // Faça a chamada à API para obter as informações do usuário
        const response = await apiAxios.get(`/api/users/user/${userId}`);

        // Verifique se a chamada foi bem-sucedida e se há dados de usuário
        if (response.data.status === 'success' && response.data.data) {
          setUserData(response.data.data);
        }
      } catch (error) {
        console.error('Erro ao obter informações do usuário:', error);
      }
    };
    const handleResize = () => {
      // Adiciona um pequeno atraso para garantir que a largura seja atualizada após o redimensionamento
      setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 200);
    };

    fetchUserData();
    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // Chame a função para obter as informações do usuário ao montar o componente
    fetchUserData();
  }, []);

  return (
    <div>
      <header>
        {!user || !user.data ? (
          <section className='sec-header-1'>
            <Link to="/"><img className='logo' src={logo} alt="alternatetext" /></Link>
          </section>
        ) : (
          <section style={{}} className='sec-header-1'>
            <Link to="/"><img className='logo' src={logo} alt="alternatetext" /></Link>
            <div className='sec'>
              <div className='user-sec'>
                {userData && userData.profileImageUrl ? (
                  <>
                    <h6 className='header-name-perfil'>{userData.firstName}</h6>
                    <div className='header-perfil'>
                      <img
                        alt={userData.firstName}
                        src={userData.profileImageUrl}
                        onClick={() => setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen)}
                        loading="lazy"
                      />
                      <IoIosArrowDown onClick={() => setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen)} style={{ font: "bold 15pt arial", color: "black", marginLeft: "5px", marginTop: "5px" }} />
                      {isMenuOpen && (
                        <div className="user-menu">
                          <button onClick={handleLogout}>Logout</button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className='header-perfil'>
                    <BiUserCircle style={{ font: "bold 30pt arial"}} onClick={() => setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen)} />
                    <IoIosArrowDown onClick={() => setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen)} style={{ font: "bold 15pt arial", color: "black", marginLeft: "5px", marginTop: "5px" }} />
                    {isMenuOpen && (
                      <div className="user-menu">
                        <button onClick={handleLogout}>Logout</button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        {!user || !user.data ? (
          <section className='sec-header-2'>
            <div className='menu'>
              <div className='nav-link'><Link to="https://api.whatsapp.com/send?phone=5585988068633">Fale Conosco</Link></div>
              <div className='nav-link'><Link to="/Info">Agendar uma visita</Link></div>
              <div className='nav-link'><Link to="https://www.youtube.com/@colegioalternativocolaco">Conheça Mais</Link></div>
              <div className='main-button'><LinkScroll onClick={() => setNav(!nav)} Link to={modalidade} smooth duration={500}>Seja Alternativo</LinkScroll></div>
            </div>
          </section>
        ) : (
          <div style={{ background: "gray", height: "2px" }}></div>
        )}

      </header>
      <div>
        {
          !user || !user.data ? (
            console.log("Not connected")
          ) : (
            <div className='header-area-user'>
              <div className='area-user-link none' onClick={handleToggleLinks}><div className='link-item'><BsFillGrid3X2GapFill /></div></div>
              <Link activeClassName='active' className={`area-user-link ${location.pathname === '/userarea' ? 'active' : ''}`} onClick={() => setCurrentPage('mural')} to={`/userarea`}><div className='link-item'>{windowWidth >= 500 ? (<BsLayoutSidebarReverse />) : (<IoMdHome />)}</div><div className='link-name'>Mural</div></Link>
              {windowWidth >= 500 && (
                <>
                  <Link className='area-user-link' to={`/agenda`}><div className='link-item'><TfiAgenda /></div><div className='link-name'>Agenda escolar</div></Link>

                  {user && (user.data.isAdmin || user.data.isCoAdmin) && (
                    <Link className='area-user-link' to={`/financeiro`}><div className='link-item'><TbPigMoney /></div><div className='link-name'>Financeiro</div></Link>
                  )}
                  <Link className='area-user-link' to={`/pedagogico`}><div className='link-item'><PiGraduationCap /></div><div className='link-name'>Gestão das notas</div></Link>

                  {user && (user.data.isAdmin || user.data.isCoAdmin) && (
                    <Link className='area-user-link' to="/menuadmin">
                      <div className='link-item'><CiViewList /></div>
                      <div className='link-name'>AdminArea</div>
                    </Link>
                  )}
                </>
              )}

              <Link className={`area-user-link`} to={`/`}><div className='link-item'><AiFillBell /></div><div className='link-name'>Notificações</div></Link>
              <Link className={`area-user-link ${location.pathname === `/profile/${user.data._id}` ? 'active' : ''}`} onClick={handleToggleLinks} to={`/profile/${user.data._id}`}><div className='link-item'><BsFillPersonFill /></div><div className='link-name'>Perfil</div></Link>
              {windowWidth <= 500 && (
                <>
                  <Link className={`area-user-link ${location.pathname === `/menu` ? 'active' : ''}`} to={`/menu`}><div className='link-item'><CgMenuGridR /></div><div className='link-name'>Menu</div></Link>
                </>
              )}
            </div>
          )
        }
      </div>
    </div>
  );
}

export default Header;
