import React from 'react'
import Header from '../../../components/header'
import { Link } from 'react-router-dom'
import './MenuAdmin.css'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'; // Importe o componente TailSpin
import { FcGraduationCap } from "react-icons/fc";
import { FcLeave } from "react-icons/fc";
import { FcSportsMode } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FcBusinessman } from "react-icons/fc";
import { FaArrowLeft } from 'react-icons/fa'

const MenuAdmin = () => {
    const userInfo = useSelector(state => state.user);
    const { loading, userInfo: user, error } = userInfo;
    const isAdmin = user && user.data && user.data.isAdmin ? user.data.isAdmin : false;

    const items = [
        {
            key: 1,
            name: 'Usuarios',
            icon: <FcBusinessman />,
            link: '/menuadmin/adminarea',
            admin: true
        },
        {
            key: 2,
            name: 'Fichas de matrículas',
            icon: <FcGraduationCap />,
            link: '/menuadmin/matriculadmin',
            admin: false
        },
        {
            key: 3,
            name: 'Sábado Animado',
            icon: <FcBullish />,
            link: '/menuadmin/sabadoanimado',
            admin: false
        },
        {
            key: 4,
            name: 'Fichas Esportes',
            icon: <FcSportsMode />,
            link: '/menuadmin/fichasEsportes',
            admin: false
        },
        {
            key: 5,
            name: 'Fichas Colónia',
            icon: <FcLeave />,
            link: '/menuadmin/fichascolonia',
            admin: false
        },
        {
            key: 6,
            name: 'Fichas Catequese',
            icon: '✝️',
            link: '/menuadmin/fichascatequese',
            admin: false
        },
    ]

    return (
        <div>
            <Header />
            <div className='return-icon-portal'><Link to="/"><FaArrowLeft /><p>Voltar</p></Link></div>
            <div className='matriculamain-fullscreen' style={{height:"300vh"}}>
                <div className='matriculamain-acess-full' style={{height:"90%"}}>
                    <div className='matriculamain-acess'>
                        {loading ? ( // Verifique se o carregamento está em andamento
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "100%" }}>
                                <TailSpin // Use o componente TailSpin para o indicador de carregamento
                                    color="red"
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : (
                            items.map((item) => (
                                (item.admin && isAdmin) || !item.admin ? (
                                    <Link key={item.key} style={{ textDecoration: "none" }} to={item.link}>
                                        <div className='matriculamain-item'>
                                            <div className='icon-main-matriculamain-colonia'>
                                                {item.icon}
                                            </div>
                                            <div className='name-main-matriculamain-colonia'>
                                                {item.name}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuAdmin
