import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Header from '../../../components/header';
import apiAxios from '../../../api/apiAxios';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { app } from '../../../firebase';
import "../InformesAgenda/informesAgenda.css";
import { AiOutlineNotification } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { format,parseISO } from 'date-fns';


const InformesAgenda = () => {

    const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
    const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);


    const [informes, setInformes] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        date: '',
        image: null,
    });

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editFormData, setEditFormData] = useState({
        editTitle: '',
        editDescription: '',
        editDate: '',
    });

    useEffect(() => {
        const fetchInformes = async () => {
            try {
                const response = await apiAxios.get('/api/agendaInformes/informes');
                if (response.status === 200) {
                    setInformes(response.data);
                } else {
                    console.error('Error fetching informes.');
                }
            } catch (error) {
                console.error('Unexpected error:', error);
            }
        };
        fetchInformes();
    }, []);

    const reloadInformes = async () => {
        try {
            const response = await apiAxios.get('/api/agendaInformes/informes');
            if (response.status === 200) {
                setInformes(response.data);
            } else {
                console.error('Error fetching informes.');
            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: e.target.files[0],
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;

        setEditFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageUpload = async () => {
        try {
            const storage = getStorage(app);
            const storageRef = ref(storage, `images/${Date.now()}-${formData.image.name}`);
            await uploadBytes(storageRef, formData.image);
            const imageUrl = await getDownloadURL(storageRef);
            return imageUrl;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };

    const openAddModal = () => {
        setAddModalIsOpen(true);
    };

    const closeAddModal = () => {
        setAddModalIsOpen(false);
        setFormData({
            title: '',
            description: '',
            date: '',
            image: null,
        });
    };

    const openDetailsModal = () => {
        setDetailsModalIsOpen(true);
    };

    const closeDetailsModal = () => {
        setDetailsModalIsOpen(false);
        //setSelectedItem(null);
    };

    const handleItemClick = (item) => {
        setSelectedItem(item);
        openDetailsModal();
    };

    const openEditModal = () => {
        setEditModalIsOpen(true);

        // Preencha os campos de edição com os dados do item selecionado
        setEditFormData({
            editTitle: selectedItem.title,
            editDescription: selectedItem.description,
            editDate: selectedItem.date,
        });
        closeDetailsModal();
    };

    const closeEditModal = () => {
        setEditModalIsOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const { title, description, date, image } = formData;
        
        // Extrair ano, mês e dia da data fornecida
        const [year, month, day] = date.split('-');
        
        // Construir a data com os valores extraídos
        let formattedDate = new Date(year, month - 1, day);
    
        // Definir a hora atual para a data selecionada
        const currentDate = new Date();
        formattedDate.setHours(currentDate.getHours());
        formattedDate.setMinutes(currentDate.getMinutes());
        formattedDate.setSeconds(currentDate.getSeconds());
        
        // Formatar a data para o formato desejado
        formattedDate = format(formattedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'America/Sao_Paulo' });
        
        try {
            const imageUrl = await handleImageUpload();
            
            const response = await apiAxios.post('/api/agendaInformes/informes', {
                title,
                description,
                date: formattedDate,
                image: {
                    url: imageUrl,
                },
            });
            
            if (response.status === 201) {
                console.log('Informe enviado com sucesso!');
                closeAddModal();
                reloadInformes();
            } else {
                console.error('Erro ao enviar o informe.');
            }
        } catch (error) {
            console.error('Erro inesperado:', error);
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Check if there's a new image to upload
            let imageUrl = selectedItem.image.url;
            if (formData.image) {
                // If a new image is selected, upload it
                imageUrl = await handleImageUpload();
            }
    
            const { editTitle, editDescription, editDate } = editFormData;
    
            // Extrair ano, mês e dia da data fornecida
            const [year, month, day] = editDate.split('-');
            
            // Construir a data com os valores extraídos
            let formattedDate = new Date(year, month - 1, day);
        
            // Definir a hora atual para a data selecionada
            const currentDate = new Date();
            formattedDate.setHours(currentDate.getHours());
            formattedDate.setMinutes(currentDate.getMinutes());
            formattedDate.setSeconds(currentDate.getSeconds());
            
            // Formatar a data para o formato desejado
            formattedDate = format(formattedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'America/Sao_Paulo' });
    
            const response = await apiAxios.patch(`/api/agendaInformes/informes/${selectedItem._id}`, {
                title: editTitle,
                description: editDescription,
                date: formattedDate,
                image: {
                    url: imageUrl,
                },
                // You can include other fields as needed
            });
    
            if (response.status === 200) {
                console.log('Informe editado com sucesso!');
                closeEditModal();
                reloadInformes();
            } else {
                console.error('Erro ao editar o informe.');
            }
        } catch (error) {
            console.error('Erro inesperado:', error);
        }
    };
    
    const handleDelete = async () => {
        try {
            if (!selectedItem) {
                console.error('Nenhum item selecionado para exclusão.');
                return;
            }

            const response = await apiAxios.delete(`/api/agendaInformes/informes/${selectedItem._id}`);

            if (response.status !== 200) {
                console.error('Erro ao excluir o informe do banco de dados.');
                return;
            }

            const imageUrl = selectedItem.image.url;
            const pathStartIndex = imageUrl.indexOf('/images/');
            const imagePath = imageUrl.substring(pathStartIndex + 1);

            const storage = getStorage(app);
            const imageRef = ref(storage, imagePath);
            await deleteObject(imageRef);
            reloadInformes();
            console.log('Item e imagem excluídos com sucesso.');
        } catch (error) {
            console.error('Erro ao excluir o item e a imagem:', error);
        }
    };

    function formatarData(dataString) {
        const options = { weekday: 'long', day: 'numeric', month: 'long' };
        const data = new Date(dataString);
        return data.toLocaleDateString('pt-BR', options);
    }


    return (
        <div style={{ height: "320vh" }}>
            <Header />
            <div className='informes-agenda-fullscreen'>
                <div className='return-icon-portal' style={{ width: "100%" }}>
                    <Link to="/"><FaArrowLeft /><p>Voltar</p></Link>
                </div>
                <div className='too-bar-informes'>
                    {
                        isAdmin || isCoAdmin ? (
                            <button onClick={openAddModal}>+</button>
                        )
                            :
                            (
                                ""
                            )
                    }
                </div>
                <Modal
                    show={addModalIsOpen}
                    onHide={closeAddModal}
                    contentLabel="Add Form Modal"
                    aria-hide-app={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Adicionar Informe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit} method="post">
                            <label htmlFor="title">Título:</label>
                            <input
                                type="text"
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="description">Descrição:</label>
                            <textarea
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="date">Data:</label>
                            <input
                                type="date"
                                id="date"
                                name="date"
                                value={formData.date}
                                onChange={handleChange}
                                required
                            />

                            <label htmlFor="image">Imagem:</label>
                            <input
                                type="file"
                                id="image"
                                name="image"
                                onChange={handleChange}
                                accept="image/*"
                            />
                            <Button variant="primary" type="submit">Enviar</Button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeAddModal}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
                <div className='informes-agenda-full'>
                    {informes.map((image) => (
                        <div
                            className='informes-agenda-item'
                            key={image._id}
                            onClick={() => handleItemClick(image)}
                        >
                            <div className='informes-agenda-item-img'>
                                <img src={image.image.url} alt={image.title} />
                            </div>
                            <div className='informes-agenda-item-content'>
                                <div className='informes-agenda-item-content-icon'>
                                    <AiOutlineNotification />
                                    <p>{formatarData(image.date)}</p>
                                </div>
                                <div className='informes-agenda-item-content-infos' style={{ color: "gray" }}>
                                    <h4>{image.title}</h4>
                                    <p>
                                        {image.description &&
                                            (image.description.length > 100
                                                ? `${image.description.substring(0, 100).split(' ').slice(0, -1).join(' ')}...`
                                                : image.description)
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Modal
                    show={detailsModalIsOpen}
                    onHide={closeDetailsModal}
                    contentLabel="Details Modal"
                    aria-hide-app={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Detalhes do Informe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedItem && (
                            <div>
                                <h2>{selectedItem.title}</h2>
                                <p>{selectedItem.description}</p>
                                <img style={{ maxWidth: '100%', maxHeight: '400px', width: 'auto', height: 'auto', margin: '0 auto', display: 'block' }} src={selectedItem.image.url} alt={selectedItem.title} />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDetailsModal}>Fechar</Button>

                        {
                            isAdmin || isCoAdmin ? (
                                <>
                                    <Button variant="danger" onClick={handleDelete}>Excluir</Button>
                                    <Button variant="primary" onClick={openEditModal}>Editar Informe</Button>
                                </>
                            ) : (
                                ""
                            )
                        }
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={editModalIsOpen}
                    onHide={closeEditModal}
                    contentLabel="Edit Modal"
                    aria-hide-app={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Editar Informe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleEditSubmit} method="post">
                            <label htmlFor="editTitle">Novo Título:</label>
                            <input
                                type="text"
                                id="editTitle"
                                name="editTitle"
                                value={editFormData.editTitle}
                                onChange={handleEditChange}
                                required
                            />

                            <label htmlFor="editDescription">Nova Descrição:</label>
                            <textarea
                                id="editDescription"
                                name="editDescription"
                                value={editFormData.editDescription}
                                onChange={handleEditChange}
                                required
                            />

                            <label htmlFor="editDate">Nova Data:</label>
                            <input
                                type="date"
                                id="editDate"
                                name="editDate"
                                value={editFormData.editDate}
                                onChange={handleEditChange}
                                required
                            />
                            <Button variant="primary" type="submit">Salvar Edições</Button>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeEditModal}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default InformesAgenda;
