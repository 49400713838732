import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userReducer from './reducers/UserReducer';
import Cookies from 'js-cookie';
import MatriculaReducer from './reducers/MatriculaReducer';
import alunoDetail from './reducers/alunoDetail';

const store = configureStore({
  reducer: {
    user: userReducer,
    matricula: MatriculaReducer,
    alunos: alunoDetail,
  },
  middleware: [thunk],
});

// Carregar o estado inicial do usuário a partir do cookie
const userInfoFromCookies = Cookies.get('userInfo');
const storedImageURL = localStorage.getItem('userProfileImage');

if (userInfoFromCookies) {
  const userInfo = JSON.parse(userInfoFromCookies);
  userInfo.profileImageURL = storedImageURL;
  store.dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: userInfo });
}

export default store;
