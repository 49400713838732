const initialState = {
    aluno: null,
    loading: false,
    error: null,
};

const alunoDetail = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ALUNO_SUCCESS':
            return {
                ...state,
                aluno: action.payload, // Armazene o aluno em um array
                loading: false,
                error: null,
            };
        case 'FETCH_ALUNO_FAILURE':
            return { ...state, aluno: null, loading: false, error: action.payload };
        default:
            return state;
    }
}

export default alunoDetail