import React, { useEffect, useState } from 'react';
import Header from '../../../../components/header';
import apiAxios from '../../../../api/apiAxios';
import Modal from 'react-modal';
import { IoAdd } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import './planoagendaanual.css'
import { RiDeleteBin5Line } from 'react-icons/ri';
import { FaArrowLeft, FaRegEdit } from "react-icons/fa";
import { MdPostAdd } from "react-icons/md";
import { Link } from 'react-router-dom';

const PlanosAgenda2ano = () => {
    const [forceRender, setForceRender] = useState(false);

    const [planoAula, setPlanoAula] = useState({
        materias: {
            portugues: {
                conteudos: "",
            },
            matematica: {
                conteudos: "",
            },
            ensinoReligioso: {
                conteudos: "",
            },
            historia: {
                conteudos: "",
            },
            geografia: {
                conteudos: "",
            },
            ciencias: {
                conteudos: "",
            },
            ingles: {
                conteudos: "",
            },
            musica: {
                conteudos: "",
            },
        },
        serie: '2ano',
        mes: '',
        date: '',
    });

    const [opcoesMaterias, setOpcoesMaterias] = useState([]);

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [editingPlanoAulaId, setEditingPlanoAulaId] = useState(null);

    const [filtroAno, setFiltroAno] = useState((new Date().getFullYear() - 1).toString());
    const [anosDisponiveis, setAnosDisponiveis] = useState([]);

    const handleFiltroAnoChange = (e) => {
        setFiltroAno(e.target.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'mes') {
            setPlanoAula((prevPlanoAula) => ({
                ...prevPlanoAula,
                mes: value,
            }));
        } else {
            // Caso contrário, atualize a disciplina específica
            setPlanoAula((prevPlanoAula) => ({
                ...prevPlanoAula,
                materias: {
                    ...prevPlanoAula.materias,
                    [name]: {
                        ...prevPlanoAula.materias[name],
                        conteudos: value,
                    },
                },
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiAxios.post('/api/planoAula', planoAula);
            console.log('Plano de aula criado com sucesso:', response.data);
            closeModal();
            // Forçar uma nova renderização
            setForceRender((prev) => !prev);
        } catch (error) {
            console.error('Erro ao criar plano de aula:', error);
        }
    };

    const handleDelete = async (id) => {
        console.log('Deletando o item com o ID:', id);
        try {
            await apiAxios.delete(`/api/planoAula/${id}`);
            const updatedOpcoesMaterias = opcoesMaterias.filter(opcao => opcao._id !== id);
            setOpcoesMaterias(updatedOpcoesMaterias);
        } catch (error) {
            console.error('Erro ao deletar plano de aula:', error);
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiAxios.patch(`/api/planoAula/${editingPlanoAulaId}`, planoAula);
            console.log('Plano de aula editado com sucesso:', response.data);
            closeEditModal();
            // Forçar uma nova renderização
            setForceRender((prev) => !prev);
        } catch (error) {
            console.error('Erro ao editar plano de aula:', error);
        }
    };

    useEffect(() => {
        const obterOpcoesMaterias = async () => {
            try {
                const response = await apiAxios.get('/api/planoAula');
                const opcoes = response.data.filter(opcao =>
                    opcao.serie === planoAula.serie && (
                        (
                            new Date(opcao.date).getFullYear().toString() === filtroAno &&
                            (new Date(opcao.date).getMonth() >= 6 && new Date(opcao.date).getMonth() <= 11)
                        ) || (
                            (new Date(opcao.date).getFullYear() - 1).toString() === filtroAno &&
                            (new Date(opcao.date).getMonth() >= 0 && new Date(opcao.date).getMonth() <= 5)
                        )
                    )
                );
                opcoes.sort((a, b) => {
                    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
                    return meses.indexOf(a.mes) - meses.indexOf(b.mes);
                });
                setOpcoesMaterias(opcoes);
            } catch (error) {
                console.error('Erro ao obter opções de matérias:', error);
            }
        };

        obterOpcoesMaterias();

        const obterAnosDisponiveis = async () => {
            try {
                const response = await apiAxios.get('/api/planoAula');
                const anosUnicos = [...new Set(response.data.map(opcao => new Date(opcao.date).getFullYear()))];
                setAnosDisponiveis(anosUnicos);
            } catch (error) {
                console.error('Erro ao obter anos disponíveis:', error);
            }
        };

        obterAnosDisponiveis();
    }, [forceRender, planoAula.serie, filtroAno]);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openEditModal = (id) => {
        const planoAulaParaEditar = opcoesMaterias.find(opcao => opcao._id === id);
        setPlanoAula(planoAulaParaEditar);
        setEditingPlanoAulaId(id);
        setEditModalIsOpen(true);
    };

    const closeEditModal = () => {
        setPlanoAula({
            materias: {
                portugues: {
                    conteudos: "",
                },
                matematica: {
                    conteudos: "",
                },
                ensinoReligioso: {
                    conteudos: "",
                },
                historia: {
                    conteudos: "",
                },
                geografia: {
                    conteudos: "",
                },
                ciencias: {
                    conteudos: "",
                },
                ingles: {
                    conteudos: "",
                },
                musica: {
                    conteudos: "",
                },
            },
            serie: '2ano',
            mes: '',
        });
        setEditingPlanoAulaId(null);
        setEditModalIsOpen(false);
    };

    return (
        <div>
            <Header />
            <div className='return-icon-portal'><Link to="/agenda/planos"><FaArrowLeft /><p>Voltar</p></Link></div>
            <div className='agendamain-fullscreen'>
                <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>PLANO DE AULA ANUAL</h4>
                <div className='agendamain-acess-full' style={{ height: "294vh", flexDirection: "column", alignItems: "center", justifyContent: "start", gap: "10px", padding: "5px", marginTop: "5px" }}>
                    <div style={{ width: "95%" }}>
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            contentLabel="Example Modal"
                        >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button style={{ border: "0", background: "white", color: "gray", font: "bold 20pt arial" }} onClick={closeModal}><IoMdClose /></button>
                            </div>

                            <form className='agenda-form-series' onSubmit={handleSubmit}>
                                <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>LANÇAR PLANO ANUAL</h4>
                                <label htmlFor="mes">Mês:</label>
                                <select
                                    id="mes"
                                    name="mes"
                                    value={planoAula.mes}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="" disabled>
                                        Selecione o mês
                                    </option>
                                    <option value="Janeiro">Janeiro</option>
                                    <option value="Fevereiro">Fevereiro</option>
                                    <option value="Março">Março</option>
                                    <option value="Abril">Abril</option>
                                    <option value="Maio">Maio</option>
                                    <option value="Junho">Junho</option>
                                    <option value="Julho">Julho</option>
                                    <option value="Agosto">Agosto</option>
                                    <option value="Setembro">Setembro</option>
                                    <option value="Outubro">Outubro</option>
                                    <option value="Novembro">Novembro</option>
                                    <option value="Dezembro">Dezembro</option>
                                </select>
                                {/* Iteração sobre as disciplinas */}
                                {Object.entries(planoAula.materias).map(([disciplina, dados]) => (
                                    <div key={disciplina}>
                                        <label htmlFor={`conteudos-${disciplina}`}>{`Conteúdos ${disciplina}:`}</label>
                                        <textarea
                                            id={`conteudos-${disciplina}`}
                                            name={disciplina}
                                            value={dados.conteudos || ''}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                ))}
                                <button type="submit">Criar Plano de Aula</button>
                            </form>
                        </Modal>
                        <div className='agenda-too-bar'>
                            <button onClick={openModal}><MdPostAdd style={{ font: "bold 14pt arial" }} /></button>
                            <select
                                id="filtroAno"
                                name="filtroAno"
                                value={filtroAno}
                                onChange={handleFiltroAnoChange}
                            >
                                {anosDisponiveis.map((ano) => (
                                    <option key={ano} value={ano}>
                                        {ano + 1}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='agenda-plano-aula-anual-full'>
                        <div className='agenda-plano-aula-anual-grid'>
                            {opcoesMaterias.map((opcao) => (
                                <div className='agenda-plano-aula-anual' key={opcao._id}>
                                    <h2>
                                        <div className='agenda-plano-aula-anual-options-month'>{opcao.mes}</div>
                                        <div className='agenda-plano-aula-anual-options-edit'>
                                            <div onClick={() => handleDelete(opcao._id)}><RiDeleteBin5Line /></div>
                                            <div onClick={() => openEditModal(opcao._id)}><FaRegEdit /></div>
                                        </div>
                                    </h2>                                    <p>{opcao.materias.portugues.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.portugues.conteudos}</p>
                                    <p>{opcao.materias.matematica.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.matematica.conteudos}</p>
                                    <p>{opcao.materias.ensinoReligioso.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.ensinoReligioso.conteudos}</p>
                                    <p>{opcao.materias.historia.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.historia.conteudos}</p>
                                    <p>{opcao.materias.matematica.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.matematica.conteudos}</p>
                                    <p>{opcao.materias.geografia.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.geografia.conteudos}</p>
                                    <p>{opcao.materias.ciencias.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.ciencias.conteudos}</p>
                                    <p>{opcao.materias.ingles.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.ingles.conteudos}</p>
                                    <p>{opcao.materias.musica.materia}</p>
                                    <p style={{ background: "white", font: "bold 10pt arial" }}>{opcao.materias.musica.conteudos}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={editModalIsOpen}
                    onRequestClose={closeEditModal}
                    contentLabel="Edit Modal"
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={{ border: "0", background: "white", color: "gray", font: "bold 20pt arial" }} onClick={closeEditModal}><IoMdClose /></button>
                    </div>
                    <form className='agenda-form-series' onSubmit={handleEditSubmit}>
                        <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>EDITAR PLANO DE AULA</h4>
                        <label htmlFor="mes">Mês:</label>
                        <select
                            id="mes"
                            name="mes"
                            value={planoAula.mes}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="" disabled>
                                Selecione o mês
                            </option>
                            <option value="Janeiro">Janeiro</option>
                            <option value="Fevereiro">Fevereiro</option>
                            <option value="Março">Março</option>
                            <option value="Abril">Abril</option>
                            <option value="Maio">Maio</option>
                            <option value="Junho">Junho</option>
                            <option value="Julho">Julho</option>
                            <option value="Agosto">Agosto</option>
                            <option value="Setembro">Setembro</option>
                            <option value="Outubro">Outubro</option>
                            <option value="Novembro">Novembro</option>
                            <option value="Dezembro">Dezembro</option>
                        </select>
                        {/* Iteração sobre as disciplinas */}
                        {Object.entries(planoAula.materias).map(([disciplina, dados]) => (
                            <div key={disciplina}>
                                <label htmlFor={`conteudos-${disciplina}`}>{`Conteúdos ${disciplina}:`}</label>
                                <textarea
                                    id={`conteudos-${disciplina}`}
                                    name={disciplina}
                                    value={dados.conteudos || ''}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                        ))}
                        <button type="submit">Salvar Edição</button>
                    </form>
                </Modal>
            </div>
        </div>
    );
};

export default PlanosAgenda2ano;
