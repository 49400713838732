import React from 'react'
import Header from '../components/header'

const NotFound = () => {
  return (
    <div>
      <Header />
      NotFound
    </div>
  )
}

export default NotFound