// In the matriculaActions.js file
// matriculaActions.js
import Cookies from 'js-cookie';
import apiAxios from '../api/apiAxios';

// export const matricularAluno = (matriculaData) => async (dispatch) => {
//     try {
//         const { data } = await apiAxios.post('/api/matriculas/matricular', matriculaData); // Pass matriculaData directly

//         const userInfo = JSON.stringify(data);

//         Cookies.set('userInfo', userInfo, { path: '/' });

//         dispatch({
//             type: 'MATRICULA_SUCCESS', // Corrected action type
//             payload: data,
//         });
//     } catch (error) {
//         dispatch({
//             type: 'MATRICULA_ERROR', // Corrected action type
//             payload: error.message, // Error message from the server or a default error message
//         });
//     }
// };

export const matricularAluno = (matriculaData) => async (dispatch) => {
    try {
        const { data } = await apiAxios.post('/api/matriculas/matricular', matriculaData);
        dispatch({
            type: 'MATRICULA_SUCCESS',
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: 'MATRICULA_ERROR',
            payload: error.message,
        });
    }
};

export const listMatriculas = () => async (dispatch) => {
    try {
        dispatch({ type: 'MATRICULA_REQUEST' });

        const { data } = await apiAxios.get('/api/matriculas/listar-matriculas');
        dispatch({ type: 'MATRICULA_SUCCESS', payload: data });
    } catch (error) {
        dispatch({ type: 'MATRICULA_ERROR', payload: error.message });
    }
};

export const fetchAlunoById = (id) => async (dispatch) => {
    try {
        const { data } = await apiAxios.get(`/api/matriculas/${id}`);
        dispatch({ type: 'FETCH_ALUNO_SUCCESS', payload: data });
    } catch (error) {
        dispatch({ type: 'FETCH_ALUNO_FAILURE', payload: error.response.data.error });
    }
};

export const updateMatricula = (matriculaId, updatedData) => async (dispatch) => {
    try {
        dispatch({ type: 'MATRICULA_UPDATE_REQUEST' });

        const { data } = await apiAxios.put(`/api/matriculas/atualizar/${matriculaId}`, updatedData);

        // Se a atualização for bem-sucedida, você pode despachar uma ação para atualizar o estado Redux com os novos dados.
        dispatch({ type: 'MATRICULA_UPDATE_SUCCESS', payload: data });
    } catch (error) {
        dispatch({ type: 'MATRICULA_UPDATE_ERROR', payload: error.message });
    }
};

export const deleteMatricula = (matriculaId) => async (dispatch) => {
    try {
        dispatch({ type: 'MATRICULA_DELETE_REQUEST' });

        // Faça a chamada à API para deletar a matrícula com base no ID fornecido
        await apiAxios.delete(`/api/matriculas/deletar/${matriculaId}`);

        // Se a deleção for bem-sucedida, você pode despachar uma ação para atualizar o estado Redux ou realizar outras ações necessárias.
        dispatch({ type: 'MATRICULA_DELETE_SUCCESS', payload: matriculaId }); // Você pode enviar o ID da matrícula deletada se quiser atualizar o estado Redux com esse ID.
        dispatch(listMatriculas())
    } catch (error) {
        dispatch({ type: 'MATRICULA_DELETE_ERROR', payload: error.message });
    }
};