import React from 'react'
import Header from '../../components/header'
import { CiApple, CiBoxList, CiBullhorn, CiEdit, CiViewList, CiViewTable } from 'react-icons/ci'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './Menu.css'
import logo2 from '../../assets/Logo Alternativo.png'

const Menu = () => {
    const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
    const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);
    const isprof = useSelector(state => state.user?.userInfo?.data?.isProfessor?.isProfessor);
    const canAccessPlanoAnual = isCoAdmin || isAdmin || isprof;
    const canAccessFichas = isCoAdmin || isAdmin ;

    const items = [
        {
            key: 1,
            name: 'Atividades e planos',
            icon: <CiEdit />,
            link: '/agenda/atividades',
        },
        {
            key: 2,
            name: 'Cardápio',
            icon: <CiApple />,
            link: '/',
        },
        {
            key: 3,
            name: 'Informes',
            icon: <CiBullhorn />,
            link: '/agenda/informes',
        },
        {
            key: 4,
            name: 'Plano Anual',
            icon: <CiBoxList />,
            link: '/agenda/planos',
        },
        {
            key: 5,
            name: 'Fichas',
            icon: <CiViewList />,
            link: '/menuadmin',
        },
        {
            key: 6,
            name: 'Boletins',
            icon: <CiViewTable />,
            link: '/pedagogico/boletins',
        },
    ]

    return (
        <div>
            <Header />
            <div className='all-banner-userarea' >
                <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <div className='menu-element-title-style-top-area'>
                        <img src={logo2} alt="logo2" />
                        <h1>Portal <gr>Alternativo</gr></h1>
                    </div>
                    <div className='menu-items-bottom-area'>
                        <div className='items-usearea-list'>
                            {items.map((item) => (
                                (item.name !== 'Plano Anual' || canAccessPlanoAnual) && (
                                    (item.name !== 'Fichas' || canAccessFichas) && (
                                        <Link to={item.link} style={{ textDecoration: "none" }}>
                                            <div className='items-usearea-1' key={item.key}>
                                                <div className='items-usearea-1-icon'>{item.icon}</div>
                                                <div className='items-usearea-1-title'>
                                                    <p>{item.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                )
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Menu