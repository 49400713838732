import React from 'react'
import Header from '../../../components/header'
import { Link } from 'react-router-dom'
import './PedagogicoMain.css'
import { useSelector } from 'react-redux'
import { TailSpin } from 'react-loader-spinner'; // Importe o componente TailSpin
import { FcDiploma2 } from "react-icons/fc";

const PedagogicoMain = () => {
    const userInfo = useSelector(state => state.user);
    const { loading, userInfo: user, error } = userInfo;
    const isAdmin = user && user.data && user.data.isAdmin ? user.data.isAdmin : false;

    const items = [
        {
            key: 1,
            name: 'Boletins',
            icon: <FcDiploma2 />,
            link: '/pedagogico/boletins',
        },
    ]

    return (
        <div>
            <Header />
            <h1>Pedagógico</h1>
            <div className='pedagogicomain-fullscreen' style={{height:"300vh"}}>
                <div className='pedagogicomain-acess-full' style={{height:"90%"}}>
                    <div className='pedagogicomain-acess'>
                        {loading ? ( // Verifique se o carregamento está em andamento
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "100%" }}>
                                <TailSpin // Use o componente TailSpin para o indicador de carregamento
                                    color="red"
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : (
                            items.map((item) => (
                                <Link key={item.key} style={{ textDecoration: "none" }} to={item.link}>
                                    <div className='pedagogicomain-item'>
                                        <div className='icon-main-pedagogicomain-colonia'>
                                            {item.icon}
                                        </div>
                                        <div className='name-main-pedagogicomain-colonia'>
                                            {item.name}
                                        </div>
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PedagogicoMain
