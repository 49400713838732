import Cookies from 'js-cookie';
import apiAxios from '../api/apiAxios';

export const signin = (login, password) => async (dispatch) => {
  dispatch({ type: 'USER_SIGNIN_REQUEST', payload: { login, password } });
  try {
    const { data } = await apiAxios.post('/api/users/signin', { login, password });
    // Armazenar o userInfo como um cookie
    const userInfo = JSON.stringify(data);
    Cookies.set('userInfo', userInfo, { path: '/' });

    dispatch({ type: 'USER_SIGNIN_SUCCESS', payload: data });
  } catch (error) {
    if (error) {
      const status = error.response.status;
      if (status === 401) {
        dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Credenciais inválidas. Por favor, insira um usuário ou senha válido.' } });
        console.log(error)
      } else if (status === 500) {
        dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Houve um erro interno no servidor. Tente novamente mais tarde.' } });
      } else {
        dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
      }
    } else {
      dispatch({ type: 'USER_SIGNIN_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
    }
  }
};

export const register = (formData) => async (dispatch) => {
  dispatch({ type: 'USER_REGISTER_REQUEST', payload: formData });
  try {
    const { data } = await apiAxios.post('/api/users/register', formData);
    const email = formData.get("email");
    const password = formData.get("password");
    dispatch(signin(email, password));
    dispatch({ type: 'USER_REGISTER_SUCCESS', payload: data });
    // Aqui, chame a função signin() e passe as credenciais para o login automático

  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      if (status === 400) {
        // Possíveis mensagens de erro para status 400 (Bad Request)
        const errorMessage = error.response.data.error || '*Erro de requisição inválido';
        dispatch({ type: 'USER_REGISTER_FAILURE', payload: { general: errorMessage } });
      } else if (status === 500) {
        dispatch({ type: 'USER_REGISTER_FAILURE', payload: { general: '*Houve um erro interno no servidor. Tente novamente mais tarde.' } });
      } else {
        dispatch({ type: 'USER_REGISTER_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
      }
    } else {
      dispatch({ type: 'USER_REGISTER_FAILURE', payload: { general: '*Ocorreu um erro. Tente novamente mais tarde.' } });
    }
  }
};

function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const logout = () => (dispatch) => {
  // Limpar o cookie do userInfo
  Cookies.remove('userInfo');

  // Despachar a ação de logout
  dispatch({ type: 'USER_LOGOUT' });
};

export const listUsers = () => async (dispatch) => {
  dispatch({ type: 'USER_LIST_REQUEST' });

  try {
    const { data } = await apiAxios.get('/api/users/list');

    dispatch({ type: 'USER_LIST_SUCCESS', payload: data });
  } catch (error) {
    dispatch({ type: 'USER_LIST_FAILURE', payload: error.message });
  }
};


export const deleteUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: 'USER_DELETE_REQUEST', payload: userId });

  try {
    await apiAxios.delete(`/api/users/delete/${userId}`);

    dispatch({ type: 'USER_DELETE_SUCCESS', payload: userId });

    // Atualizar a lista de usuários após a exclusão
    dispatch(listUsers());
  } catch (error) {
    dispatch({
      type: 'USER_DELETE_FAILURE',
      payload: { userId, error: error.message },
    });
  }
};