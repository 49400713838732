import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';


const AtividadesAgendaInfantil1 = () => {
    return (
        <AtividadeComponente materiaSelect="Infantil I" anoName="Infantil I" />
    );
};

export default AtividadesAgendaInfantil1;
