import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';


const AtividadesAgenda5ano = () => {
    return (
        <AtividadeComponente materiaSelect="5ano" anoName="5°ano" />
    );
};

export default AtividadesAgenda5ano;
