// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZ3EboLyekOK6c0bnW-0Pl8AYfVPim1pw",
  authDomain: "portal-alternativo-2def1.firebaseapp.com",
  projectId: "portal-alternativo-2def1",
  storageBucket: "portal-alternativo-2def1.appspot.com",
  messagingSenderId: "171253914438",
  appId: "1:171253914438:web:7108a376fc154e671ca347",
  measurementId: "G-5KHC5BJZYJ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);