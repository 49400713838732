import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';

const AtividadesAgendaInfantil5Tarde = () => {
    return (
        <AtividadeComponente materiaSelect="Infantil V Tarde" anoName="Infantil V Tarde" />
    );
};

export default AtividadesAgendaInfantil5Tarde;
