import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';

const AtividadesAgendaInfantil4Tarde = () => {
    return (
        <AtividadeComponente materiaSelect="Infantil IV Tarde" anoName="Infantil IV Tarde" />
    );
};

export default AtividadesAgendaInfantil4Tarde;
