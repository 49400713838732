import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';

const AtividadesAgendaInfantil5Manha = () => {

    return (
        <AtividadeComponente materiaSelect="Infantil V Manhã" anoName="Infantil V Manhã" />
    );
};

export default AtividadesAgendaInfantil5Manha;
