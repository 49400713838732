import React from 'react'
import "../components-style/modalidadesmatriculas.css"
import { Link } from 'react-router-dom'
import imgCall from '../assets/bolha-de-bate-papo.png'
import imgMatricula from '../assets/matricular.png'
import imgOnline from '../assets/curso-online.png'
const modalidadesmatriculas = () => {
  return (
    <div className='modalidade-container' name="modalidade">
      <div className='modalidade-item'>
        <Link to="https://api.whatsapp.com/send?phone=5585988068633">
          <img src={imgCall} ></img>
          <p>Fale Conosco</p>
        </Link>
      </div>
      <div className='modalidade-item'>
        <Link to="https://portal-alternativocolaco.com.br/">
          <img src={imgMatricula}></img>
          <p>Matricule-se Online</p>
        </Link>
      </div>
      <div className='modalidade-item'>
        <Link to="/login">
          <img src={imgOnline}></img>
          <p>Portal Online</p>
        </Link>
      </div>
    </div>
  )
}

export default modalidadesmatriculas