import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';

const AtividadesAgendaInfantil4Manha = () => {
    return (
        <AtividadeComponente materiaSelect="Infantil IV Manhã" anoName="Infantil IV Manhã" />
    );
};

export default AtividadesAgendaInfantil4Manha;
