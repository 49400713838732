import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const GraficoDesempenho = ({ notas, onAlunoChange, alunoSelecionado }) => {
    const svgRef = useRef();

    useEffect(() => {
        const disciplinas = Object.keys(notas['B1']);
        console.log('Disciplinas:', disciplinas);
        const margin = { top: 20, right: 30, bottom: 80, left: 180 };
        const width = 750 - margin.left - margin.right;
        const height = 300 - margin.top - margin.bottom;

        const xScale = d3.scaleBand().domain(['B1', 'B2', 'B3', 'B4']).range([0, width]).padding(0.1);
        const yScale = d3.scaleLinear().domain([0, 10]).range([height, 0]);

        const svg = d3
            .select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        disciplinas.forEach((disciplina, index) => {
            console.log(`Disciplina ${index + 1}:`, disciplina);
            const data = ['B1', 'B2', 'B3', 'B4'].map((bimestre) => notas[bimestre]?.[disciplina]);

            const color = generateRandomColor();
            const rectWidth = 15;
            const rectHeight = 15;
            const yPosition = index * (rectHeight + 15);

            svg
                .append('rect')
                .attr('x', -margin.left)
                .attr('y', yPosition)
                .attr('width', rectWidth)
                .attr('height', rectHeight)
                .attr('fill', color);

            svg
                .append('text')
                .attr('x', -margin.left + rectWidth + 10)
                .attr('y', yPosition + rectHeight / 2)
                .text(disciplina)
                .attr('fill', color)
                .attr('alignment-baseline', 'middle');

            svg
                .append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke', color)
                .attr('stroke-width', 1.5)
                .attr(
                    'd',
                    d3
                        .line()
                        .x((_, i) => xScale(['B1', 'B2', 'B3', 'B4'][i]) + xScale.bandwidth() / 2)
                        .y((d) => yScale(d))
                );
        });

        svg
            .append('g')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale))
            .selectAll('text')
            .attr('transform', 'rotate(-45)')
            .style('text-anchor', 'end');

        svg.append('g').call(d3.axisLeft(yScale));

        // Chame onAlunoChange após criar o gráfico
        if (onAlunoChange && typeof onAlunoChange === 'function') {
            onAlunoChange(alunoSelecionado);
            console.log('Função onAlunoChange chamada com sucesso.');
        }

        console.log('Data for chart:', notas);
    }, [notas, onAlunoChange, alunoSelecionado]);

    return (
        <div style={{ width: '80%', height: '70%' }} className='grafico-desempenho'>
            <svg ref={svgRef} />
        </div>
    );
};

export default GraficoDesempenho;

