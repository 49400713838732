import React from 'react';
import { FaWhatsapp, FaInstagram, FaTiktok, FaFacebook, FaLinkedin } from 'react-icons/fa';
import "../components-style/footer.css";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-icons'>
        <a href='https://api.whatsapp.com/send?phone=5585988068633' className='icon-link'><FaWhatsapp className='icon' /></a>
        <a href='https://www.instagram.com/colegioalternativocolaco' className='icon-link'><FaInstagram className='icon' /></a>
        <a href='https://www.tiktok.com/@colegioalternativocolaco?_t=8XxozM2TnMr&_r=1' className='icon-link'><FaTiktok className='icon' /></a>
        <a href='https://www.facebook.com/colegioalternativocolaco' className='icon-link'><FaFacebook className='icon' /></a>
        <a href='https://www.linkedin.com/company/colegioalternativocolaco/mycompany/' className='icon-link'><FaLinkedin className='icon' /></a>
      </div>
      <p className='footer-text'>
        Alternativo - Escola de Ensino Infantil e Fundamental I
        <br />
        Endereço: Rua Viriato Ribeiro, 724 - Bela Vista
        <br />
        Telefones: 
        <br />
        Secretaria - (85) 9 8898-78-99
        <br />
        Direção - (85) 9 8806-86-33
        <br />
        Coordenação - (85) 9 8957-9305 
      </p>
    </div>
  )
}

export default Footer;

