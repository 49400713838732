import React from 'react'
import Header from '../components/header';
import Banner from '../components/banner';
import AboutUs from '../components/AboutUs';
import Footer from '../components/footer';
import Manual from '../components/Manual';
import Perguntas from '../components/perguntas';
import Ensino from '../components/Ensino';
const HomeScreen = () => {
    return (
        <div>
            <Header />
            <Banner />
            <AboutUs />
            <Ensino/>
            <Perguntas/>
            <Manual/>
            <Footer/> 
        </div>
    )
}

export default HomeScreen