import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';

const AtividadesAgenda1ano = () => {
    return (
        <>
            <AtividadeComponente materiaSelect={"1ano"} anoName={"1°ano"}/>
        </>
    );
};

export default AtividadesAgenda1ano;
