import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';


const AtividadesAgenda4ano = () => {

    return (
        <AtividadeComponente materiaSelect="4ano" anoName="4°ano" />

    );
};

export default AtividadesAgenda4ano;
