import React, { useState, useEffect } from 'react';
import Header from '../../../components/header';
import { Link } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import { MdFamilyRestroom } from 'react-icons/md';
import { TailSpin } from 'react-loader-spinner';

const FinanceiroColonia = () => {
    const [matriculas, setMatriculas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [campanha, setCampanha] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCampanha, setFilteredCampanha] = useState([]);

    useEffect(() => {
        async function fetchCampanha() {
            try {
                const response = await apiAxios.get('/api/matriculaColonia/inscricoes');
                setCampanha(response.data);
                setFilteredCampanha(response.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }

        fetchCampanha();
    }, []);

    return (
        <div>
            <Header />
            <h1 className="text-center">Financeiro</h1>
            <div className='full-matriculasadmin'>
                <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>Financeiro colónia de férias</h4>
                {loading ? (
                    // Mostra o componente de loading enquanto os dados estão sendo carregados
                    <TailSpin type="spin" color="red" height={50} width={50} />
                ) : (
                    <>
                        {filteredCampanha && filteredCampanha.length > 0 ? (
                            <div className='matriculasadmin-elements'>
                                <p style={{ color: "black", font: "bold 13pt arial" }}>Alunos na colônia de férias: {filteredCampanha.length}</p>
                                <div>
                                    {/* Adicione um campo de pesquisa */}
                                    <input
                                        type="text"
                                        placeholder="Pesquisar por nome"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                {filteredCampanha.map((inscricao) => (
                                    <div className='matriculasadmin-element'>
                                        <Link to={`/financeiro/colonia/${inscricao._id}`} key={inscricao._id}>
                                            <div className='matriculasadmin-sec-1'>
                                                <MdFamilyRestroom />
                                            </div>
                                            <div className='matriculasadmin-sec-2'>
                                                <p>Criança: {inscricao.nome}</p>
                                                <p>Responsável: {inscricao.nomeResponsavel}</p>
                                                <p>Colégio que veio: {inscricao.colegio}</p>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Nenhuma inscrição encontrada.</p>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default FinanceiroColonia;
