import React, { useEffect, useState } from 'react';
import apiAxios from '../../../../../api/apiAxios';
import Header from '../../../../../components/header';
import { toast } from 'react-toastify';
import { format, parseISO, setHours, setMinutes, setSeconds, isSameMonth, isSameDay } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import './AtividadeAgendaSerie.css';
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/io';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import emptySearchImage from '../../../../../assets/search-empty.png';
import { MdDelete } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { app } from '../../../../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";


const resetForm = (setAtividade, materiaSelect) => {
    setAtividade({
        atividadeSerie: materiaSelect,
        agendastatus: false,
        acolhida: '',
        materias: {
            portugues: {
                isSelect: false,
                materia: 'Português',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            matematica: {
                isSelect: false,
                materia: 'Matemática',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            ensinoReligioso: {
                isSelect: false,
                materia: 'Ensino Religioso',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            historia: {
                isSelect: false,
                materia: 'História',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            geografia: {
                isSelect: false,
                materia: 'Geografia',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            ciencias: {
                isSelect: false,
                materia: 'Ciências',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            ingles: {
                isSelect: false,
                materia: 'Inglês',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            musica: {
                isSelect: false,
                materia: 'Música',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            Linguagem: {
                isSelect: false,
                materia: 'Linguagem',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            sociedade: {
                isSelect: false,
                materia: 'Sociedade',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            natureza: {
                isSelect: false,
                materia: 'Natureza',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            Artes: {
                isSelect: false,
                materia: 'Artes',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
        },
    });
};

const updateAtividadesList = async (
    isAdminOrCoAdmin,
    materiaSelect,
    setAtividadesList,
    setLoading
) => {
    try {
        // Lógica para buscar as atividades atualizadas do servidor
        const response = await apiAxios.get('/api/agendaOnline/agenda');
        let filteredAtividades;

        if (isAdminOrCoAdmin) {
            filteredAtividades = response.data.filter(
                (atividade) => atividade.atividadeSerie === materiaSelect
            );
        } else {
            // Para outros usuários, lista apenas as atividades com agendastatus true
            filteredAtividades = response.data.filter(
                (atividade) => atividade.atividadeSerie === materiaSelect && atividade.agendastatus === true
            );
        }

        // Atualize o estado local atividadesList com as atividades obtidas
        setAtividadesList(filteredAtividades.reverse());
        setLoading(false);
    } catch (error) {
        console.error('Erro ao buscar atividades atualizadas:', error);
        setLoading(false);
    }
};

const AtividadeComponente = ({ materiaSelect, anoName }) => {
    const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
    const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);

    const isprof1ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.primeiroAno);
    const isprof2ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.segundoAno);
    const isprof3ano = useSelector(state => state.user.userInfo?.data?.isProfessor?.terceiroAno);
    const isprof4ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.quartoAno);
    const isprof5ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.quintoAno);

    const isprofInfantilI = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilUm);
    const isprofInfantilII = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilDois);
    const isprofInfantilIII = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilTres);
    const isprofInfantilIV = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilQuatro);
    const isprofInfantilV = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilCinco);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const [loading, setLoading] = useState(true);
    const [atividade, setAtividade] = useState({
        atividadeSerie: materiaSelect,
        agendastatus: false,
        acolhida: '',
        pdfFile: {
            pdfUrl: ""
        },
        materias: {
            portugues: {
                isSelect: false,
                materia: 'Português',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            matematica: {
                isSelect: false,
                materia: 'Matemática',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            ensinoReligioso: {
                isSelect: false,
                materia: 'Ensino Religioso',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            historia: {
                isSelect: false,
                materia: 'História',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            geografia: {
                isSelect: false,
                materia: 'Geografia',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            ciencias: {
                isSelect: false,
                materia: 'Ciências',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            ingles: {
                isSelect: false,
                materia: 'Inglês',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            musica: {
                isSelect: false,
                materia: 'Música',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            Linguagem: {
                isSelect: false,
                materia: 'Linguagem',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            sociedade: {
                isSelect: false,
                materia: 'Sociedade',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            natureza: {
                isSelect: false,
                materia: 'Natureza',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
            Artes: {
                isSelect: false,
                materia: 'Artes',
                objetivoConhecimento: '',
                habilidades: '',
                metodologia: '',
                atividadeMaterial: '',
                links: '',
                atividadeMaterialCasa: '',
                linksCasa: '',
            },
        },
    });

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [modalIsOpen2, setModalIsOpen2] = useState(false);
    const [selectedAtividade, setSelectedAtividade] = useState(null);

    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [editAtividade, setEditAtividade] = useState(null);
    const [selectedAtividadeId, setSelectedAtividadeId] = useState(null);
    const [envioClicado, setEnvioClicado] = useState(false);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAtividade((prevAtividade) => ({
            ...prevAtividade,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const selectedMaterias = Object.values(atividade.materias).filter((materia) => materia.isSelect);

        if (selectedMaterias.length === 0) {
            // Se nenhum estiver selecionado, exiba uma mensagem ou tome a ação apropriada
            console.error('Selecione pelo menos uma matéria antes de enviar.');
            return;
        }

        if (envioClicado) {
            return; // Se o envio já foi clicado antes, saia da função
        }

        try {
            setEnvioClicado(true);
            let atividadeToSend = { ...atividade }; // Copie a atividade para evitar mutação direta

            if (atividade.pdfFile) {
                const storage = getStorage(app);
                const storageRef = ref(storage, `PDF/${atividade.pdfFile.name}`);

                // Use uploadBytes para enviar o arquivo para o Firebase
                await uploadBytes(storageRef, atividade.pdfFile);

                const pdfUrl = await getDownloadURL(storageRef);

                // Adicione a URL do PDF à cópia da atividade que será enviada para a API
                atividadeToSend.pdfFile = {
                    ...atividade.pdfFile,
                    pdfUrl: pdfUrl,
                };

                console.log('Arquivo PDF enviado com sucesso:', pdfUrl);
            }

            // Obtenha a data do estado e defina o horário para o final do dia
            const dateWithEndTime = setHours(setMinutes(setSeconds(parseISO(atividade.date), 59), 23), 23);

            // Formate a data antes de enviá-la para o servidor
            const formattedDate = format(dateWithEndTime, 'yyyy-MM-dd HH:mm:ss');

            const response = await apiAxios.post('/api/agendaOnline/agenda', {
                ...atividadeToSend,
                date: formattedDate,
            });

            console.log('Atividade postada com sucesso:', response.data);
            const updatedAtividadesList = [...atividadesList, response.data];
            setAtividadesList(updatedAtividadesList);
            closeModal();
            toast.success('Agenda registrada com sucesso!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            resetForm(setAtividade, materiaSelect);
            updateAtividadesList(isAdminOrCoAdmin, materiaSelect, setAtividadesList, setLoading);
        } catch (error) {
            console.error('Erro ao postar a atividade:', error);
        } finally {
            setEnvioClicado(false); // Redefina o estado do envio clicado, permitindo futuros envios
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();

        try {
            // Obter a data editada do estado
            let editedDate = parseISO(editAtividade.date); // Parse a string da data para um objeto Date

            // Configurar a hora para 23:59:59
            editedDate = setSeconds(setMinutes(setHours(editedDate, 23), 59), 59);

            // Aqui, você pode usar a rota de edição com o ID da atividade
            const response = await apiAxios.put(`/api/agendaOnline/agenda/${editAtividade._id}`, { ...editAtividade, date: editedDate });

            console.log('Atividade editada com sucesso:', response.data);

            // Atualize a lista de atividades, substituindo a atividade editada
            const updatedAtividadesList = atividadesList.map((atividade) =>
                atividade.id === editAtividade.id ? response.data : atividade
            );

            setAtividadesList(updatedAtividadesList);
            closeEditModal();

            toast.success('Atividade editada com sucesso!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            updateAtividadesList(isAdminOrCoAdmin, materiaSelect, setAtividadesList, setLoading);
        } catch (error) {
            console.error('Erro ao editar a atividade:', error);
        }
    };

    const handleDelete = async (selectedAtividade) => {
        if (!selectedAtividade) {
            console.error('Selected activity is null.');
            return;
        }

        try {
            // Ensure selectedAtividade is defined and has an _id property
            if (!selectedAtividade || !selectedAtividade._id) {
                console.error('ID da atividade não está definido.');
                return;
            }

            if (selectedAtividade.pdfFile && selectedAtividade.pdfFile.pdfUrl) {
                const pdfUrl = selectedAtividade.pdfFile.pdfUrl;

                const pathStartIndex = pdfUrl.indexOf('/PDF/');
                const PDFPath = pdfUrl.substring(pathStartIndex + 1);

                if (PDFPath) {
                    const storage = getStorage(app);
                    const PDFRef = ref(storage, PDFPath);

                    try {
                        // Tenta deletar o arquivo
                        await deleteObject(PDFRef);
                        console.log('File deleted successfully');
                    } catch (error) {
                        if (error.code === 'storage/object-not-found') {
                            console.log('File does not exist in Firebase Storage');
                        } else {
                            console.error('Error deleting file:', error);
                        }
                    }
                } else {
                    console.log('Invalid PDF URL format');
                }

            } else {
                console.log('No PDF URL available');
            }

            // Make the deletion request using the activity ID
            await apiAxios.delete(`/api/agendaOnline/agenda/${selectedAtividade._id}`);

            console.log('Activity deleted from database');

            // Remove the deleted activity from the list
            const updatedActivitiesList = atividadesList.filter((activity) => activity._id !== selectedAtividade._id);
            setAtividadesList(updatedActivitiesList);

            // Close the modal
            closeModal2();
            setShowConfirmationModal(false);

            toast.success('Atividade excluída com sucesso!', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            console.error('Erro ao excluir a atividade:', error);

            toast.error('Erro ao excluir a atividade. Tente novamente.', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const toggleAgendastatus = async (atividadeId) => {
        try {
            const response = await apiAxios.put(`/api/agendaOnline/agenda/${atividadeId}`, {
                agendastatus: !atividadesList.find(atividade => atividade._id === atividadeId).agendastatus,
            });

            // Assuming your API response includes the updated activity
            const updatedActivitiesList = atividadesList.map((atividade) =>
                atividade._id === atividadeId ? response.data : atividade
            );
            setAtividadesList(updatedActivitiesList);

            // Handle the response as needed...
        } catch (error) {
            console.error('Erro ao alterar o status da atividade:', error);
        }
    };

    const [atividadesList, setAtividadesList] = useState([]);
    const isAdminOrCoAdmin = isAdmin || isCoAdmin || isprof1ano || isprof2ano || isprof3ano || isprof4ano || isprof5ano || isprofInfantilI || isprofInfantilII || isprofInfantilIII || isprofInfantilIV || isprofInfantilV;

    useEffect(() => {
        const fetchAtividades = async () => {
            try {
                const response = await apiAxios.get('/api/agendaOnline/agenda');
                let filteredAtividades;

                if (isAdminOrCoAdmin) {
                    filteredAtividades = response.data.filter(
                        (atividade) => atividade.atividadeSerie === materiaSelect
                    );
                } else {
                    // Para outros usuários, lista apenas as atividades com agendastatus true
                    filteredAtividades = response.data.filter(
                        (atividade) => atividade.atividadeSerie === materiaSelect && atividade.agendastatus === true
                    );
                }
                setAtividadesList(filteredAtividades.reverse());
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar atividades:', error);
                setLoading(false);
            }
        };
        fetchAtividades();
    }, []);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const closeModal2 = () => {
        setSelectedAtividadeId(selectedAtividade._id);
        setModalIsOpen2(false);
    };

    const handleCheckboxChange = (materia) => {
        setAtividade((prevAtividade) => ({
            ...prevAtividade,
            materias: {
                ...prevAtividade.materias,
                [materia]: {
                    ...prevAtividade.materias[materia],
                    isSelect: !prevAtividade.materias[materia].isSelect,
                },
            },
        }));
    };

    const handleEditCheckboxChange = (materiaKey) => {
        setEditAtividade((prevAtividade) => {
            const updatedMaterias = { ...prevAtividade.materias };

            // Se a matéria não existir, crie uma nova entrada com valores padrão
            if (!updatedMaterias[materiaKey]) {
                updatedMaterias[materiaKey] = {
                    materia: 'Nova Matéria',  // Valor padrão para o nome da matéria
                    isSelect: true,           // Pode ajustar conforme necessário
                    objetivoConhecimento: '',
                    habilidades: '',
                    metodologia: '',
                    atividadeMaterial: '',
                    atividadeMaterialCasa: '',
                    links: '',
                    linksCasa: '',
                };
            } else {
                // Se a matéria existir, alterne o valor de isSelect
                updatedMaterias[materiaKey].isSelect = !updatedMaterias[materiaKey].isSelect;
            }

            return {
                ...prevAtividade,
                materias: updatedMaterias,
            };
        });
    };

    const handleInputChangeForMateria = (materia, field, value) => {
        setAtividade((prevAtividade) => ({
            ...prevAtividade,
            materias: {
                ...prevAtividade.materias,
                [materia]: {
                    ...prevAtividade.materias[materia],
                    [field]: value,
                },
            },
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setAtividade({ ...atividade, pdfFile: file });
    };

    const openEditModal = (atividadeItem) => {
        setSelectedAtividade(atividadeItem);
        setEditAtividade(atividadeItem);
        setEditModalIsOpen(true);
        setModalIsOpen(false);
        setModalIsOpen2(false);
    };


    const closeEditModal = () => {
        setEditAtividade(null);
        setEditModalIsOpen(false);
    };

    const handleMateriaChange = (materiaKey, field, value) => {
        setEditAtividade((prevEditAtividade) => {
            const updatedMaterias = { ...prevEditAtividade.materias };
            updatedMaterias[materiaKey] = { ...updatedMaterias[materiaKey], [field]: value };
            return { ...prevEditAtividade, materias: updatedMaterias };
        });
    };

    const [date, setDate] = useState(new Date());
    const [selectedMateria, setSelectedMateria] = useState(null);

    const onChange = (newDate) => {
        setDate(newDate);

        // Ao mudar a data, redefinir a matéria selecionada para nulo
        setSelectedMateria(null);

        // Adicionar lógica para encontrar a primeira matéria da fila para o novo dia
        const primeiraMateria = encontrarPrimeiraMateriaDaFila(newDate);
        if (primeiraMateria) {
            setSelectedMateria(primeiraMateria.materiaKey);
        }
    };

    const encontrarPrimeiraMateriaDaFila = (data) => {
        const filteredAtividades = atividadesList.filter((atividadeItem) => {
            const atividadeDate = parseISO(atividadeItem.date);
            return isSameMonth(atividadeDate, data) && isSameDay(atividadeDate, data);
        });

        for (const atividade of filteredAtividades) {
            const primeiraMateriaFila = Object.entries(atividade.materias).find(([materiaKey, materia]) => materia.isSelect);
            if (primeiraMateriaFila) {
                return {
                    date: data,
                    materiaKey: primeiraMateriaFila[0]
                };
            }
        }

        console.log("Nenhuma atividade ou matéria encontrada para o novo dia");
        return null; // Retorna null se não houver atividades para o novo dia
    };

    const filteredActivities = atividadesList.filter((atividadeItem) => {
        const atividadeDate = parseISO(atividadeItem.date);
        return isSameMonth(atividadeDate, date) && isSameDay(atividadeDate, date);
    });

    useEffect(() => {
        const fetchData = async () => {
            // Lógica para encontrar a primeira matéria da fila ao carregar a página
            const primeiraMateriaAoCarregar = encontrarPrimeiraMateriaDaFila(date);
            if (primeiraMateriaAoCarregar) {
                setSelectedMateria(primeiraMateriaAoCarregar.materiaKey);
            }
        };

        fetchData(); // Chama a função assíncrona

    }, [date, atividadesList]); // Certifica-se de que o useEffect seja executado quando a data ou a lista de atividades mudar

    return (
        <div style={{ height: '300vh' }}>
            <Header />
            <div className='return-icon-portal'><Link to="/agenda/atividades"><FaArrowLeft /><p>Voltar</p></Link></div>
            <h4 style={{ color: "red", font: "bold 20pt arial", marginBottom: "25px", margin: "30px" }}>Atividades {anoName}</h4>
            <div className="agendamain-fullscreen">
                <div className="atv-agenda-screen">
                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <TailSpin // Use o componente TailSpin para o indicador de carregamento
                                color="red"
                                height={80}
                                width={80}
                            />
                        </div>
                    ) : (
                        <>
                            <div className="custom-calendar-full">
                                <Calendar onChange={onChange} value={date} calendarType="gregory" className="custom-calendar" locale="pt-BR" view="month" />
                            </div>
                            <hr />
                            <div>
                                {filteredActivities.length > 0 ? (
                                    <>
                                        {filteredActivities.map((activity) => (
                                            <div key={activity._id}>

                                                <div className="atividade-agenda-modal-view-detail-container" style={{ border: "none" }}>
                                                    <div style={{ display: "flex", justifyContent: "start" }}>
                                                        <div className='orientation'>
                                                            <p style={{ alignItems: "start", textAlign: "start", }}><strong style={{ color: "#FF5050", fontSize: "16px" }}>Acolhida:</strong> <br />{activity.acolhida}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="disciplinas-list">
                                                    {Object.entries(activity.materias).filter(([materiaKey, materia]) => materia.isSelect).map(([materiaKey, materia]) => (
                                                        <div
                                                            key={materiaKey}
                                                            onClick={() => setSelectedMateria(materiaKey)}
                                                            className={materiaKey === selectedMateria ? 'selected-materia' : 'materia'}
                                                        >
                                                            {materia.materia}
                                                        </div>
                                                    ))}
                                                </div>
                                                {selectedMateria !== null && (
                                                    <div className='atividade-agenda-modal-view-detail-container'>
                                                        <p className='title'><strong>{activity.materias[selectedMateria]?.materia}</strong></p>
                                                        <div>
                                                            <div className='orientation'>
                                                                <div>
                                                                    <p><strong style={{ color: "#FF5050" }}>Objeto de Conhecimento:</strong> <br />{activity.materias[selectedMateria]?.objetivoConhecimento}</p>
                                                                    <p><strong style={{ color: "#FF5050" }}>Habilidades: <br /></strong>{activity.materias[selectedMateria]?.habilidades}</p>
                                                                </div>
                                                                <div>
                                                                    <p><strong style={{ color: "#FF5050" }}>Metodologia: <br /></strong>{activity.materias[selectedMateria]?.metodologia}</p>
                                                                </div>
                                                            </div>
                                                            <div className='orientation'>
                                                                <div>
                                                                    <p><strong style={{ color: "#FF5050" }}>Atividade de Classe:</strong> <br />{activity.materias[selectedMateria]?.atividadeMaterial}</p>
                                                                </div>
                                                                <div>
                                                                    <p><strong style={{ color: "#FF5050" }}>Atividade de Casa:</strong> <br />{activity.materias[selectedMateria]?.atividadeMaterialCasa}</p>
                                                                </div>
                                                            </div>
                                                            <div className='orientation'>
                                                                <div>
                                                                    <p>
                                                                        <strong style={{ color: "#FF5050" }}>Links Úteis para Classe:</strong> <br />
                                                                        <a href={activity.materias[selectedMateria]?.links} target="_blank" rel="noopener noreferrer">
                                                                            {activity.materias[selectedMateria]?.links === '' ? '' : 'Link para a atividade'}
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p>
                                                                        <strong style={{ color: "#FF5050" }}>Links Úteis para Casa:</strong> <br />
                                                                        <a href={activity.materias[selectedMateria]?.linksCasa} target="_blank" rel="noopener noreferrer">
                                                                            {activity.materias[selectedMateria]?.links === '' ? '' : 'Link para a atividade'}
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className='orientation'>
                                                                <div>
                                                                    <p>
                                                                        <strong style={{ color: "#FF5050" }}>Material complementar:</strong>
                                                                        {activity.pdfFile ? (
                                                                            <a
                                                                                href={activity.pdfFile.pdfUrl}
                                                                                download={activity.pdfFile.name || 'atividade.pdf'}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="download-button google-drive-style"
                                                                            >
                                                                                <FontAwesomeIcon icon={faDownload} /> Baixar PDF
                                                                            </a>
                                                                        ) : ('')}
                                                                    </p>
                                                                </div>
                                                                <div></div>
                                                            </div>
                                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                                {isAdminOrCoAdmin ? (
                                                                    <>
                                                                        <button
                                                                            className='buttons-edition-agenda'
                                                                            onClick={() => openEditModal(activity)}
                                                                        >
                                                                            <FaRegEdit />
                                                                        </button>

                                                                        <button className='buttons-edition-agenda' onClick={() => setShowConfirmationModal(true)}><MdDelete /></button>
                                                                        <Modal
                                                                            isOpen={showConfirmationModal}
                                                                            onRequestClose={() => setShowConfirmationModal(false)}
                                                                            style={{
                                                                                content: {
                                                                                    width: '300px', // Defina a largura desejada
                                                                                    height: '140px', // Defina a largura desejada
                                                                                    margin: 'auto',
                                                                                    borderRadius: '15px'
                                                                                },
                                                                            }}
                                                                        >
                                                                            <h6>Você tem certeza que deseja excluir este plano?</h6>
                                                                            <button className='button-matriculado-detail' style={{ marginLeft: "10px", background: 'red', margin: '5px', font: "bold 9pt arial" }} onClick={() => handleDelete(activity)}>Confirmar</button>
                                                                            <button className='button-matriculado-detail' style={{ marginLeft: "10px", background: 'gray', margin: '5px', font: "bold 9pt arial" }} onClick={() => setShowConfirmationModal(false)}>Cancelar</button>
                                                                        </Modal>
                                                                    </>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                                {isAdmin || isCoAdmin ?
                                                                    <button
                                                                        style={{
                                                                            background: activity.agendastatus ? 'green' : 'gray',
                                                                        }}
                                                                        className='button-matriculado-detail'
                                                                        onClick={() => toggleAgendastatus(activity._id)}
                                                                    >
                                                                        {activity.agendastatus ? 'Ocultar atividade' : 'Liberar atividade'}
                                                                    </button>

                                                                    :
                                                                    ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <Modal isOpen={editModalIsOpen} onRequestClose={closeEditModal} contentLabel="Editar Atividade">
                                                    {editAtividade && (
                                                        <div>
                                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <button
                                                                    style={{ border: '0', background: 'white', color: 'gray', font: 'bold 20pt arial' }}
                                                                    onClick={closeEditModal}
                                                                >
                                                                    <IoMdClose />
                                                                </button>
                                                            </div>

                                                            <form className="agenda-form-series" onSubmit={handleEditSubmit}>
                                                                <h4 style={{ color: "red", font: "bold 25pt arial", marginBottom: "30px", margin: "20px" }}>Editar Atividade</h4>

                                                                <label htmlFor="editDate">Data:</label>
                                                                <input
                                                                    type="date"
                                                                    id="editDate"
                                                                    name="editDate"
                                                                    value={format(parseISO(editAtividade.date), 'yyyy-MM-dd')}
                                                                    onChange={(e) => setEditAtividade({ ...editAtividade, date: e.target.value })}
                                                                    required
                                                                />
                                                                <label htmlFor="editAcolhida">Acolhida:</label>
                                                                <textarea
                                                                    type="text"
                                                                    id="editAcolhida"
                                                                    name="editAcolhida"
                                                                    value={editAtividade.acolhida}
                                                                    onChange={(e) => setEditAtividade({ ...editAtividade, acolhida: e.target.value })}
                                                                />
                                                                <div className='atividade-agenda-select-area'>
                                                                    {Object.entries(editAtividade.materias).map(([materiaKey, materia]) => (
                                                                        <div style={{ height: "min-content", width: "min-content" }} key={materiaKey}>
                                                                            <div
                                                                                className='atividade-agenda-select'
                                                                                onClick={() => handleEditCheckboxChange(materiaKey)}
                                                                                style={{ cursor: 'pointer', background: materia.isSelect ? 'gray' : '', color: materia.isSelect ? 'white' : '' }}
                                                                            >
                                                                                {materia.materia}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* Campos de edição para cada matéria selecionada */}
                                                                {Object.entries(editAtividade.materias).map(([materiaKey, materia]) => (
                                                                    <div key={materiaKey}>
                                                                        {materia.isSelect && (
                                                                            <>
                                                                                <h4 style={{ color: "red", font: "bold 13pt arial" }}>{materia.materia}</h4>

                                                                                <label htmlFor={`editMaterias.${materiaKey}.objetivoConhecimento`}>Objetivo de Conhecimento:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.objetivoConhecimento`}
                                                                                    name={`editMaterias.${materiaKey}.objetivoConhecimento`}
                                                                                    value={materia.objetivoConhecimento}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'objetivoConhecimento', e.target.value)}
                                                                                />
                                                                                <label htmlFor={`editMaterias.${materiaKey}.habilidades`}>Habilidades:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.habilidades`}
                                                                                    name={`editMaterias.${materiaKey}.habilidades`}
                                                                                    value={materia.habilidades}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'habilidades', e.target.value)}
                                                                                />

                                                                                <label htmlFor={`editMaterias.${materiaKey}.metodologia`}>Metodologia:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.metodologia`}
                                                                                    name={`editMaterias.${materiaKey}.metodologia`}
                                                                                    value={materia.metodologia}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'metodologia', e.target.value)}
                                                                                />
                                                                                <label htmlFor={`editMaterias.${materiaKey}.atividadeMaterial`}>Atividades de Classe:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.atividadeMaterial`}
                                                                                    name={`editMaterias.${materiaKey}.atividadeMaterial`}
                                                                                    value={materia.atividadeMaterial}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'atividadeMaterial', e.target.value)}
                                                                                />

                                                                                <label htmlFor={`editMaterias.${materiaKey}.atividadeMaterialCasa`}>Atividades de Casa:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.atividadeMaterialCasa`}
                                                                                    name={`editMaterias.${materiaKey}.atividadeMaterialCasa`}
                                                                                    value={materia.atividadeMaterialCasa}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'atividadeMaterialCasa', e.target.value)}
                                                                                />

                                                                                <label htmlFor={`editMaterias.${materiaKey}.links`}>Links úteis para Classe:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.links`}
                                                                                    name={`editMaterias.${materiaKey}.links`}
                                                                                    value={materia.links}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'links', e.target.value)}
                                                                                />

                                                                                <label htmlFor={`editMaterias.${materiaKey}.linksCasa`}>Links úteis para Casa:</label>
                                                                                <textarea
                                                                                    type="text"
                                                                                    id={`editMaterias.${materiaKey}.linksCasa`}
                                                                                    name={`editMaterias.${materiaKey}.linksCasa`}
                                                                                    value={materia.linksCasa}
                                                                                    onChange={(e) => handleMateriaChange(materiaKey, 'linksCasa', e.target.value)}
                                                                                />

                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                                <button type="submit">Salvar</button>
                                                            </form>
                                                        </div>
                                                    )}
                                                </Modal>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <p style={{ color: '#555', fontSize: '20px', fontWeight: 'bold' }}>Nenhum item encontrado.</p>
                                        {isAdminOrCoAdmin ? (
                                            <div className="agenda-too-bar">
                                                <button onClick={openModal}>
                                                    <MdOutlineLibraryAdd style={{ fontSize: "20px", marginRight: "5px" }} />Adicionar envio
                                                </button>
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                        <img src={emptySearchImage} alt="Nenhum item encontrado" />
                                    </div>
                                )}
                            </div>
                            {/* Criar item */}
                            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Example Modal">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button
                                        style={{ border: '0', background: 'white', color: 'gray', font: 'bold 20pt arial' }}
                                        onClick={closeModal}
                                    >
                                        <IoMdClose />
                                    </button>
                                </div>

                                <form className="agenda-form-series" onSubmit={handleSubmit}>
                                    <h4 style={{ color: "red", font: "bold 25pt arial", marginBottom: "30px", margin: "20px", marginLeft: "25px" }}>Adicionar agenda</h4>
                                    <div className="error-message">
                                        {Object.values(atividade.materias).filter(materia => materia.isSelect).length === 0 && (
                                            <p style={{ color: 'red', marginTop: '10px' }}>
                                                *Selecione pelo menos uma matéria antes de enviar.
                                            </p>
                                        )}
                                    </div>
                                    <div key="acolhida">
                                        <label htmlFor="date">Data:</label>
                                        <input
                                            type="date"
                                            id="date"
                                            name="date"
                                            value={atividade.date}
                                            onChange={(e) => setAtividade({ ...atividade, date: e.target.value })}
                                        />

                                        <label htmlFor="acolhida">Acolhida:</label>
                                        <textarea
                                            type="text"
                                            id="acolhida"
                                            name="acolhida"
                                            value={atividade.acolhida}
                                            onChange={(e) => setAtividade({ ...atividade, acolhida: e.target.value })}
                                        />

                                        {/* Dentro do seu formulário... */}
                                        <label htmlFor="pdfFile">Anexar PDF:</label>
                                        <input
                                            type="file"
                                            id="pdfFile"
                                            name="pdfFile"
                                            accept=".pdf"
                                            onChange={handleFileChange}
                                        />

                                    </div>
                                    <div className='atividade-agenda-select-area'>
                                        {Object.entries(atividade.materias).map(([materiaKey, materia]) => (
                                            <div style={{ height: "min-content", width: "min-content" }} key={materiaKey}>
                                                <div
                                                    className='atividade-agenda-select'
                                                    onClick={() => handleCheckboxChange(materiaKey)}
                                                    style={{ cursor: 'pointer', background: materia.isSelect ? 'gray' : '', color: materia.isSelect ? 'white' : '' }}
                                                >
                                                    {materia.materia} {/* Adiciona o nome da matéria como uma div clicável */}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {Object.entries(atividade.materias).map(([materiaKey, materia]) => (
                                        <div key={materiaKey}>
                                            {materia.isSelect && (
                                                <>
                                                    <h4 style={{ color: "red", font: "bold 13pt arial" }}>{materia.materia}</h4>
                                                    <label htmlFor={`materias.${materiaKey}.objetivoConhecimento`}>Objetivo de Conhecimento:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.objetivoConhecimento`}
                                                        name={`materias.${materiaKey}.objetivoConhecimento`}
                                                        value={materia.objetivoConhecimento}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'objetivoConhecimento', e.target.value)}
                                                    />
                                                    <label htmlFor={`materias.${materiaKey}.habilidades`}>habilidades:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.habilidades`}
                                                        name={`materias.${materiaKey}.habilidades`}
                                                        value={materia.habilidades}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'habilidades', e.target.value)}
                                                    />
                                                    <label htmlFor={`materias.${materiaKey}.metodologia`}>metodologia:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.metodologia`}
                                                        name={`materias.${materiaKey}.metodologia`}
                                                        value={materia.metodologia}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'metodologia', e.target.value)}
                                                    />
                                                    <label htmlFor={`materias.${materiaKey}.atividadeMaterial`}>Atividades de Classe:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.atividadeMaterial`}
                                                        name={`materias.${materiaKey}.atividadeMaterial`}
                                                        value={materia.atividadeMaterial}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'atividadeMaterial', e.target.value)}
                                                    />
                                                    <label htmlFor={`materias.${materiaKey}.links`}>Links úteis para Classe:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.links`}
                                                        name={`materias.${materiaKey}.links`}
                                                        value={materia.links}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'links', e.target.value)}
                                                    />
                                                    <label htmlFor={`materias.${materiaKey}.atividadeMaterialCasa`}>Atividades de Casa:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.atividadeMaterialCasa`}
                                                        name={`materias.${materiaKey}.atividadeMaterialCasa`}
                                                        value={materia.atividadeMaterialCasa}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'atividadeMaterialCasa', e.target.value)}
                                                    />
                                                    <label htmlFor={`materias.${materiaKey}.linksCasa`}>Links úteis para Casa:</label>
                                                    <textarea
                                                        type="text"
                                                        id={`materias.${materiaKey}.linksCasa`}
                                                        name={`materias.${materiaKey}.linksCasa`}
                                                        value={materia.linksCasa}
                                                        onChange={(e) => handleInputChangeForMateria(materiaKey, 'linksCasa', e.target.value)}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ))}
                                    <button type="submit">Postar na Agenda</button>
                                </form>
                            </Modal>
                        </>
                    )}
                </div>
            </div>
        </div >
    );
};

export default AtividadeComponente;
