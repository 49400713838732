import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import apiAxios from '../../../api/apiAxios';
import { MdFamilyRestroom } from 'react-icons/md';
import { Link } from 'react-router-dom';

const InscricoesCampanha = () => {
    const [loading, setLoading] = useState(true);
    const [campanha, setCampanha] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCampanha, setFilteredCampanha] = useState([]);

    useEffect(() => {
        async function fetchCampanha() {
            try {
                const response = await apiAxios.get('/api/campanhaDeMatriculas/inscricoes');
                setCampanha(response.data);
                setFilteredCampanha(response.data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        }

        fetchCampanha();
    }, []);

    useEffect(() => {
        // Filtrar as inscrições com base no nome ao digitar
        const filteredData = campanha.filter((inscricao) =>
            inscricao.nome.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCampanha(filteredData);
    }, [searchTerm, campanha]);

    return (
        <div>
            <Header />
            <h1>Matrículas</h1>
            {loading ? (
                <p>Carregando...</p>
            ) : error ? (
                <p>Ocorreu um erro: {error}</p>
            ) : (
                <div className='full-matriculasadmin'>
                    <h4 style={{ color: "red", font: "bold 17pt arial", margin: "20px" }}>Inscrições na Campanha de Matrículas</h4>

                    {filteredCampanha && filteredCampanha.length > 0 ? (
                        <div className='matriculasadmin-elements'>
                            <p style={{ color: "black", font: "bold 13pt arial" }}>Total de matrículas : {filteredCampanha.length}</p>
                            <div>
                                {/* Adicione um campo de pesquisa */}
                                <input
                                    type="text"
                                    placeholder="Pesquisar por nome"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            {filteredCampanha.map((inscricao) => (
                                <div className='matriculasadmin-element'>
                                    <Link to={`/menuadmin/sabadoanimado/${inscricao._id}`} key={inscricao._id}>
                                        <div className='matriculasadmin-sec-1'>
                                            <MdFamilyRestroom />
                                        </div>
                                        <div className='matriculasadmin-sec-2'>
                                            <p>Criança: {inscricao.nome}</p>
                                            <p>Responsável: {inscricao.nomeResponsavel}</p>
                                            <p>Colégio que veio: {inscricao.colegio}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p>Nenhuma inscrição encontrada.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default InscricoesCampanha;
