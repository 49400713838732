import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/header';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import apiAxios from '../../api/apiAxios';
import { TailSpin } from 'react-loader-spinner';
import './userarea.css';
import img from '../../assets/bonecoscolegio.jpg';
import img2 from '../../assets/picina2.jpg';
import img3 from '../../assets/picina.jpg';
import logo2 from '../../assets/Logo Alternativo.png'

import { CiEdit } from "react-icons/ci";
import { CiBullhorn } from "react-icons/ci";
import { CiBoxList } from "react-icons/ci";
import { CiApple } from "react-icons/ci";
import { CiViewTable } from "react-icons/ci";

import youtube from "../../assets/youtube.png"
import facebook from "../../assets/facebook.png"
import instagram from "../../assets/instagram.png"
import tiktok from "../../assets/tiktok.png"

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserArea = () => {

  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user);
  const { loading, userInfo: user, error } = userInfo;
  const [userData, setUserData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sliderRef = useRef();
  const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
  const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);
  const isprof = useSelector(state => state.user?.userInfo?.data?.isProfessor?.isProfessor);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = user?.data?._id;
        const response = await apiAxios.get(`/api/users/user/${userId}`);
        if (response.data.status === 'success' && response.data.data) {
          setUserData(response.data.data);
        }
      } catch (error) {
        console.error('Erro ao obter informações do usuário:', error);
      }
    };

    const handleResize = () => {
      // Adiciona um pequeno atraso para garantir que a largura seja atualizada após o redimensionamento
      setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 200);
    };

    fetchUserData();
    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [user]);

  const [informes, setInformes] = useState([]);

  useEffect(() => {
    const fetchInformes = async () => {
      try {
        const response = await apiAxios.get('/api/agendaInformes/informes');
        if (response.status === 200) {
          setInformes(response.data);
        } else {
          console.error('Error fetching informes.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    };
    fetchInformes();
  }, []);

  const sortedInformes = informes.sort((a, b) => new Date(b.date) - new Date(a.date));

  const slides = sortedInformes.slice(-3).map((informe) => ({
    id: informe._id,
    imgSrc: informe.image.url,
    altText: informe.title,
  })).reverse();

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const items = [
    {
      key: 1,
      name: 'Agenda',
      icon: <CiEdit />,
      link: '/agenda/atividades',
    },
    {
      key: 2,
      name: 'Cardápio',
      icon: <CiApple />,
      link: '/',
    },
    {
      key: 3,
      name: 'Informes',
      icon: <CiBullhorn />,
      link: '/agenda/informes',
    },
    {
      key: 4,
      name: 'Plano Anual',
      icon: <CiBoxList />,
      link: '/agenda/planos',
    },
  ]

  const canAccessPlanoAnual = isCoAdmin || isAdmin || isprof;

  const redes = [
    {
      key: 1,
      name: 'Facebook',
      icon: facebook,
      link: 'https://www.facebook.com/colegioalternativocolaco',
    },
    {
      key: 2,
      name: 'Instagran',
      icon: instagram,
      link: 'https://www.instagram.com/colegioalternativocolaco',
    },
    {
      key: 3,
      name: 'Tik Tok',
      icon: tiktok,
      link: 'https://www.tiktok.com/@colegioalternativocolaco?_t=8XxozM2TnMr&_r=1',
    },
    {
      key: 4,
      name: 'Youtube',
      icon: youtube,
      link: 'https://www.youtube.com/@colegioalternativocolaco',
    },
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false); // Altera o estado do loading após 5 segundos
    }, 2000);

    return () => clearTimeout(timer); // Limpa o temporizador ao desmontar o componente
  }, []);

  return (
    <div>
      <Header></Header>
      <div className='all-banner-userarea'>
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p>Ocorreu um erro: {error}</p>
        ) : (
          <div className='home-content-area'>
            {!showLoading ? (
              <>
                {windowWidth <= 500 && (
                  <>
                    <div className='logo-userarea'>
                      <img src={logo2} alt="logo2" />
                    </div>
                    <div className='welcome-userarea'>
                      <p><strong style={{ color: "#333" }}>Olá, {userData.firstName}👋</strong></p>
                      <p>Bem vindo de volta !</p>
                    </div>
                  </>
                )}
                <div className='banner-userarea'>
                  <Slider {...settings} ref={sliderRef}>
                    {slides.map((slide, index) => (
                      <div className={`custom-slide`} key={slide.id}>
                        <img src={slide.imgSrc} alt={slide.altText} />
                      </div>
                    ))}
                  </Slider>
                </div>
                {windowWidth <= 500 && (
                  <div className='items-usearea'>
                    <div>
                      <h4>Agenda 📒</h4>
                      <div className='items-usearea-list'>
                        {items.map((item) => (
                          (item.name !== 'Plano Anual' || canAccessPlanoAnual) && (
                            <Link to={item.link} style={{ textDecoration: "none" }}>
                              <div className='items-usearea-1' key={item.key}>
                                <div className='items-usearea-1-icon'>{item.icon}</div>
                                <div className='items-usearea-1-title'>
                                  <p>{item.name}</p>
                                </div>
                              </div>
                            </Link>
                          )
                        ))}
                      </div>
                    </div>
                    <div className='items-usearea-2'>
                      <h4>Boletins 📝</h4>
                      <div className='items-usearea-list'>
                        <Link to="/pedagogico/boletins" style={{ textDecoration: "none" }}>
                          <div className='items-usearea-1'>
                            <div className='items-usearea-1-icon'><CiViewTable /></div>
                            <div className='items-usearea-1-title'>
                              <p>Boletim</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='items-usearea-2'>
                      <h4>Conheça mais sobre nós ⭐</h4>
                      <div className='items-usearea-list-redes'>
                        {redes.map((item) => (
                          <div key={item.key}>
                            <Link to={item.link}><img src={item.icon}></img></Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div style={{ padding: "20px"}}>
                <TailSpin color="red" height={80} width={80} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserArea;
