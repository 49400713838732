import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { signin } from '../../actions/userActions';
import styles from './LoginEscolaScreen.module.css';
import escola from "../../assets/OIP.png"
import { TailSpin } from 'react-loader-spinner';

const LoginEscolaScreen = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const user = useSelector((state) => state.user);
    const { loading, userInfo, error } = user;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const redirecth = searchParams.get('redirect') || '/';

    useEffect(() => {
        if (userInfo) {
            navigate(redirecth);
        }
    }, [userInfo, navigate, redirecth, error]);
    console.log(error)

    // LoginEscolaScreen.js
    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await dispatch(signin(login, password));
        } catch (error) {
            return error;
        }
    };

    return (
        <div className={styles.all}>
            {loading ? <TailSpin color="red" size={30} /> :
                <>
                    <div className={styles.formLogin}>
                        <form onSubmit={submitHandler}>
                            <img className={styles.logo} src={escola} alt="Escola Logo" />
                            <div>
                                {error?.general && <div style={{ color: 'red', font: "normal 9pt arial" }}>{error.general}</div>}
                            </div>
                            <div>
                                <label htmlFor="login">Login (Email ou CPF)</label>
                                <input
                                    type="text"
                                    name="login"
                                    id="login"
                                    onChange={(e) => setLogin(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor="password">Senha</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div>
                                <button type="submit">
                                    Signin
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={styles.GotAcess}>
                        <p>Não tem conta?</p> <Link to="/matriculaescola">Cadastre-se</Link>
                    </div>
                    <div className={styles.footerLogin}>
                        <p>©Alternativo Colaço Soluções Tecnológicas.</p>
                        <p>Todos os direitos reservados.</p>
                    </div>
                </>
            }
        </div>
    );
};

export default LoginEscolaScreen;
