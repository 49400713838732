import React, { useEffect, useState } from 'react';
import Header from '../../../components/header';
import apiAxios from '../../../api/apiAxios';
import { useParams } from 'react-router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ImageData from '../../../assets/OIP.png';

const FichaColoniaDetail = () => {
    const { id } = useParams(); // Obtém o parâmetro :id da URL
    const [alunoDetail, setAlunoDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchAlunoDetail() {
            try {
                console.log("ID:", id);
                const response = await apiAxios.get(`/api/matriculaColonia/inscricao/filtrar/${id}`);
                setAlunoDetail(response.data);
                setLoading(false);
            } catch (error) { 
                setError(error.message);
                setLoading(false);
            }
        }
        fetchAlunoDetail();
    }, [id]);

    const toggleArqvStatus = async () => {
        try {
            const newStatus = !alunoDetail.isArqv;
            const response = await apiAxios.patch(`/api/matriculaColonia/inscricao/isArqv/${id}`, { isArqv: newStatus });
            setAlunoDetail(response.data.data); // Atualiza o estado com os dados retornados
        } catch (error) {
            console.error('Erro ao atualizar o status de arquivamento:', error);
        }
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        // Define a fonte e o tamanho do texto principal
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(12);

        // Título principal
        doc.setTextColor(255, 0, 0); // Cor do texto vermelho
        doc.setFont('bold'); // Use setFontStyle em vez de setFontType
        doc.setFontSize(20); // Aumente o tamanho da fonte para 16
        doc.text(`Ficha Colonia`, 89, 15);

        // Restaure a cor e o estilo do texto
        doc.setTextColor(0, 0, 0); // Cor do texto preto
        doc.setFont('normal'); // Use setFontStyle em vez de setFontType

        // Dados do Aluno
        const x = 10;
        let y = 30;

        const i = 80;
        const g = 215;
        const width = 50; // Largura da imagem em unidades de PDF
        const height = 50; // Altura da imagem em unidades de PDF

        // Adiciona a imagem ao PDF
        doc.addImage(ImageData, 'PNG', i, g, width, height);

        // Crie uma matriz com os dados do aluno
        const data = [
            ["Nome:", alunoDetail.nome],
            ["Idade do aluno:", alunoDetail.idade],
            ["Série atual do aluno:", alunoDetail.serie],
            ["Nome do responsável:", alunoDetail.nomeResponsavel],
            ["Telefone do Responsável:", alunoDetail.telefoneResponsavel],
            ["WhatsApp do responsável:", alunoDetail.whatsappResponsavel],
            ["Colégio que veio:", alunoDetail.colegio],
            ["Qual vai ser a série do aluno para 2024?", alunoDetail.serie2024],
            ["Tem alguma restrição alimentar?", alunoDetail.restricaoAlimentar],
            ["Tem alguma comorbidade?", alunoDetail.comorbidade],
        ];

        // Crie a tabela com os dados do aluno
        doc.autoTable({
            startY: y + 10,
            head: [['Descrição', 'Valor']], // Cabeçalho da tabela
            body: data, // Dados da tabela
            theme: 'grid', // Estilo da tabela
            styles: {
                fontSize: 12,
                cellPadding: 5,
                fontStyle: 'normal', // Estilo da fonte
            },
            didDrawCell: (data) => {
                // Verifique se a célula é a descrição ou o valor e ajuste a cor de preenchimento apenas para essas células
                if (data.section === 'body' && (data.row.index === 0 || data.row.index === 1)) {
                    data.cell.styles.fillColor = [255, 255, 0]; // Cor de preenchimento (amarelo) para descrição e valor
                }
            },
        });

        // Use o nome do aluno para salvar o PDF
        const nomeAluno = alunoDetail.nome.replace(/ /g, "_"); // Substitui espaços por underscores
        doc.save(`informacoes_${nomeAluno}.pdf`);
    };

    return (
        <div>
            <Header />
            <h1>Matrículas</h1>
            <div className='full-matriculado-detail'>
                <div style={{ width: "95%", display: "flex", margin: "10px", gap:"10px" }}>
                    <button className='button-matriculado-detail' onClick={generatePDF}>Baixar PDF</button>
                    <button style={alunoDetail?.isArqv ? {background:"green"}:{background:"gray"}} className='button-matriculado-detail' onClick={toggleArqvStatus}>
                        {alunoDetail?.isArqv ? "Desarquivar" : "Arquivar"}
                    </button>
                </div>
                {loading ? (
                    <p>Carregando...</p>
                ) : error ? (
                    <p>Ocorreu um erro: {error}</p>
                ) : alunoDetail ? (
                    <div className='grid-container'>
                        <h4>Detalhes do Aluno</h4>
                        <div className='grid-container-2'>
                            <div className='grid-item'>
                                <p>Nome: {alunoDetail.nome}</p>
                                <p>Idade do aluno: {alunoDetail.idade}</p>
                                <p>Série atual do aluno: {alunoDetail.serie}</p>
                                <p>Nome do responsável: {alunoDetail.nomeResponsavel}</p>
                                <p>Telefone do Responsável: {alunoDetail.telefoneResponsavel}</p>
                                <p>WhatsApp do responsável: {alunoDetail.whatsappResponsavel}</p>
                                <p>Colégio que veio: {alunoDetail.colegio}</p>
                                <p>Qual vai ser a série do aluno para 2024? {alunoDetail.serie2024}</p>
                                <p>Tem alguma restrição alimentar? {alunoDetail.restricaoAlimentar}</p>
                                <p>Tem alguma comorbidade? {alunoDetail.comorbidade}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <p>Aluno não encontrado.</p>
                )}
            </div>
        </div>
    );
};

export default FichaColoniaDetail;
