import React, { useState } from 'react';
import "../components-style/perguntas.css"

const Perguntas = () => {
  const perguntas = [
    {
      id: 1,
      title: "Qual é o dia e o horário de funcionamento do colégio ?",
      res: "7h às 17h de segunda a sexta-feira."
    },
    {
      id: 2,
      title: "Qual a idade para entrar no infantil I ?",
      res: "A partir de 1 ano andando."
    },
    {
      id: 3,
      title: "Quais os esportes que tem no colégio ?",
      res: "karaté, balé e natação."
    },
    {
      id: 4,
      title: "Quando ocorre a colônia de férias ?",
      res: "Janeiro, Julho e Dezembro. E ela é aberta ao público externo para crianças de 2 à 10 anos."
    },
    {
      id: 5,
      title: "Qual o horário de visitação do colégio ?",
      res: "7h às 17h de segunda a sexta-feira."
    },
    {
      id: 6,
      title: "Qual os turnos de funcionamento ?",
      res: "manhã 7h às 11h, tarde 13h às 17h, semi manhã , semi tarte e integral."
    },
    {
      id: 7,
      title: "Vocês têm creche na diária e qual o valor?",
      res: "Sim.DIÁRIA ABERTA AO PÚBLICO.( RS 90,00 dia integral com 3 alimentações, RS 65,00 semi com 2 alimentações, 45 manhã ou tarde com 1 alimentação)"
    },
    {
      id: 8,
      title: "Qual a oferta de vagas para 2024?",
      res: "SALAS LIMITADAS COM 15 ALUNOS.INFANTIL I- II- III- IV- V manhã/ tarde/ Semi/Integral 1° ano- Manhã/Semi/Integral 2° ano- Tarde/Semi/Integral3° ano- Tarde/Semi/Integral 4° ano- Manhã/Semi/Integral 5°ano- Manhã/Semi/Integral"
    },
  ];

  const [perguntasAbertas, setPerguntasAbertas] = useState([]);

  const togglePergunta = (id) => {
    if (perguntasAbertas.includes(id)) {
      // Se a pergunta está aberta, feche-a
      setPerguntasAbertas(perguntasAbertas.filter((perguntaId) => perguntaId !== id));
    } else {
      // Se a pergunta está fechada, abra-a
      setPerguntasAbertas([...perguntasAbertas, id]);
    }
  };

  return (
    <div className='sec-perguntas'>
      <h1 >Perguntas Frequentes 🤔💡</h1>
      <div className='sec-content-P'>
        {perguntas.map((pergunta) => (
          <div key={pergunta.id} className='pergunta'>
            <div
              className='pergunta-titulo'
              onClick={() => togglePergunta(pergunta.id)}
            >
              {pergunta.title}
            </div>
            {perguntasAbertas.includes(pergunta.id) && (
              <div className='pergunta-resposta'>{pergunta.res}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Perguntas;
