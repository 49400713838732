import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { TbMoodKid } from "react-icons/tb";
import Header from '../../../components/header';
import './AtividadesAgenda.css';
import { useSelector } from 'react-redux';
import { AiFillLock } from 'react-icons/ai';
import { FaArrowLeft } from "react-icons/fa";

const AtividadesAgenda = () => {

    const isAdmin = useSelector(state => state.user?.userInfo?.data?.isAdmin);
    const isCoAdmin = useSelector(state => state.user?.userInfo?.data?.isCoAdmin);

    const isprof1ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.primeiroAno);
    const isprof2ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.segundoAno);
    const isprof3ano = useSelector(state => state.user.userInfo?.data?.isProfessor?.terceiroAno);
    const isprof4ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.quartoAno);
    const isprof5ano = useSelector(state => state.user?.userInfo?.data?.isProfessor?.quintoAno);

    const isprofInfantilI = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilUm);
    const isprofInfantilII = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilDois);
    const isprofInfantilIII = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilTres);
    const isprofInfantilIV = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilQuatro);
    const isprofInfantilV = useSelector(state => state.user?.userInfo?.data?.isProfessor?.infantilCinco);

    const turmasinfantil = [
        { id: 1, nome: 'Infantil I', Link: '/agenda/atividades/infantil/1', verifyprof: 'isprofInfantilI', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 2, nome: 'Infantil II', Link: '/agenda/atividades/infantil/2', verifyprof: 'isprofInfantilII', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 3, nome: 'Infantil III', Link: '/agenda/atividades/infantil/3', verifyprof: 'isprofInfantilIII', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 4, nome: 'Infantil V Manhã', Link: '/agenda/atividades/infantil/5/manha', verifyprof: 'isprofInfantilV', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 5, nome: 'Infantil V Tarde', Link: '/agenda/atividades/infantil/5/tarde', verifyprof: 'isprofInfantilV', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 6, nome: 'Infantil IV Manhã', Link: '/agenda/atividades/infantil/4/manha', verifyprof: 'isprofInfantilIV', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 7, nome: 'Infantil IV Tarde', Link: '/agenda/atividades/infantil/4/tarde', verifyprof: 'isprofInfantilIV', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
    ];
    const turmasfundamental = [
        { id: 1, nome: '1° ano', Link: '/agenda/atividades/fundamental/1', verifyprof: 'isprof1ano', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 2, nome: '2° ano', Link: '/agenda/atividades/fundamental/2', verifyprof: 'isprof2ano', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 3, nome: '3° ano', Link: '/agenda/atividades/fundamental/3', verifyprof: 'isprof3ano', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 4, nome: '4° ano', Link: '/agenda/atividades/fundamental/4', verifyprof: 'isprof4ano', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
        { id: 5, nome: '5° ano', Link: '/agenda/atividades/fundamental/5', verifyprof: 'isprof5ano', verifyadmin: isAdmin, verifyCoadmin: isCoAdmin },
    ];

    const [infantilExpandido, setInfantilExpandido] = useState(false);
    const [fundamentalExpandido, setFundamentalExpandido] = useState(false);

    const toggleInfantil = () => {
        setInfantilExpandido(!infantilExpandido);
    };

    const toggleFundamental = () => {
        setFundamentalExpandido(!fundamentalExpandido);
    };

    return (
        <div>
            <Header />
            {/* <h1>Agenda</h1> */}
            <div className='return-icon-portal'><Link to="/"><FaArrowLeft /><p>Voltar</p></Link></div>
            <div className="agendamain-fullscreen">
                <div className='pedagogico-containers'>
                    <h4 style={{ color: "red", font: "bold 16pt arial", marginBottom: "25px" }}>Planos e atividades</h4>
                    <div className={`container ${infantilExpandido ? 'expandido' : ''}`}>
                        <p>Ensino Infantil</p>
                        {turmasinfantil.map((turma) => (
                            <Link key={turma.id} to={turma.verifyprof || turma.verifyCoadmin || turma.verifyadmin ? turma.Link : ""} className='serie-item'>
                                <div>
                                    <TbMoodKid icon={faGraduationCap} className="icon" />
                                    {turma.nome}
                                </div>
                                {turma.verifyprof || turma.verifyCoadmin || turma.verifyadmin ? <AiFillLock style={{ color: "green" }} /> : <AiFillLock />}
                            </Link>
                        ))}
                    </div>
                    <div className={`container ${fundamentalExpandido ? 'expandido' : ''}`}>
                        <p>Ensino Fundamental I</p>
                        {turmasfundamental.map((turma) => (
                            <Link key={turma.id} to={turma.verifyprof || turma.verifyCoadmin || turma.verifyadmin ? turma.Link : ""} className='serie-item'>
                                <div>
                                    <FontAwesomeIcon icon={faGraduationCap} className="icon" />
                                    {turma.nome}
                                </div>
                                {turma.verifyprof || turma.verifyCoadmin || turma.verifyadmin ? <AiFillLock style={{ color: "green" }} /> : <AiFillLock />}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AtividadesAgenda;
