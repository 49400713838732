import React from 'react'
import Header from '../../../components/header'
import './financeiromain.css'
import { FcOvertime } from "react-icons/fc";
import { Link } from 'react-router-dom';
const FinanceiroMain = () => {
    const items = [
        {
            key: 1,
            name: 'Financeiro Colônia',
            icon: <FcOvertime />,
            link: '/financeiro/colonia'
        },
    ]
    return (
        <div >
            <Header />
            <h1>Financeiro</h1>
            <div className='financeiro-fullscreen' style={{height:"300vh"}}>
                <div className='financeiro-acess-full'>
                    <div className='financeiro-acess'>
                        {
                            items.map((e) => (
                                <Link style={{ textDecoration: "none" }} key={e.key} to={e.link}>
                                    <div className='financeiro-item'>
                                        <div style={{ font: "bold 45pt arial", height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {e.icon}
                                        </div>
                                        <div className='name-main-financeiro-colonia'>
                                            {e.name}
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceiroMain