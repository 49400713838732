import React from 'react';
import './components/AtividadeAgendaSerie.css';
import AtividadeComponente from './components/AtividadeComponente';


const AtividadesAgenda3ano = () => {
    return (
        <AtividadeComponente materiaSelect="3ano" anoName="3°ano" />
    );
};

export default AtividadesAgenda3ano;
